import { MUTATION_SWITCH_ROLE } from "@/app/gql/roles/roles_mutations";
import UserUtils from "@/app/utils/users/user_utils";
import { useNotification } from "@/components/notification/MGNotificationProvider/useNotification";
import MGRolesPicker from "@/components/roles-picker/MGRolesPicker";
import { useTranslation } from "react-i18next";
import { currentUserVar } from "@/cache";
import Config from "@/config";
import useMGMutation from "@/app/utils/gql/useMGMutation";

export const RolesPickerWidget = ({ onLogout }) => {
  const [notification] = useNotification();
  const { t } = useTranslation();

  const currentUser = UserUtils.getCurrentSchoolUser();

  const schoolUsers = UserUtils.getCurrentSchoolUsers();
  const roles = UserUtils.getCurrentSchoolRoles();

  function userChanged(user) {
    localStorage.setItem("currentUser", JSON.stringify(user));
    currentUserVar(user);
  }

  const [switchRole] = useMGMutation(MUTATION_SWITCH_ROLE);

  const changeRole = (roleID, schoolID) => {
    switchCurrentRole(roleID, schoolID);
  };

  async function switchCurrentRole(roleID, schoolID) {
    const response = await switchRole({
      variables: {
        input: {
          school_id: schoolID ?? currentUser.school_id,
          role_id: roleID,
        },
      },
      onCompleted: (data) => {
        if (data) {
          if (UserUtils.isTeacherRoleActive(data.switchCurrentSchoolUser?.currentUser)) {
            window.location.reload(false);
          } else {
            window.location.href = Config.ADMIN_DASHBOARD_HOST;
          }
        }
      },
    });

    if (response.data.switchCurrentSchoolUser) {
      if (typeof userChanged === "function") {
        userChanged(response.data.switchCurrentSchoolUser.currentUser);
      }

      notification.success({ message: t("Your role has been successfully updated"), tiny: true });
    }
  }

  return (
    <>
      {currentUser && (
        <MGRolesPicker
          onLogout={onLogout}
          currentUser={currentUser}
          schoolUsers={schoolUsers}
          roles={roles}
          onRoleChange={changeRole}
        />
      )}
    </>
  );
};

export default RolesPickerWidget;
