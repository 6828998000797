import "./style.css";

import { ErrorMessage } from "formik";

import PropTypes from "prop-types";
import { MGHelperText } from "@/components/typography";
import MGTextArea from "./MGTextArea";
import MGField from "../BuildingBlocks/MGField";

export const MGTextAreaField = ({ name, placeholder, onChange, width, height, inline }) => {
  return (
    <MGField name={name} inline={inline}>
      {({ meta, field, form }) => {
        const onChangeHandler = (evt) => {
          form.setFieldValue(field.name, evt);
          if (typeof onChange === "function") onChange(evt);
        };

        return (
          <>
            <MGTextArea
              placeholder={placeholder}
              value={field.value}
              onChange={onChangeHandler}
              formikField={field}
              formikMeta={meta}
              width={width}
              height={height}
            ></MGTextArea>
            <ErrorMessage component={MGHelperText} name={name} />
          </>
        );
      }}
    </MGField>
  );
};

MGTextAreaField.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  width: PropTypes.string,
  height: PropTypes.string,
  inline: PropTypes.bool,
};

MGTextAreaField.defaultProps = {};

export default MGTextAreaField;
