import "./style.css";
import MGTagInput from "./MGTagInput";
import PropTypes from "prop-types";
import { ErrorMessage } from "formik";
import { MGHelperText } from "@/components/typography";
import MGField from "../BuildingBlocks/MGField";

export const MGTagInputField = ({ name, placeholder, onChange, inline }) => {
  return (
    <MGField name={name} inline={inline}>
      {({ meta, field, form }) => {
        {
          /* {(props) => { */
        }
        const onChangeHandler = (evt) => {
          form.setFieldValue(field.name, evt);
          if (typeof onChange === "function") onChange(evt);
        };

        return (
          <>
            <MGTagInput
              placeholder={placeholder}
              value={field.value}
              formikField={field}
              formikMeta={meta}
              onChange={onChangeHandler}
            ></MGTagInput>
            <ErrorMessage component={MGHelperText} name={name} />
          </>
        );
      }}
    </MGField>
  );
};

MGTagInputField.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  inline: PropTypes.bool,
};

MGTagInputField.defaultProps = {};

export default MGTagInputField;
