import { ErrorMessage } from "formik";

import PropTypes from "prop-types";
import { MGHelperText } from "@/components/typography";
import MGFileInput from "./MGFileInput";
import MGField from "../BuildingBlocks/MGField";

export const MGFileInputField = ({ name, clearable, placeholder, onChange, accept, disabled, inline }) => {
  return (
    <MGField name={name} inline={inline}>
      {({ meta, field, form }) => {
        const onChangeHandler = (evt) => {
          form.setFieldTouched(name, true);
          form.setFieldValue(field.name, evt.target?.value);
          if (typeof onChange === "function") onChange(evt.target?.files[0]);
        };

        return (
          <>
            <MGFileInput
              clearable={clearable}
              placeholder={placeholder}
              value={field.value}
              onChange={onChangeHandler}
              formikField={field}
              formikMeta={meta}
              accept={accept}
              disabled={disabled}
            />
            <ErrorMessage component={MGHelperText} name={name} />
          </>
        );
      }}
    </MGField>
  );
};

MGFileInputField.propTypes = {
  name: PropTypes.string,
  clearable: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  accept: PropTypes.string,
  inline: PropTypes.bool,
};

MGFileInputField.defaultProps = {
  clearable: true,
};

export default MGFileInputField;
