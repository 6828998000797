// NB: Cross reference with .env.production and 40-envsubst-vite-vars.sh entrypoint for Dockerfile
import { logger } from "@/app/services/logging/Logger";

type AppConfig = {
  DEBUG_I18N: string
  API_BASE_URL: string
  ADMIN_DASHBOARD_HOST: string
  SOCKET_HOST: string
  SCREEN_SHARE_SOCKET_HOST: string
  ENABLE_WHY_DID_YOU_RENDER: string
  GOOGLE_DRIVE_API_KEY: string
  GOOGLE_DRIVE_CLIENT_ID: string
  GOOGLE_API_KEY: string
  STUN_TURN: string
  STUN_TURN_LEGACY: string
  ICE_TRANSPORT_POLICY: string
  BUILD_ID: string
  BUILD_DATE: string
  GIT_SHA: string
  NATS_SERVER_WEBSOCKET: string,
  NATS_TEACHER_USER_NAME: string,
  NATS_TEACHER_PASS: string,
  NATS_TEACHER_AUTH_SEED: string,
  PLACEHOLDER: string,
  ENABLE_LOGIN_PAGE:string,
  CHECK_APP_CONFIG_HASH_INTERVAL_MINS: string,
}


// would regex but no time to write test, bite me
const stripQuotes = (str:string, varSubstName:string) => {
  if (!str) {
    return ""
  }
  return str
      .replaceAll('"', '')
      .replaceAll("'", '')
      .replaceAll(`$${varSubstName}`, '') // if env not set, then env substitution retains placeholder. This will remove it.

};

// Decouple VITE configuration from injected configuration (mostly prevents evaluation at build as needed)
// Defaults in Dockerfile (empty is a good default, because at least sets the var)
const importedVars = {
  ENABLE_WHY_DID_YOU_RENDER: stripQuotes(import.meta.env.VITE_ENABLE_WHY_DID_YOU_RENDER, "ENABLE_WHY_DID_YOU_RENDER" ),
  DEBUG_I18N: stripQuotes(import.meta.env.VITE_DEBUG_I18N, "DEBUG_I18N"),

  ADMIN_DASHBOARD_HOST: stripQuotes(import.meta.env.VITE_ADMIN_DASHBOARD_HOST, "ADMIN_DASHBOARD_HOST"),

  API_BASE_URL: stripQuotes(import.meta.env.VITE_API_BASE_URL, "API_BASE_URL"),
  // NATS_SERVER_WEBSOCKET_URL: stripQuotes(import.meta.env.VITE_NATS_SERVER_WEBSOCKET_URL, "NATS_SERVER_WEBSOCKET_URL"),
  NATS_SERVER_WEBSOCKET: stripQuotes(import.meta.env.VITE_NATS_SERVER_WEBSOCKET, "NATS_SERVER_WEBSOCKET"),
  NATS_TEACHER_USER_NAME: stripQuotes(import.meta.env.VITE_NATS_TEACHER_USER_NAME, "NATS_TEACHER_USER_NAME"),
  NATS_TEACHER_PASS: stripQuotes(import.meta.env.VITE_NATS_TEACHER_PASS, "NATS_TEACHER_PASS"),
  NATS_TEACHER_AUTH_SEED: stripQuotes(import.meta.env.VITE_NATS_TEACHER_AUTH_SEED, "NATS_TEACHER_AUTH_SEED"),
  PLACEHOLDER: stripQuotes(import.meta.env.VITE_PLACEHOLDER, "PLACEHOLDER"),

  SOCKET_HOST: stripQuotes(import.meta.env.VITE_SOCKET_HOST, "SOCKET_HOST"),
  SCREEN_SHARE_SOCKET_HOST: stripQuotes(import.meta.env.VITE_SCREEN_SHARE_SOCKET_HOST, "SCREEN_SHARE_SOCKET_HOST"),
  GOOGLE_DRIVE_API_KEY: stripQuotes(import.meta.env.VITE_GOOGLE_DRIVE_API_KEY, "GOOGLE_DRIVE_API_KEY"),
  GOOGLE_DRIVE_CLIENT_ID: stripQuotes(import.meta.env.VITE_GOOGLE_DRIVE_CLIENT_ID, "GOOGLE_DRIVE_CLIENT_ID"),
  GOOGLE_API_KEY: stripQuotes(import.meta.env.VITE_GOOGLE_API_KEY, "GOOGLE_API_KEY"),
  STUN_TURN: stripQuotes(import.meta.env.VITE_STUN_TURN, "STUN_TURN"),
  STUN_TURN_LEGACY: stripQuotes(import.meta.env.VITE_STUN_TURN_LEGACY, "STUN_TURN_LEGACY"),
  ICE_TRANSPORT_POLICY: stripQuotes(import.meta.env.VITE_ICE_TRANSPORT_POLICY, "ICE_TRANSPORT_POLICY"),
  ENABLE_LOGIN_PAGE: stripQuotes(import.meta.env.VITE_ENABLE_LOGIN_PAGE, "ENABLE_LOGIN_PAGE"),
  CHECK_APP_CONFIG_HASH_INTERVAL_MINS: stripQuotes(import.meta.env.VITE_CHECK_APP_CONFIG_HASH_INTERVAL_MINS, "CHECK_APP_CONFIG_HASH_INTERVAL_MINS"),
}

const compiledVariables = {
  // Hard compiled
  BUILD_ID: stripQuotes(import.meta.env.VITE_BUILD_ID, 'BUILD_ID'), // we want this rendered
  BUILD_DATE: stripQuotes(import.meta.env.VITE_BUILD_DATE, 'BUILD_DATE'), // we want this rendered - cache buster (for if config.ts every gets code split)
  GIT_SHA: stripQuotes(import.meta.env.VITE_GIT_SHA, 'BUILD_GIT_SHA')
}

// all values must be strings. no conditional logic. perhaps accessor functions can be used for primitive types
const config:AppConfig = {
  // Injected
  ENABLE_WHY_DID_YOU_RENDER: importedVars.ENABLE_WHY_DID_YOU_RENDER,
  DEBUG_I18N: importedVars.DEBUG_I18N,

  API_BASE_URL: importedVars.API_BASE_URL,
  NATS_SERVER_WEBSOCKET: importedVars.NATS_SERVER_WEBSOCKET,
  NATS_TEACHER_USER_NAME: importedVars.NATS_TEACHER_USER_NAME,
  NATS_TEACHER_PASS: importedVars.NATS_TEACHER_PASS,
  NATS_TEACHER_AUTH_SEED: importedVars.NATS_TEACHER_AUTH_SEED,
  PLACEHOLDER: importedVars.PLACEHOLDER,
  ADMIN_DASHBOARD_HOST: importedVars.ADMIN_DASHBOARD_HOST,
  SOCKET_HOST: importedVars.SOCKET_HOST,
  SCREEN_SHARE_SOCKET_HOST: importedVars.SCREEN_SHARE_SOCKET_HOST,
  GOOGLE_DRIVE_API_KEY: importedVars.GOOGLE_DRIVE_API_KEY,
  GOOGLE_DRIVE_CLIENT_ID: importedVars.GOOGLE_DRIVE_CLIENT_ID,
  GOOGLE_API_KEY: importedVars.GOOGLE_API_KEY,
  STUN_TURN: importedVars.STUN_TURN,
  STUN_TURN_LEGACY: importedVars.STUN_TURN_LEGACY,
  ICE_TRANSPORT_POLICY: importedVars.ICE_TRANSPORT_POLICY,
  // Hard compiled
  BUILD_ID: compiledVariables.BUILD_ID,
  BUILD_DATE: compiledVariables.BUILD_DATE,
  GIT_SHA: compiledVariables.GIT_SHA,
  ENABLE_LOGIN_PAGE: importedVars.ENABLE_LOGIN_PAGE,
  CHECK_APP_CONFIG_HASH_INTERVAL_MINS: importedVars.CHECK_APP_CONFIG_HASH_INTERVAL_MINS
}

const logConfigVars =(cfg:any)=>{
  //These config var values should not be printed as
  const secrets = [
    "NATS_TEACHER_PASS",
    "NATS_TEACHER_AUTH_SEED",
    "GOOGLE_DRIVE_API_KEY",
    "GOOGLE_API_KEY",
  ];
  const varNames = Object.getOwnPropertyNames(cfg);
  const safeToPrintConfigVar:any = {};

  varNames?.map((varName:string)=>{
    if(secrets.includes(varName)){
      safeToPrintConfigVar[varName] = cfg[varName].replace(/./g, '*');
    } else{
      safeToPrintConfigVar[varName] = cfg[varName];
    }
  })

  logger.log("[config.ts]", safeToPrintConfigVar);
}

try{

  logConfigVars(config);
}
catch(err)
{
  logger.error("[config.ts] Could not print application's config vars")
}


export default config