import DuplicateClassModal from "../components/DuplicateClassModal";
import { useDuplicateClassModal } from "./pageState";

export const DuplicateClassModalContainer = ({ classId, className, onSuccess, onFailure, onCancel }) => {
  const { initialValues, loadingDuplicateClass, onDuplicateClassSubmit, onCancelHandler } = useDuplicateClassModal({
    classId,
    className,
    onSuccess,
    onFailure,
    onCancel,
  });

  return (
    <>
      <DuplicateClassModal
        duplicateClassModalIsShowing={classId && classId !== "" && className && className !== ""}
        initialValues={initialValues}
        loadingDuplicateClass={loadingDuplicateClass}
        onDuplicateClassSubmit={onDuplicateClassSubmit}
        onDuplicateClassCancel={onCancelHandler}
      />
    </>
  );
};

export default DuplicateClassModalContainer;
