import { makeVar } from "@apollo/client";

export const classSessionIdVar = makeVar(!!localStorage.getItem("classSessionId"));

export const classIdVar = makeVar(localStorage.getItem("classId"));

export const classEndedVar = makeVar(localStorage.getItem("classEnded") == "true");

export const classForceJoinVar = makeVar(localStorage.getItem("classForceJoin") == true);

export const classStartTeacherVar = makeVar(JSON.parse(localStorage.getItem("classStartTeacher")) ?? []);

/**
 *
 * @param {object} data
 * {
 *   @prop {string} classSessionId,
 *   @prop {string} classId,
 *   @prop {boolean} classEnded,
 *   @prop {boolean} classForceJoin,
 *   @prop {array} classStartTeacher
 *
 * }
 */
export const updateActiveClass = (data) => {
  if ("classSessionId" in data) {
    localStorage.setItem("classSessionId", data.classSessionId);
    classSessionIdVar(data.classSessionId);
  }

  if ("classId" in data) {
    localStorage.setItem("classId", data.classId);
    classIdVar(data.classId);
  }

  if ("classEnded" in data) {
    localStorage.setItem("classEnded", data.classEnded);
    classEndedVar(data.classEnded);
  }

  if ("classForceJoin" in data) {
    localStorage.setItem("classForceJoin", data.classForceJoin);
    classForceJoinVar(data.classForceJoin);
  }

  if ("classStartTeacher" in data) {
    var temp = [];
    if (localStorage.getItem("classStartTeacher")) temp = JSON.parse(localStorage.getItem("classStartTeacher"));

    if (Array.isArray(data.classStartTeacher)) {
      temp = data.classStartTeacher;
    } else {
      temp.push(data.classStartTeacher);
    }

    if (temp.length == 0) localStorage.removeItem("classStartTeacher");
    else localStorage.setItem("classStartTeacher", JSON.stringify(temp));
    classStartTeacherVar(temp);
  }
};

function loadFromLocalStorage() {
  updateActiveClass({
    classSessionId: localStorage.getItem("classSessionId"),
    classId: localStorage.getItem("classId"),
    classEnded: localStorage.getItem("classEnded") == "true",
    classForceJoin: localStorage.getItem("classForceJoin") == "true",
    classStartTeacher: JSON.parse(localStorage.getItem("classStartTeacher")) ?? [],
  });
}

/**
 *
 * @returns {object}
 */
export const activeClassVar = () => {
  loadFromLocalStorage();
  return {
    classSessionId: classSessionIdVar(),
    classId: classIdVar(),
    classEnded: classEndedVar(),
    classForceJoin: classForceJoinVar(),
    classStartTeacher: classStartTeacherVar(),
  };
};
