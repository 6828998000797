import { MGButton } from "@/components/buttons";
import { MGModalBody, MGModalFooter, MGModalHeader } from "@/components/modals";
import { MGHeading3 } from "@/components/typography";

function MGAlertModal({ title, message, buttonText, onClose }) {
  const onCloseHandler = () => {
    if (typeof onClose == "function") {
      onClose();
    }
  };
  return (
    <div>
      <MGModalHeader>
        <MGHeading3>{title}</MGHeading3>
      </MGModalHeader>
      <MGModalBody>{message}</MGModalBody>
      <MGModalFooter>
        <MGButton variant="primary" onClick={onCloseHandler}>
          {buttonText}
        </MGButton>
      </MGModalFooter>
    </div>
  );
}

MGAlertModal.propTypes = {};

export default MGAlertModal;
