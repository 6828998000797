import "./style.css";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import { MGIconButton } from "@/components/buttons";
import React from "react";
import classNames from "classnames";

export const MGModal = ({ isShowing, children, closable, onClose, fullWidth, minWidth, maxWidth }) => {
  const onOverlayClickHandler = (evt) => {
    if (evt.target.id === "mg-overlay-id" && closable) {
      onClose();
    }
  };

  return isShowing
    ? ReactDOM.createPortal(
        <>
          <div id="mg-overlay-id" onClick={onOverlayClickHandler} className="mg-overlay">
            <MGModalPanel fullWidth={fullWidth} minWidth={minWidth} maxWidth={maxWidth}>
              {closable && (
                <div className="mg-modal__close">
                  <MGIconButton onClick={onClose} variant={"primary"} icon={"close"} />
                </div>
              )}
              {children}
            </MGModalPanel>
          </div>
        </>,

        document.body
      )
    : null;
};

MGModal.propTypes = {
  isShowing: PropTypes.any,
  children: PropTypes.node,
  closable: PropTypes.bool,
  onClose: PropTypes.func,
  fullWidth: PropTypes.bool,
  minWidth: PropTypes.string,
  maxWidth: PropTypes.string,
};

MGModal.defaultProps = {
  width: "auto",
};

export default MGModal;

export const MGModalPanel = ({ children, fullWidth, minWidth, maxWidth }) => {
  const customStyle = {
    minWidth: minWidth,
    maxWidth: maxWidth,
  };

  return (
    <React.Fragment>
      <div
        className={`mg-modal-wrapper ${classNames({ "mg-modal-wrapper__fullwidth": fullWidth })}`}
        style={customStyle}
      >
        {children}
      </div>
    </React.Fragment>
  );
};

MGModalPanel.propTypes = {
  children: PropTypes.node,
  fullWidth: PropTypes.bool,
  minWidth: PropTypes.string,
  maxWidth: PropTypes.string,
};
