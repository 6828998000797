import "./style.css";
import { MGIcon } from "@/components/icons";
import { MGButton } from "@/components/buttons";
import PropTypes from "prop-types";
import { useMGTranslate } from "@/components/translations/useMGTranslate";

export const MGBanner = ({ id, bannerClass, text, positiveText, negativeText, positiveHandler, negativeHandler }) => {
  // const [internalBannerClass, setInternalBannerClass] = useState(bannerClass ?? "info");
  const internalBannerClass = bannerClass ?? "info";
  function internalPositiveHandler() {
    positiveHandler(id);
  }

  const [t] = useMGTranslate();

  function internalNegativeHandler() {
    negativeHandler(id);
  }
  return (
    <div className="--mg-banner-wrapper" data-id={id}>
      <div className="--mg-banner">
        <div className={`--mg-banner-icon ${internalBannerClass ? "--mg-banner-icon-" + internalBannerClass : ""}`}>
          <MGIcon size={32} icon={internalBannerClass} />
        </div>
        <div className="--mg-banner-content">{text || internalBannerClass}</div>
        <div>
          <MGButton onClick={internalPositiveHandler} variant="tertiary">
            {positiveText || t("Proceed")}
          </MGButton>
          <MGButton onClick={internalNegativeHandler} variant="secondary">
            {negativeText || t("Dismiss")}
          </MGButton>
        </div>
      </div>
      <div className={`--mg-banner-accent ${internalBannerClass ? "--mg-banner-" + internalBannerClass : ""}`}></div>
    </div>
  );
};

MGBanner.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  bannerClass: PropTypes.string,
  text: PropTypes.string,
  positiveText: PropTypes.string,
  negativeText: PropTypes.string,
  positiveHandler: PropTypes.func,
  negativeHandler: PropTypes.func,
};

MGBanner.defaultProps = {};

export default MGBanner;
