import { MGIcon } from "@/components/icons";

import "./style.css";
import { MGHelperText } from "@/components/typography";

export const MGTag = (props) => {
  const { disabled, children, onclick } = props;
  let defaultIconSize = 8;

  return (
    <span className={`--mg-tag ${disabled ? "disabled" : ""} ${onclick ? "hoverable" : ""}`}>
      <MGHelperText>{children}</MGHelperText>

      {onclick && (
        <span className={`--mg-tag-icon-right ${disabled ? "disabled" : ""}`} onClick={!disabled ? onclick : null}>
          <MGIcon icon="close" size={defaultIconSize} />
        </span>
      )}
    </span>
  );
};

MGTag.propTypes = {};

MGTag.defaultProps = {
  disabled: false,
};

export default MGTag;
