import PropTypes from "prop-types";
import "./style.css";
import { ErrorMessage } from "formik";
import { MGHelperText } from "@/components/typography";

import MGPassword from "./MGPassword";
import MGField from "../BuildingBlocks/MGField";

export const MGPasswordField = ({ name, clearable, disabled, placeholder, onChange, inline }) => {
  return (
    <MGField name={name} inline={inline}>
      {({ meta, field, form }) => {
        const onChangeHandler = (evt) => {
          form.setFieldValue(field.name, evt);
          if (typeof onChange === "function") onChange(evt);
        };

        return (
          <>
            <MGPassword
              placeholder={placeholder}
              disabled={disabled}
              clearable={clearable}
              value={field.value}
              onChange={(evt) => {
                onChangeHandler(evt);
              }}
              formikField={field}
              formikMeta={meta}
            ></MGPassword>
            <ErrorMessage component={MGHelperText} name={name} />
          </>
        );
      }}
    </MGField>
  );
};

MGPasswordField.propTypes = {
  name: PropTypes.string,
  clearable: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  inline: PropTypes.bool,
};

MGPasswordField.defaultProps = {};

export default MGPasswordField;
