import i18next from "i18next";
/**
 * For now, place here, and use with useMemo
 * @returns {*[]}
 */
export const getDurations = () => {
  var durations = [];
  var duration;
  for (let i = 5; i <= 90; i = i + 5) {
    if (i <= 55) {
      duration = { value: i, content: i18next.t("{{%s}} minutes", { "%s": i }) };
    } else if (i == 60) {
      duration = { value: i, content: i18next.t("1 hour") };
    } else {
      var value = i - 60;
      duration = { value: i, content: i18next.t("1 hour {{%s}} minutes", { "%s": value }) };
    }
    durations.push(duration);
  }
  durations.push({ value: 0, content: i18next.t("Custom") });
  return durations;
};
