import PropTypes from "prop-types";
import "./style.css";
import classNames from "classnames";
import { useEffect, useState } from "react";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export const MGSidebar = ({ collapsed, headerContent, footerContent, children }) => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [isCollapsed, setIsCollapsed] = useState(collapsed);

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <nav
      className={`--mg-sidebar ${classNames({
        "--mg-sidebar-collapsed": isCollapsed || windowDimensions.width < 1024,
      })}`}
    >
      <div className="--mg-sidebar-wrapper">
        {headerContent && (
          <div className="--mg-sidebar-header">
            <div className="--mg-sidebar-collapse-toggle" onClick={() => setIsCollapsed(!isCollapsed)}></div>
            {headerContent}
          </div>
        )}
        <div className="--mg-sidebar-menu">{children}</div>
        {footerContent && <div className="--mg-sidebar-footer">{footerContent}</div>}
      </div>
    </nav>
  );
};

MGSidebar.propTypes = {
  collapsed: PropTypes.bool,
};

MGSidebar.defaultProps = {
  collapsed: false,
};

export default MGSidebar;
