
    export enum IceCandidatePair_ProtocolTypes{
        HOST = "host",
        SERVER_REFLEXIVE = "srflx",
        PEER_REFLEXIVE = "prflx",
        RELAY = "relay"
    }

    export enum ScreenshareStreamProtocol{
        FORCE_CANVAS = "force_canvas",
        FORCE_DESKTOP = "force_desktop",
        NETWORK_BASED = "network_based",
    }

    export function getLocalProtocolTypeForConnection(connection: RTCPeerConnection){
        const protocols = getSelectedCandidatePairForConnection(connection);

        return protocols?.localProtocol?.type;
    }

    export function getRemoteProtocolTypeForConnection(connection: RTCPeerConnection){
        const  protocols  = getSelectedCandidatePairForConnection(connection);

        return protocols?.remoteProtocol?.type;
    }


    function getSelectedCandidatePairForConnection(connection: RTCPeerConnection): any{
        if(!connection)
            return null;

        const senders: RTCRtpSender[] = connection.getSenders();
        if (senders && senders.length > 0 && senders[0].transport) {
            const transport: any = senders[0].transport;

            //Definition for RTCDtlsTransport is out of date, problematic package needs to be identified & version made consistent with Chrome extension's version
            const iceTransport = transport["iceTransport"];
 
            if (iceTransport) {
                const pair = iceTransport.getSelectedCandidatePair();
                return {
                    localProtocol : pair?.local,
                    remoteProtocol : pair?.remote        
                }
            }
        }
       return null;
    }
