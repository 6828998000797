import PropTypes from "prop-types";
import ClassEndModal from "../components/ClassEndModal";

import { useClassEnd } from "./useClassEnd";

// named export for Testing
export const ClassEndModalContainer = ({
  classId,
  classSessionId,
  className,
  onEndClassSuccess,
  onEndClassFailure,
  onExtendClassSuccess,
  onExtendClassFailure,
  onCancel,
}) => {
  if (!classId) return null;

  const { initialValues, onSubmit } = useClassEnd({
    classId,
    classSessionId,
    onEndClassSuccess,
    onEndClassFailure,
    onExtendClassSuccess,
    onExtendClassFailure,
  });

  const handleSubmit = async (values, formik) => {
    await onSubmit(values, formik);
  };

  const handleCancel = () => {
    if (typeof onCancel === "function") onCancel();
  };

  return (
    <>
      <ClassEndModal
        classId={classId}
        className={className}
        isShowing={classId && classId !== "" && classSessionId && classSessionId !== ""}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
      />
    </>
  );
};

ClassEndModalContainer.propTypes = {
  classId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  classSessionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  onEndClassSuccess: PropTypes.func.isRequired,
  onExtendClassSuccess: PropTypes.func.isRequired,
  onEndClassFailure: PropTypes.func.isRequired,
  onExtendClassFailure: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default ClassEndModalContainer;
