import { gql } from "@apollo/client";

// Ignore weirdly named query, it gets all teachers
export const GET_TEACHERS_QUERY = gql`
  query currentSchoolUsers($q: String) {
    currentSchoolUsers(q: $q) {
      ... on UserConnection {
        edges {
          node {
            id
            firstname
            lastname
            username
            email
            phone_number
            timezone
            timezone_offset
            confirmation_code
            marketing_subscribe
            created_at
            telegram_chat_id
            slack_webhook_url
            registration_code_formatted
            device_cap
            asm_synced
            google_synced
            ad_synced
            account_status
            school_disabled
            google_authenticator_active
            role {
              id
              name
            }
            roles {
              id
              name
            }
          }
        }
      }
      ... on NotQuiteSureProblem {
        message
      }
      ... on Problem {
        message
      }
    }
  }
`;
