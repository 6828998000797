import MUTATION_TRANSFER_STUDENTS from "@/app/gql/classes/transfer_students_mutation";
import useMGMutation from "@/app/utils/gql/useMGMutation";
import { logger } from "@/app/services/logging/Logger";

export const useTransferStudentsMutation = ({ classId, onSuccess, onFailure }) => {
  const successHandler = () => {
    typeof onSuccess === "function" && onSuccess();
  };

  const failureHandler = () => {
    typeof onFailure === "function" && onFailure();
  };

  const [transferStudents] = useMGMutation(MUTATION_TRANSFER_STUDENTS);

  const submitHandler = async (values, formik) => {
    try {
      formik.setSubmitting(true);

      await transferStudents({
        variables: {
          input: {
            classId: classId,
            deviceUUIDs: values.devices,
          },
        },
      });

      formik.setSubmitting(false);
      formik.setStatus("SUCCESS");

      successHandler();
    } catch (err) {
      logger.error("err", err);
      formik.setSubmitting(false);
      formik.setStatus("ERROR");
      failureHandler();
    }
  };

  return {
    submitHandler,
  };
};
