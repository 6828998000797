// quick copy paste from examples on https://www.npmjs.com/package/react-error-boundary
// we really should never get here, ensure we have sufficient error boundary coverage (see https://reactjs.org/docs/error-boundaries.html)
// FIXME: Delete me, ensure top level Error Boundaries
import { MGIconButton } from "@/components/buttons";
import MGContent from "@/app/app-components/MGContent/MGContent";
import MGSurface from "@/app/app-components/MGSurface/MGSurface";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { MGHeading1, MGHeading3 } from "@/components/typography";
import { MGFlexBox } from "@/components/layout";
import { MGSpacing } from "@/components/spacing";
import "./style.css";
import { MGTooltip } from "@/components/tooltip";
import { useApolloClient } from "@apollo/client";
import { isLoggedInVar } from "@/cache";
import { logger } from "@/app/services/logging/Logger";

export const PageErrorFallback = ({ error, resetErrorBoundary }) => {
  const navigate = useNavigate();

  const client = useApolloClient();

  logger.error("Runtime error thrown: ", error);

  const homeButtonClick = (evt) => {
    navigate("/");
    resetErrorBoundary(evt);
  };

  const resetButtonClick = (evt) => {
    resetApplicationState();
    navigate("/");
    resetErrorBoundary(evt);
  };

  const logoutButtonClick = (evt) => {
    resetApplicationState();
    resetUserState();
    navigate("/login");
    resetErrorBoundary(evt);
  };

  const resetApplicationState = () => {
    client.resetStore();
    localStorage.removeItem("classId");
    localStorage.removeItem("classSessionId");
    window.location.reload();
  };

  const resetUserState = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("currentUser");
    isLoggedInVar(false);
  };

  const { t } = useTranslation();

  return (
    <div className="--page-error-container">
      <MGContent>
        <MGSurface>
          <div>
            <div style={{ marginTop: "15%", textAlign: "center" }}>
              <img src="/images/mg-logo-dark.svg" height="120px" width="120px" />
            </div>
            <br />
            <br />
            <MGFlexBox>
              <MGHeading1>{t("Well, this is embarrassing...")}</MGHeading1>
            </MGFlexBox>
            <br />
            <br />
            <MGFlexBox>
              <MGHeading3>{t("Something unexpected happened and we were unable to process your request")}</MGHeading3>
            </MGFlexBox>
            <br />
            <MGFlexBox>
              <MGHeading3>
                {t("Please click one of the buttons below, or contact support if the problem persists")}
              </MGHeading3>
            </MGFlexBox>
            <br />
            <br />
            <br />
            <MGFlexBox>
              <MGTooltip position={"bottom"} text={t("Return to Home page")}>
                <MGIconButton icon={"first"} onClick={homeButtonClick} variant={"primary"} iconSize={30} />
              </MGTooltip>
              <MGSpacing size={"xxl"} />
              <MGSpacing size={"xxl"} />
              <MGSpacing size={"xxl"} />
              <MGTooltip position={"bottom"} text={t("Reset application")}>
                <MGIconButton icon={"refresh"} variant={"primary"} onClick={resetButtonClick} iconSize={30} />
              </MGTooltip>
              <MGSpacing size={"xxl"} />
              <MGSpacing size={"xxl"} />
              <MGSpacing size={"xxl"} />

              <MGTooltip position={"bottom"} text={t("Log out")}>
                <MGIconButton icon={"logout"} variant={"primary"} onClick={logoutButtonClick} iconSize={30} />
              </MGTooltip>
            </MGFlexBox>
            {/* <h1>😱{t("Page Error - Something bad happened")}</h1>
            <p>{error.message}</p>
            <MGButton onClick={onClick}>{t("Return to where you were")}</MGButton> */}
          </div>
        </MGSurface>
      </MGContent>
    </div>
  );
};
