import { useSearchParams } from "react-router-dom";
import "./style.css";
import { ManualLogin } from "./components/ManualLogin";
import { NonceLogin } from "./components/NonceLogin";
import { NotFoundPage } from "@/app/ErrorPages/NotFoundPage";
import config from "@/config";

export const LoginPage = () => {
  const [searchParams] = useSearchParams();

  const nonce = searchParams.get("nonce");

  let allowManualLogin = false;

  if (config?.ENABLE_LOGIN_PAGE && config?.ENABLE_LOGIN_PAGE === "true") {
    allowManualLogin = true;
  }

  if (nonce) {
    return <NonceLogin nonce={nonce} />;
  } else {
    if (allowManualLogin) {
      return <ManualLogin />;
    } else {
      return <NotFoundPage goBackButtonLink={"/logout"} />;
    }
  }
};
