import { useEffect } from "react";
import { useNavigate } from "react-router";

export const HealthPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/");
  });

  return <>Health Check OK</>;
};

export default HealthPage;
