import { MGButton } from "@/components/buttons";
import { useMGTranslate } from "@/components/translations/useMGTranslate";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useRef, useState } from "react";
import MGInput from "../BuildingBlocks/MGInput";
import MGInputButtons from "../BuildingBlocks/MGInputButtons";
import MGInputWrapper from "../BuildingBlocks/MGInputWrapper";
import "./style.css";

export const MGFileInput = ({ placeholder, disabled, onChange, formikField, formikMeta, accept }) => {
  const [fileName, setFileName] = useState("");

  const nativeFileInputRef = useRef();

  const [t] = useMGTranslate();

  const onChangeHandler = (evt) => {
    // setFileName(evt?.target?.value)
    setFileName(evt?.currentTarget?.files[0]?.name ?? "");

    if (typeof onChange == "function") {
      onChange(evt);
    }
  };

  const onUploadClick = () => {
    // logger.log(nativeFileInputRef.current);
    nativeFileInputRef.current.click();
  };

  return (
    <div className="--mg-file-input">
      <MGInputWrapper {...{ ...formikMeta }} disabled={disabled}>
        <MGInput type={"text"} value={fileName} placeholder={placeholder} readOnly onClick={onUploadClick} />
        <MGInput
          {...formikField}
          style={{ display: "none" }}
          type="file"
          onChange={onChangeHandler}
          placeholder={placeholder}
          accept={accept}
          ref={nativeFileInputRef}
        ></MGInput>
        <MGInputButtons>
          <div
            className={`
          ${classNames({
            "button-border": true,
            error: formikMeta?.error && formikMeta?.touched,
            disabled: disabled,
          })}`}
            disabled={disabled}
          >
            {" "}
          </div>
          <MGButton variant="tertiary" disabled={disabled} onClick={onUploadClick}>
            {t("Browse")}
          </MGButton>
        </MGInputButtons>
      </MGInputWrapper>
    </div>
  );
};

MGFileInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  clearable: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  formikField: PropTypes.any,
  formikMeta: PropTypes.any,
  disabled: PropTypes.bool,
  accept: PropTypes.string,
};

MGFileInput.defaultProps = {
  placeholder: "Select a file",
};

export default MGFileInput;
