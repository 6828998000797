// quick copy paste from examples on https://www.npmjs.com/package/react-error-boundary
// we really should never get here, ensure we have sufficient error boundary coverage (see https://reactjs.org/docs/error-boundaries.html)
// FIXME: Delete me, ensure top level Error Boundaries
import MGSurface from "@/app/app-components/MGSurface/MGSurface";
import MGContent from "@/app/app-components/MGContent/MGContent";

import { useTranslation } from "react-i18next";
import { useApolloClient } from "@apollo/client";
import MGContainer from "../MGContainer/MGContainer";
import { isLoggedInVar } from "@/cache";
import { MGFlexBox } from "@/components/layout";
import { MGHeading1, MGHeading3 } from "@/components/typography";
import { MGTooltip } from "@/components/tooltip";
import { MGIconButton } from "@/components/buttons";
import { MGSpacing } from "@/components/spacing";
import { logger } from "@/app/services/logging/Logger";

export const SiteErrorFallback = ({ error, resetErrorBoundary }) => {
  const client = useApolloClient();

  logger.error("Sitewide error thrown: ", error);

  const homeButtonClick = (evt) => {
    window.location.href = "/";
    resetErrorBoundary(evt);
  };

  const resetButtonClick = (evt) => {
    resetApplicationState();
    window.location.href = "/";
    resetErrorBoundary(evt);
  };

  const logoutButtonClick = (evt) => {
    resetApplicationState();
    resetUserState();
    window.location.href = "/";
    resetErrorBoundary(evt);
  };

  const resetApplicationState = () => {
    client.resetStore();
    localStorage.removeItem("classId");
    localStorage.removeItem("classSessionId");
    window.location.reload();
  };

  const resetUserState = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("currentUser");
    isLoggedInVar(false);
  };

  const { t } = useTranslation();

  return (
    <div className="--mg-app">
      {/*    MG SIDEBAR HERE*/}
      {/* <div className="--page-error-container"> */}
      <MGContainer>
        <MGContent>
          <MGSurface>
            <div className="--page-error-container">
              <div style={{ marginTop: "15%", textAlign: "center" }}>
                <img src="/images/mg-logo-dark.svg" height="120px" width="120px" />
              </div>
              <br />
              <br />
              <MGFlexBox>
                <MGHeading1>{t("Something's not right...")}</MGHeading1>
              </MGFlexBox>
              <br />
              <br />
              <MGFlexBox>
                <MGHeading3>
                  {t("Something unexpected happened and our app needs a second to catch its breath")}
                </MGHeading3>
              </MGFlexBox>
              <br />
              <MGFlexBox>
                <MGHeading3>
                  {t("Please click one of the buttons below, or contact support if the problem persists")}
                </MGHeading3>
              </MGFlexBox>
              <br />
              <br />
              <br />
              <MGFlexBox>
                <MGTooltip position={"bottom"} text={t("Return to Home page")}>
                  <MGIconButton icon={"first"} onClick={homeButtonClick} variant={"primary"} iconSize={30} />
                </MGTooltip>
                <MGSpacing size={"xxl"} />
                <MGSpacing size={"xxl"} />
                <MGSpacing size={"xxl"} />
                <MGTooltip position={"bottom"} text={t("Reset application")}>
                  <MGIconButton icon={"refresh"} variant={"primary"} onClick={resetButtonClick} iconSize={30} />
                </MGTooltip>
                <MGSpacing size={"xxl"} />
                <MGSpacing size={"xxl"} />
                <MGSpacing size={"xxl"} />

                <MGTooltip position={"bottom"} text={t("Log out")}>
                  <MGIconButton icon={"logout"} variant={"primary"} onClick={logoutButtonClick} iconSize={30} />
                </MGTooltip>
              </MGFlexBox>
              {/* <h1>😱{t("Page Error - Something bad happened")}</h1>
            <p>{error.message}</p>
            <MGButton onClick={onClick}>{t("Return to where you were")}</MGButton> */}
            </div>
          </MGSurface>
        </MGContent>
      </MGContainer>
      {/* </div> */}
    </div>
  );
};
