import PropTypes from "prop-types";
import "./style.css";
import { MGDropdown, MGDropdownItem, MGInput, MGInputWrapper } from "@/components/inputs";
import { MGSpacing } from "@/components/spacing";
import { MGHelperText, MGLabel } from "@/components/typography";
import { Link } from "react-router-dom";
import { MGIcon } from "@/components/icons";

import classnames from "classnames";

import { config } from "@/app/config";
import { useMGTranslate } from "@/components/translations/useMGTranslate";
import { useEffect, useState } from "react";

export const MGLinkPagination = ({
  rowOptions,
  pageSize,
  currentPage,
  totalPages,
  totalCount,
  onPageChange,
  onPageSizeChange,

  firstLink,
  previousLink,
  nextLink,
  lastLink,
}) => {
  const [pageNumber, setPageNumber] = useState(currentPage);

  const [t] = useMGTranslate();

  const firePageChangeEvent = (number) => {
    typeof onPageChange === "function" && onPageChange(number);
  };

  const firePageSizeChangeEvent = (number) => {
    typeof onPageSizeChange === "function" && onPageSizeChange(number);
  };

  const pageTextChangeHandler = (evt) => {
    let number = evt === "" ? "" : evt.target.value;
    // if (number > totalPages || number < 1 || Number.isNaN(number)) return;

    if (number === "") {
      setPageNumber("");
      //firePageChangeEvent(0);
    }

    let newNumber = Number.parseInt(number, 10);

    if (!Number.isNaN(newNumber)) {
      if (totalPages && newNumber > totalPages) return;
      setPageNumber(newNumber);
      firePageChangeEvent(newNumber);
    }
  };

  const pageSizeChangedHandler = (evt) => {
    const newPageSize = evt;

    firePageSizeChangeEvent(newPageSize);
  };

  let hasPreviousPage = currentPage > 1;
  let hasNextPage = currentPage < totalPages;

  const showingRows = () => {
    return (
      <>
        {totalCount > 0 && (
          <MGHelperText>
            {t("Displaying rows {{%s}} - {{%s2}} of {{%s3}}", {
              "%s": pageSize * currentPage - (pageSize - 1),
              "%s2": pageSize * currentPage > totalCount ? totalCount : pageSize * currentPage,
              "%s3": totalCount,
            })}
            {/* Displaying rows&nbsp;{pageSize * currentPage - (pageSize - 1)}&nbsp;-&nbsp;
            {pageSize * currentPage > totalCount ? totalCount : pageSize * currentPage}
            &nbsp;of&nbsp;{totalCount} */}
          </MGHelperText>
        )}
      </>
    );
  };

  useEffect(() => {
    if (currentPage) setPageNumber(currentPage);
  }, [currentPage]);

  return (
    <div className="--mg-link-pagination">
      <div className="--mg-link-pagination-row-options">
        <div style={{ maxWidth: "100px" }}>
          {/* <select name="pageSize" id="pageSize" onChange={pageSizeChangedHandler} defaultValue={pageSize}>
            {rowOptions?.map((option, idx) => {
              return (
                <option key={idx} value={option}>
                  {option}
                </option>
              );
            })}
          </select> */}
          <MGDropdown value={pageSize} onChange={pageSizeChangedHandler}>
            {rowOptions?.map((option, idx) => {
              return (
                <MGDropdownItem key={idx} value={option}>
                  {option}
                </MGDropdownItem>
              );
            })}
          </MGDropdown>
        </div>
        <MGSpacing size="xs"></MGSpacing>
        <MGLabel>{t("per page")}</MGLabel>
      </div>
      <div>{showingRows()}</div>
      <div className="--mg-link-pagination-page-selector">
        <MGPaginationLink icon={"first"} linkTo={firstLink} disabled={!hasPreviousPage} />
        <MGPaginationLink icon={"previous"} linkTo={previousLink} disabled={!hasPreviousPage} />

        <MGSpacing size="lg"></MGSpacing>
        <div style={{ maxWidth: "60px" }}>
          {/* <MGNumberBox value={pageNumber} onChange={pageTextChangeHandler} clearable={false}></MGNumberBox> */}
          <MGInputWrapper>
            <MGInput
              value={pageNumber}
              onChange={pageTextChangeHandler}
              onFocus={() => {
                if (pageNumber) setPageNumber("");
              }}
            />
          </MGInputWrapper>
        </div>
        <MGSpacing size="xs"></MGSpacing>
        <div>
          <MGLabel>{t("of {{%s}}", { "%s": totalPages })}</MGLabel>
        </div>
        <MGSpacing size="lg"></MGSpacing>
        <MGPaginationLink icon={"next"} linkTo={nextLink} disabled={!hasNextPage} />
        <MGPaginationLink icon={"last"} linkTo={lastLink} disabled={!hasNextPage} />
      </div>
    </div>
  );
};

MGLinkPagination.propTypes = {
  rowOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
  pageSize: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  totalCount: PropTypes.number,
  onPageChange: PropTypes.func,
  onPageSizeChange: PropTypes.func,

  firstLink: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object, // FIXME: shape of React Router's `To` (see https://reactrouter.com/docs/en/v6/components/link)
  ]),
  previousLink: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object, // FIXME: shape of React Router's `To` (see https://reactrouter.com/docs/en/v6/components/link)
  ]),
  nextLink: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object, // FIXME: shape of React Router's `To` (see https://reactrouter.com/docs/en/v6/components/link)
  ]),
  lastLink: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object, // FIXME: shape of React Router's `To` (see https://reactrouter.com/docs/en/v6/components/link)
  ]),
};

MGLinkPagination.defaultProps = {
  rowOptions: config.PAGINATION.ROW_OPTIONS,
  currentPage: 0,
  totalPages: 0,
  pageSize: 10,
};

export const MGPaginationLink = ({ icon, disabled, linkTo }) => {
  return (
    <div
      className={`--mg-link-pagination-link ${classnames({
        "--mg-link-pagination-link__disabled": disabled,
      })}`}
    >
      {linkTo && !disabled ? (
        <Link to={linkTo}>
          <MGIcon icon={icon} />
        </Link>
      ) : (
        <MGIcon icon={icon} />
      )}
    </div>
  );
};

MGPaginationLink.propTypes = {
  icon: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  linkTo: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object, // FIXME: shape of React Router's `To` (see https://reactrouter.com/docs/en/v6/components/link)
  ]),
};

MGPaginationLink.defaultProps = {
  disabled: false,
};

export default MGLinkPagination;
