export const config = {
  PAGINATION: {
    ROW_OPTIONS: [5, 10, 50, 100],
    PAGE_SIZE: 10,
    CURRENT_PAGE: 1,
    VIEW: "list",
  },
  CLASS: {
    TEACH: {
      MAXIMUM_ACTIVE_CLASSES_DISPLAYED: 10,
      MAXIMUM_UPCOMING_CLASSES_DISPLAYED: 10,
    },
  },
};
