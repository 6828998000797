import PropTypes from "prop-types";
import "./style.css";
import { Field } from "formik";
import classNames from "classnames";

export const MGField = ({ name, children, inline, rest }) => {
  return (
    <div className={`--mg-field ${classNames({ "--mg-field__inline": inline })}`}>
      <Field name={name} {...rest}>
        {(formik) => children(formik)}
      </Field>
    </div>
  );
};

MGField.propTypes = {
  name: PropTypes.string,
  children: PropTypes.func,
  inline: PropTypes.bool,
  rest: PropTypes.any,
};

MGField.defaultProps = {
  inline: false,
};

export default MGField;
