import { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

import "./style.css";
import { MGIcon } from "@/components/icons";
import { MGInput, MGInputsOverlay, MGInputWrapper } from "@/components/inputs";
import { MGDatePickerControl } from "../MGDatePickerControl";

export const MGDatePicker = ({ placeholder, value, minDate, maxDate, onChange, formikField, formikMeta }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [displayVal, setDisplayVal] = useState("");
  const [selectedDate, setSelectedDate] = useState(value);

  const trigger = useRef();

  useEffect(() => {
    if (value) setDisplayVal(formatDate(value));
  }, [value]);

  const formatDate = (val) => {
    const userLocale = window.navigator.language;
    return new Date(val).toLocaleDateString(userLocale);
  };

  const toggle = useCallback(() => {
    setIsOpen(!isOpen);
  });

  const onChangeHandler = (evt) => {
    toggle();
    if (typeof onChange === "function") onChange(evt);

    setSelectedDate(evt);
    setDisplayVal(formatDate(evt));
  };

  return (
    <div ref={trigger} className={"--mg-date-picker-input"}>
      <MGInputWrapper {...{ ...formikMeta }}>
        <MGIcon size={16} icon="date"></MGIcon>
        <MGInput
          {...formikField}
          type="text"
          value={displayVal}
          onClick={toggle}
          placeholder={placeholder}
          readOnly
        ></MGInput>
      </MGInputWrapper>

      {isOpen && (
        <MGInputsOverlay trigger={trigger} onOutsideClick={toggle}>
          <div className="--mg-date-picker">
            <MGDatePickerControl
              unbordered
              minDate={minDate}
              maxDate={maxDate}
              value={selectedDate}
              onChange={onChangeHandler}
            ></MGDatePickerControl>
          </div>
        </MGInputsOverlay>
      )}
    </div>
  );
};

MGDatePicker.propTypes = {
  placeholder: PropTypes.string,
  //value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  onChange: PropTypes.func,
  formikField: PropTypes.any,
  formikMeta: PropTypes.any,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
};

MGDatePicker.defaultProps = {
  placeholder: "dd/mm/yyyy",
};

export default MGDatePicker;
