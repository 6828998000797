import { gql } from "@apollo/client";

export const MUTATION_TRANSFER_STUDENTS = gql`
  mutation classForceTransfer($input: ClassDeviceActionForceTransferInput!) {
    classForceTransfer(input: $input) {
      handled
      errors {
        ... on NotQuiteSureProblem {
          message
        }
        ... on Problem {
          message
        }
      }
    }
  }
`;

export default MUTATION_TRANSFER_STUDENTS;
