import "./style.css";

export const MGRadioGroup = ({ children, onChange, asList }) => {
  function onChangeValue(event) {
    if (typeof onChange === "function") {
      onChange(event.target.value);
    }
  }

  return (
    <div className={`--mg-radio-group${asList ? "-list" : ""}`} onChange={onChangeValue}>
      {children}
    </div>
  );
};

MGRadioGroup.propTypes = {};

MGRadioGroup.defaultProps = {};

export default MGRadioGroup;
