import { gql } from "@apollo/client";

export const GET_CLASS_MODES_CLASS_CONTENT_AND_CURRENT_USER = gql`
  query classModesByClass($classId: ID!) {
    classContents(classId: $classId) {
      __typename
      ... on ClassContentConnection {
        edges {
          node {
            id
            name
            object_content
            meta_url
            object_type
          }
        }
      }
      ... on NotQuiteSureProblem {
        message
      }
      ... on Problem {
        message
      }
    }

    classModesByClass(classId: $classId) {
      __typename
      ... on ClassModeConnection {
        edges {
          node {
            id
            name
            description
            created_at
            updated_at
            deleted_at
            allow_raise_hand
          }
        }
      }
      ... on NotQuiteSureProblem {
        message
      }
      ... on Problem {
        message
      }
    }

    currentUser {
      __typename
      ... on CurrentUser {
        id
        firstname
        lastname
        username
        email
      }
      ... on NotQuiteSureProblem {
        message
      }
      ... on Problem {
        message
      }
    }
  }
`;

export default GET_CLASS_MODES_CLASS_CONTENT_AND_CURRENT_USER;
