import { authService } from "@/main";
import { useQuery } from "@apollo/client";

import { useEffect, useMemo, useRef } from "react";
import findGQLProblem from "./findGQLProblem";
import { logger } from "@/app/services/logging/Logger";

export const useMGQuery = (query, options) => {
  let problems = null;
  let refreshing = useRef(false);
  let weHaveAProblem = false;

  if (options && options?.onCompleted)
  {
    logger.warn("As of React 18, providing onCompleted to Apollo's useQuery will yield inconsistent results. Use a useEffect hook with 'loading' as a dependency to detect once query has completed.")
  }
  
  const checkIf401ProblemExists = (problems, requestNewAuthTokenIfUnauthorized) => {
    let found = false;
    if (problems) {
      problems?.map((problem) => {
        if ((problem && problem?.message?.includes("401: Unauthorized")) || problem?.includes("401: Unauthorized")) {
          found = true;
          if (requestNewAuthTokenIfUnauthorized) {
            authService.requestNewAuthToken();
          }
        }
      });
    }
    return found;
  };

  let isBusyRefreshingOnChange = authService.isBusyRefreshingOnChange();
  isBusyRefreshingOnChange.subscribe((isRefreshing) => {
    if (!isRefreshing) {
      if (weHaveAProblem) {
        weHaveAProblem = false;
        refetch(); //Rerun query after token was refreshed, but we do not want to refetch if data was retrieved successfully on first run
      } else {
        refreshing.current = false;
      }
    } else {
      refreshing.current = true;
    }
  });


  const queryResponseProps = useQuery(query, {
    ...options,
  });

  const { data, error, loading, refetch } = queryResponseProps;

  useEffect(()=>{
    if(!loading){
      if (queryResponseProps && queryResponseProps.data != undefined) {
        if (checkIf401ProblemExists(problems, true)) {
          weHaveAProblem = true;
        } else {
          weHaveAProblem = false;
          //if (options && options?.onCompleted && typeof options.onCompleted === "function") options.onCompleted(response);
        }
      } else {
        weHaveAProblem = true;
      }
    }
  }, [loading])

  const refetchHandler = () => {
    refetch();
  };

  useMemo(() => {
    problems = findGQLProblem(queryResponseProps?.data);
    if (((queryResponseProps?.data == undefined || queryResponseProps?.data == null) && error) || error) {
      if (problems == null) {
        problems = [];
        problems.push(queryResponseProps?.error?.message);
        if (checkIf401ProblemExists(problems, true)) {
          weHaveAProblem = true;
        }
      } else {
        problems.push(queryResponseProps?.error?.message);
        if (checkIf401ProblemExists(problems, true)) {
          weHaveAProblem = true;
        }
      }
    }
  }, [loading, refreshing.current, refreshing]);

  const maskedLoading = useMemo(() => {
    if (refreshing.current) {
      return true;
    } else if (loading) {
      return true;
    } else if (!loading) {
      if (queryResponseProps?.error?.message?.includes("401: Unauthorized")) {
        return true;
      }

      if (problems) {
        problems?.map((problem) => {
          let message = problem?.message;
          if (message && message !== "") {
            if (message.includes("401: Unauthorized")) {
              return true;
            }
          }
        });
      } else if (problems == null) {
        Object.keys(queryResponseProps?.data).map((childOfData) => {
          if (queryResponseProps?.data[childOfData].message?.includes("401: Unauthorized")) {
            return true;
          }
        });
        return false;
      }
    }
  }, [loading, refreshing.current, refreshing]);

  const MGQueryResponseProps = {
    data: data,
    error: error,
    loading: loading || maskedLoading,
    // realLoading: loading,
    // maskedLoading:maskedLoading,
    refetch: refetchHandler,
    problems: problems,
  };

  return MGQueryResponseProps;
};

export default useMGQuery;
