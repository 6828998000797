import { useState } from "react";
import "./style.css";
// import { MGActionTextbox } from "../MGActionTextbox";
import { MGInputList } from "../BuildingBlocks/MGInputList";
import { MGAutocomplete } from "../MGAutocomplete";
import PropTypes from "prop-types";

export const MGInputListBox = ({
  value,
  removable,
  placeholder,
  onChange,
  onTextChange,
  suggestions,
  allowFreeText,
  listItemValidator,
  formikField,
  formikMeta,
  displayProp,
  suggestionRenderer,
  filter,
  displayExpr,
}) => {
  const [text, setText] = useState("");

  const [listValues, setListValues] = useState(value);

  function onChangeHandler(data) {
    if (typeof onChange == "function") {
      onChange(data);
    }
  }

  const removeListItemHandler = (item) => {
    let tempList = listValues.slice();
    tempList.splice(tempList.indexOf(item), 1);
    setListValues(tempList);
    onChangeHandler(tempList);
  };

  const listItemIsValid = (text) => {
    // if (text != "") {
    if (typeof listItemValidator === "function") {
      if (listItemValidator(text)) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
    // }
    //return false;
  };

  const addlistItemHandler = () => {
    let tempList = [...listValues];

    if (listItemIsValid(text) && text !== "") {
      if (!allowFreeText) {
        if (typeof filter === "function") {
          let value = filter(text);
          if (value && value.length == 1) {
            tempList.push(value[0]);
          } else {
            return;
          }
        } else {
          if (!suggestions?.includes(text)) return;
          tempList.push(text);
        }
      } else {
        tempList.push(text);
      }

      //logger.log("Item is valid");
      // logger.log("TEMP", tempList);

      setListValues(tempList);

      onChangeHandler(tempList);

      handleTextChange("");
    }
  };

  const handleTextChange = (data) => {
    if (typeof onTextChange === "function") {
      onTextChange(data);
    }
    setText(data);
  };

  return (
    <span className="--mg-input-list-box">
      <MGAutocomplete
        value={text}
        suggestions={suggestions?.filter((suggestion) => {
          return !value?.includes(suggestion);
        })}
        placeholder={placeholder}
        onChange={handleTextChange}
        onActionButtonClick={() => {
          addlistItemHandler();
        }}
        actionButtonIcon="add"
        enterKeyFiresAction={true}
        formikField={formikField}
        formikMeta={formikMeta}
        displayProp={displayProp}
        suggestionRenderer={suggestionRenderer}
        filter={filter}
      />
      {listValues.length > 0 && (
        <>
          <div className="--mg-input-list-spacer"></div>
          <MGInputList
            value={listValues}
            removable={removable}
            displayProp={displayProp}
            displayExpr={displayExpr}
            onItemRemoved={(item) => removeListItemHandler(item)}
          ></MGInputList>
        </>
      )}
    </span>
  );
};

MGInputListBox.propTypes = {
  /**
   *  Array value of the selected items in the component
   */
  value: PropTypes.array,
  /**
   *  Boolean indicating whether or not items in the list can be removed or not
   */
  removable: PropTypes.bool,
  /**
   *  Placeholder text of the textbox input
   */
  placeholder: PropTypes.string,
  /**
   *  Event that gets fired that emits current selected items when the selected items of the component has been modified
   */
  onChange: PropTypes.func,
  /**
   *  List of suggestions to appear as the user types (Must be array of strings)
   */
  suggestions: PropTypes.array,
  /**
   *  Boolean indicating whether or not an item that is trying to be added to the list NEEDS to exist as an array element within the "suggestions" prop
   */
  allowFreeText: PropTypes.bool,
  /**
   *  Function that allows you to return true or false depending on if the emitted text value is a valid option to add to the selected items in the list
   */
  listItemValidator: PropTypes.func,
  /**
   *  Formik field data, not to be used outside of a Formik Field tag
   */
  formikField: PropTypes.object,
  /**
   *  Formik field metadata object, not to be used outside of a Formik Field tag
   */
  formikMeta: PropTypes.object,

  displayProp: PropTypes.string,

  suggestionRenderer: PropTypes.func,

  filter: PropTypes.func,

  displayExpr: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),

  onTextChange: PropTypes.func,
};

MGInputListBox.defaultProps = {
  value: [],
  allowFreeText: true,
  removable: true,
};

export default MGInputListBox;
