import "./style.css";
import classnames from "classnames";
import { MGHeading1, MGHeading3 } from "@/components/typography";
import { MGImage } from "@/components/image";
import PropTypes from "prop-types";

export const MGAvatar = ({ color, bordered, img, letter, secondLetter, isSmall, ...rest }) => {
  let letterOne = letter ? letter.toUpperCase().substring(0, 1) : "";
  let letterTwo = secondLetter ? secondLetter.toUpperCase().substring(0, 1) : "";

  return (
    <span
      className={`--mg-avatar ${classnames({
        "--mg-avatar-large": !isSmall,
        "--mg-avatar-small": isSmall,
        "--mg-avatar-bordered": bordered,
      })}`}
      {...rest}
      style={{ backgroundColor: color }}
    >
      {!img ? (
        <span className="--mg-avatar-font">
          {isSmall ? (
            <MGHeading3>
              {letterOne}
              {letterTwo}
            </MGHeading3>
          ) : (
            <MGHeading1>
              {letterOne}
              {letterTwo}
            </MGHeading1>
          )}
        </span>
      ) : (
        <span>
          <MGImage src={img} round={true} />
        </span>
      )}
    </span>
  );
};

MGAvatar.propTypes = {
  bordered: PropTypes.bool,
  img: PropTypes.string,
  letter: PropTypes.string,
  secondLetter: PropTypes.string,
  isSmall: PropTypes.bool,
};

MGAvatar.defaultProps = {
  bordered: false,
  img: "",
  letter: "",
  secondLetter: "",
  isSmall: false,
};

export default MGAvatar;
