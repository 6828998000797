import "./style.css";
import { MGHelperText } from "@/components/typography";
import PropTypes from "prop-types";
import { ErrorMessage } from "formik";
import { MGCheckbox } from "./MGCheckbox";
import { MGField } from "@/components/inputs";

export const MGCheckboxField = ({ name, children, disabled, indeterminate, inline }) => {
  return (
    <MGField name={name} inline={inline}>
      {({ field, form }) => {
        const onChangeHandler = (evt) => {
          form.setFieldTouched(field.name, true); //This can be removed later
          form.setFieldValue(field.name, evt.target.checked);
        };
        return (
          <>
            <MGCheckbox
              value={field.value}
              disabled={disabled}
              indeterminate={indeterminate}
              onChange={onChangeHandler}
              formikField={field}
            >
              {children}
              <ErrorMessage component={MGHelperText} name={name} />
            </MGCheckbox>
          </>
        );
      }}
    </MGField>
  );
};

MGCheckboxField.propTypes = {
  name: PropTypes.string,
  children: PropTypes.any,
  disabled: PropTypes.bool,
  indeterminate: PropTypes.bool,
  inline: PropTypes.bool,
};

MGCheckboxField.defaultProps = {
  disable: false,
  indeterminate: false,
};

export default MGCheckboxField;
