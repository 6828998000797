import { gql } from "@apollo/client";

export const EXTEND_CLASS_MUTATION = gql`
  mutation extendClass($input: ExtendClassInput!) {
    extendClass(input: $input) {
      classSession {
        id
        has_ended
        duration
        pin
        home_url
        created_at
        updated_at
        session_end_time
      }
      errors {
        __typename
        ... on NotQuiteSureProblem {
          message
        }
        ... on Problem {
          message
        }
      }
    }
  }
`;

export default EXTEND_CLASS_MUTATION;
