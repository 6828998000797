import { useMutation } from "@apollo/client";
import findGQLProblem from "./findGQLProblem";
import i18next from "i18next";
import { useNotification } from "@/components/notification/MGNotificationProvider/useNotification";
import { authService } from "@/main";

export const useMGMutation = (mutation) => {
  const [mutationPromise, options] = useMutation(mutation);
  const [notification] = useNotification();

  const onCompletedHandler = (gqlResponse) => {
    if (options && options.onCompleted && typeof options.onCompleted === "function") options.onCompleted(gqlResponse);
    return {
      ...gqlResponse,
      problems: findGQLProblem(gqlResponse?.data),
    };
  };

  const onErrorHandler = (gqlResponse) => {
    if (options && options.onError && typeof options.onError === "function") options.onError(gqlResponse);
    return {
      ...gqlResponse,
      problems: findGQLProblem(gqlResponse?.data),
    };
  };

  const wrappedMutationPromise = async (mutationOptions) => {
    await authService.checkAndRefreshToken(async () => {
      //If refresh token fails, this will be called
      notification.warning({ message: i18next.t("Your session has timed out, you will be logged out shortly") });
    });

    return mutationPromise(mutationOptions).then(onCompletedHandler, onErrorHandler);
  };

  return [wrappedMutationPromise, options];
};

export default useMGMutation;
