import "./style.css";
import MGInputWrapper from "../BuildingBlocks/MGInputWrapper";
import MGInput from "../BuildingBlocks/MGInput";
import { MGIconButton } from "@/components/buttons";
import MGInputButtons from "../BuildingBlocks/MGInputButtons";
import PropTypes from "prop-types";

export const MGSearchBox = ({ value, onChange, onSearch, placeholder, formikField, formikMeta }) => {
  const onChangeHandler = (evt) => {
    if (typeof onChange === "function") onChange(evt.target.value);
  };

  return (
    <>
      <MGInputWrapper {...{ ...formikMeta }}>
        <MGInput
          {...formikField}
          type="text"
          value={value}
          placeholder={placeholder}
          onChange={onChangeHandler}
        ></MGInput>
        <MGInputButtons>
          <MGIconButton variant="primary" tabindex="-1" icon="search" onClick={onSearch}></MGIconButton>
        </MGInputButtons>
      </MGInputWrapper>
    </>
  );
};

MGSearchBox.propTypes = {
  onSearch: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  formikField: PropTypes.any,
  formikMeta: PropTypes.any,
};

MGSearchBox.defaultProps = {};

export default MGSearchBox;
