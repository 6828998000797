import { MGFlexBox, MGFlexBoxSpacer } from "@/components/layout";
import { LoadingPlaceholder } from "@/components/progress";
import { MGSpacing } from "@/components/spacing";
import { random } from "lodash";

export const LoadingHomePageClassList = () => {
  return (
    <>
      <div style={{ height: "8px" }}>&nbsp;</div>
      {[1, 2, 3, 4, 5].map((i) => (
        <div key={i} style={{ marginBottom: "4px" }}>
          <MGFlexBox>
            <MGSpacing size={"lg"} />
            <LoadingPlaceholder
              extraStyles={{
                height: "var(--mg-size-avatarSmall)",
                width: "var(--mg-size-avatarSmall)",
                borderRadius: "50%",
              }}
            />
            <MGSpacing size="md" />
            <div>
              <div>
                <LoadingPlaceholder
                  extraStyles={{
                    height: "15px",
                    top: "7px",
                    width: `${random(100, 200)}px`,
                  }}
                />
                {/* <br /> */}
              </div>
              <div>
                <LoadingPlaceholder
                  extraStyles={{
                    height: "10px",
                    top: "7px",
                    // bottom: "-10px",
                    width: `${random(100, 200)}px`,
                  }}
                />
              </div>
            </div>
            <MGFlexBoxSpacer />
            <LoadingPlaceholder
              extraStyles={{
                height: "20px",
                width: "100px",
                marginRight: "20px",
              }}
            />
          </MGFlexBox>
          <br />
        </div>
      ))}
    </>
  );
};

export default LoadingHomePageClassList;
