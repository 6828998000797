import "./style.css";

export const MGModalFooter = ({ children }) => {
  return <div className="--mg-modal-footer">{children}</div>;
};

MGModalFooter.propTypes = {};

MGModalFooter.defaultProps = {};

export default MGModalFooter;
