import { Navigate } from "react-router";
import { useLocation } from "react-router-dom";
import { isLoggedInVar } from "@/cache";

// eslint-disable-next-line no-unused-vars
export const withAuth = (WrappedComponent, options = {}) => {
  const ComponentWithAuth = () => {
    const loggedIn = isLoggedInVar();

    const location = useLocation();

    if (!loggedIn) {
      return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return <WrappedComponent />;
  };
  ComponentWithAuth.displayName = "ComponentWithAuth";
  return ComponentWithAuth;
};
// withAuth.displayName = "Yahoo";

// Duplicate just to show for now, it's just a component we can wrap directly.
export const Secure = (props) => {
  const loggedIn = isLoggedInVar();

  const location = useLocation();

  if (!loggedIn) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return props.children;
};
