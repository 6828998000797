import { MGSlider } from "./MGSlider";
import "./style.css";
import { MGHelperText } from "@/components/typography";
import PropTypes from "prop-types";
import { ErrorMessage } from "formik";
import { MGField } from "@/components/inputs";

export const MGSliderField = ({ name, disabled, maxValue, minValue, inline }) => {
  return (
    <MGField name={name} inline={inline}>
      {({ field, form }) => {
        const onChangeHandler = (evt) => {
          form.setFieldTouched(field.name, true); //This can be removed later
          form.setFieldValue(field.name, evt);
        };
        return (
          <>
            <MGSlider
              disabled={disabled}
              maxValue={maxValue}
              minValue={minValue}
              onChange={onChangeHandler}
              value={field.value}
            />
            <ErrorMessage component={MGHelperText} name={name} />
          </>
        );
      }}
    </MGField>
  );
};

MGSliderField.propTypes = {
  name: PropTypes.string,
  disabled: PropTypes.bool,
  maxValue: PropTypes.number,
  minValue: PropTypes.number,
  inline: PropTypes.bool,
};

MGSliderField.defaultProps = {};

export default MGSliderField;
