import classNames from "classnames";
import "./style.css";

export const MGCardHeader = ({ children, fixed }) => {
  return (
    <div
      className={`card__card-header ${classNames({
        "card__card-header-fixed": fixed,
        "--mg-effect-shadow-level1": fixed,
      })}`}
    >
      {children}
    </div>
  );
};

MGCardHeader.propTypes = {};

MGCardHeader.defaultProps = {};

export default MGCardHeader;
