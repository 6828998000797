import PropTypes from "prop-types";
import TransferStudentsModal from "../components/TransferStudentsModal";
import { useTransferStudentsMutation } from "./useTransferStudentsMutation";

export const TransferStudentsModalContainer = ({ classId, device_uuids, onSuccess, onFailure, onCancel }) => {
  if (!classId) return null;

  const initialValues = {
    devices: [],
  };

  const { loadingData, submitHandler } = useTransferStudentsMutation({
    classId,
    onSuccess,
    onFailure,
  });

  return (
    <>
      <TransferStudentsModal
        isShowing={classId && classId !== ""}
        loading={loadingData}
        initialValues={initialValues}
        onSubmit={submitHandler}
        onCancel={onCancel}
        devices={device_uuids}
      />
    </>
  );
};

TransferStudentsModalContainer.propTypes = {
  classId: PropTypes.string,
  device_uuids: PropTypes.any,
  onSuccess: PropTypes.func.isRequired,
  onFailure: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default TransferStudentsModalContainer;
