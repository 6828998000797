import { EmptyState } from "@/app/app-components/EmptyState/EmptyState";
import MGSurface from "@/app/app-components/MGSurface/MGSurface";
import MGAvatar from "@/components/avatar/MGAvatar";
import { MGIconButton } from "@/components/buttons";
import { MGFlexBox, MGFlexBoxSpacer } from "@/components/layout";
import { MGSpacing } from "@/components/spacing";
import { MGTooltip } from "@/components/tooltip";
import { MGHeading2, MGHeading3, MGHelperText } from "@/components/typography";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import LoadingHomePageClassList from "./LoadingHomePageClassList";
import "./style.css";

export const HomePageClassList = ({
  classDataProps,
  redirectTo,
  heading,
  emptyListMessage,
  errorMessage,
  actionButton,
}) => {
  const { data: classes, loading, error, refetch } = classDataProps;

  const { t } = useTranslation();

  return (
    <MGSurface>
      <div className="home-page-class-list" style={{ minHeight: "400px" }}>
        <MGFlexBox>
          <MGSpacing size={"lg"} />
          <MGHeading2>{heading}</MGHeading2>
          <MGFlexBoxSpacer />
          <MGTooltip text={t("Refresh")} position={"top"}>
            <MGIconButton
              variant={"primary"}
              icon={"refresh"}
              onClick={() => {
                refetch();
              }}
            />
          </MGTooltip>
          <MGSpacing size={"lg"} />
        </MGFlexBox>
        <br />
        <div className="--home-page-class-list-container non-scrollable">
          {loading && <LoadingHomePageClassList />}

          {error && <EmptyState message={errorMessage} />}

          {!loading && !error && (!classes || classes.length < 1) && <EmptyState message={emptyListMessage} />}

          {!loading &&
            !error &&
            classes &&
            classes.map((classObj) => {
              const { className, schedule, id, color } = classObj;

              // const sessionId = classObj?.sessionId;

              const toLink = redirectTo?.replace("$classId", id);
              return (
                <Link key={`${heading}_${id}`} to={toLink} className={"no-underline "}>
                  <div className={"--mg-flex-box --mg-flex-box-row --home-page-class-list-item-container hover-shadow"}>
                    <MGAvatar color={color} letter={className} isSmall={true} />
                    <MGSpacing size="sm" />
                    <div>
                      <MGHeading3>{className}</MGHeading3>

                      <MGHelperText>{schedule}</MGHelperText>
                    </div>
                    <MGFlexBoxSpacer />
                    <div>{actionButton !== null && actionButton(classObj)}</div>
                  </div>
                </Link>
              );
            })}
        </div>
      </div>
    </MGSurface>
  );
};
