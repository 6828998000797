import "./style.css";

import PropTypes from "prop-types";
import MGRadio from "./MGRadio";
import { MGField } from "@/components/inputs";

export const MGRadioField = ({ name, children, value, inline }) => {
  return (
    <MGField name={name} inline={inline}>
      {({ field, form }) => {
        const onChangeHandler = () => {
          form.setFieldTouched(field.name, true); //This can be removed later
          form.setFieldValue(field.name, value);
        };

        return (
          <>
            <MGRadio onChange={onChangeHandler} name={name} value={value} checked={field.value === value}>
              {children}
            </MGRadio>
          </>
        );
      }}
    </MGField>
  );
};

MGRadioField.propTypes = {
  name: PropTypes.string,
  children: PropTypes.any,
  value: PropTypes.any,
  inline: PropTypes.bool,
};

MGRadioField.defaultProps = {};

export default MGRadioField;
