import React from "react";
import PropTypes from "prop-types";
import "./style.css";
import classNames from "classnames";
import { MGFlexBox } from "../../layout/MGFlexBox/MGFlexBox";
import { MGCheckbox } from "../../controls/MGCheckbox/MGCheckbox";

export const MGDropdownItem = React.forwardRef((props, ref) => {
  return (
    <button
      ref={ref}
      type="button"
      tabIndex={0}
      role="dropdownOption"
      className={`dropdown-option 
             ${classNames({
               isHighlighted: props.highlighted,
             })}`}
      aria-selected={props.selected ?? false}
      data-focused={props.focused ?? false}
      style={{ minWidth: props.minWidth ? props.minWidth : 0 }}
      data-value={props.value}
      onClick={props.onClick}
    >
      <MGFlexBox align="start">
        {props.selectable && <MGCheckbox value={props.selected} />}
        <div style={{ flex: 1, overflowWrap: "anywhere" }}>{props.children}</div>
      </MGFlexBox>
    </button>
  );
});
MGDropdownItem.displayName = "MGDropdownItem";
MGDropdownItem.propTypes = {
  highlighted: PropTypes.bool,
  selected: PropTypes.bool,
  selectable: PropTypes.bool,
  focused: PropTypes.bool,
  value: PropTypes.any,
  onClick: PropTypes.any,
  children: PropTypes.any,
};

MGDropdownItem.defaultProps = {
  highlighted: false,
  selected: false,
  focused: false,
};

export default MGDropdownItem;
