import { ErrorMessage } from "formik";
import "./style.css";

import { MGTimePicker } from "./MGTimePicker";
import PropTypes from "prop-types";
import { MGHelperText } from "@/components/typography";
import { MGField } from "@/components/inputs";

export const MGTimePickerField = ({ name, onChange, disabled, inline, ...rest }) => {
  return (
    <>
      <MGField name={name} inline={inline}>
        {({ meta, field, form }) => {
          const onChangeHandler = (evt) => {
            form.setFieldValue(field.name, evt);
            if (typeof onChange === "function") onChange(evt);
          };

          return (
            <>
              <MGTimePicker
                value={field.value}
                onChange={onChangeHandler}
                disabled={disabled}
                formikField={field}
                formikMeta={meta}
                {...rest}
              ></MGTimePicker>
            </>
          );
        }}
      </MGField>
      <ErrorMessage component={MGHelperText} name={name} />
    </>
  );
};

MGTimePickerField.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  inline: PropTypes.bool,
};

MGTimePickerField.defaultProps = {};

export default MGTimePickerField;
