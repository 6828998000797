import MGSurface from "@/app/app-components/MGSurface/MGSurface";
import { useTranslation } from "react-i18next";
import "./style.css";
import { MGHeading1 } from "@/components/typography";
import { getAMPMTime } from "@/app/hooks/useDateFormattingUtils";

export const WelcomeSplash = ({ nextClass, currentUser }) => {
  const { t } = useTranslation();

  return (
    <MGSurface>
      <div className="mg-home-welcome">
        <div style={{ marginTop: "10px" }}>
          <h1 className="heading-jumbo">
            {t("Welcome back,")}
            <br />
            {currentUser?.firstname}!
          </h1>

          <br />
          <br />
          {nextClass && (
            <>
              <MGHeading1>{t("Your next class starts")} </MGHeading1>

              <MGHeading1>
                {t("at ")}
                {getAMPMTime(nextClass?.start_time)}!
              </MGHeading1>
            </>
          )}
        </div>
      </div>
    </MGSurface>
  );
};
