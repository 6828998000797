import { BehaviorSubject } from "rxjs";
import io from "socket.io-client";
import Config from "@/config";
import { logger } from "@/app/services/logging/Logger";

class ScreenShareSocket {
  NAMESPACE = "ScreenShareSocket";
  /**
   * @var {object} _options
   */
  _options = {
    transports: ["websocket"],
    rememberUpgrade: true,
    reconnectionDelay: 5000,
  };

  /**
   * @var {boolean}
   */
  _connected = false;

  SCREEN_SHARE_SEND = "screenShareSend";
  SCREEN_SHARE_RECEIVE = "screenShareReceive";
  CLASS_SUBSCRIBE = "classSubscribe";

  /**
   * @var {socket.io} socket
   */
  socket = io(Config.SCREEN_SHARE_SOCKET_HOST, this._options);

  constructor() {
    this.socket.on("connect", () => {
      logger.debug(this.NAMESPACE, "socket connected!");
      this._connected = true;
    });

    this.socket.on("connect_error", () => {
      logger.debug(this.NAMESPACE, "socket connection error!");
      this._connected = false;
    });

    /**
     * @param {any} data
     */
    this.socket.on(this.SCREEN_SHARE_RECEIVE, (socketData) => {
      var data = this.sanatizeData(socketData);
      if (data) {
        this.received?.next(data);
      }
    });
  }

  /**
   * @returns boolean
   */
  get connected() {
    return this._connected;
  }

  /**
   *
   * @param {string} classId
   */
  subscribeToClass(classId) {
    logger.debug("Subscribing to class", classId);
    this.socket.emit(this.CLASS_SUBSCRIBE, { id: classId });
  }

  /**
   * @returns any
   */
  received = new BehaviorSubject("");

  screenShareReceived() {
    return this.received.asObservable();
  }

  /**
   *
   * @param {any} data
   * @returns
   */
  sanatizeData(data) {
    if (typeof data == "string") {
      try {
        data = JSON.parse(data);
      } catch (e) {
        logger.error(e);
        return null;
      }
    }

    if (!data.payload) {
      data.payload = {};
    }

    if (typeof data.payload == "string") {
      try {
        data.payload = JSON.parse(data.payload);
      } catch (e) {
        logger.error(e);
        return null;
      }
    }

    if (data.from && data.from === this.socket.id) {
      return null;
    }

    if (!data.payload.type) {
      data.payload.type = data.payload.action;
    }

    if (data.to != "ALL_LISTENING") {
      if (data.to && data.to !== this.socket.id) {
        return null;
      }
    }
    return data;
  }
}

var screenShareSocket = new ScreenShareSocket();

export default screenShareSocket;
