import "./style.css";
import MGInputListBox from "./MGInputListBox";
import { ErrorMessage } from "formik";
import { MGHelperText } from "@/components/typography";
import PropTypes from "prop-types";
import MGField from "../BuildingBlocks/MGField";

export const MGInputListBoxField = ({
  name,
  placeholder,
  suggestions,
  allowFreeText,
  listItemValidator,
  removable,
  onChange,
  onTextChange,
  displayProp,
  suggestionRenderer,
  filter,
  displayExpr,
  inline,
}) => {
  return (
    <MGField name={name} inline={inline}>
      {({ meta, field, form }) => {
        const onChangeHandler = (evt) => {
          form.setFieldValue(field.name, evt);
          if (typeof onChange === "function") onChange(evt);
        };

        return (
          <>
            <MGInputListBox
              placeholder={placeholder}
              removable={removable}
              value={field.value}
              onChange={onChangeHandler}
              suggestions={suggestions}
              listItemValidator={listItemValidator}
              allowFreeText={allowFreeText}
              formikField={field}
              formikMeta={meta}
              displayProp={displayProp}
              suggestionRenderer={suggestionRenderer}
              filter={filter}
              displayExpr={displayExpr}
              onTextChange={onTextChange}
            ></MGInputListBox>

            <ErrorMessage component={MGHelperText} name={name} />
          </>
        );
      }}
    </MGField>
  );
};

MGInputListBoxField.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  removable: PropTypes.bool,
  onChange: PropTypes.func,
  displayProp: PropTypes.string,
  suggestionRenderer: PropTypes.func,
  filter: PropTypes.func,
  displayExpr: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  onTextChange: PropTypes.func,
  inline: PropTypes.bool,
};

MGInputListBoxField.defaultProps = {};

export default MGInputListBoxField;
