import { gql } from "@apollo/client";

export const ADD_CLASS_MUTATION = gql`
  mutation addClass($input: AddClassInput!) {
    addClass(input: $input) {
      class {
        id
      }
      errors {
        __typename
        ... on NotQuiteSureProblem {
          message
        }
        ... on ConflictProblem {
          message
        }
        ... on Problem {
          message
        }
      }
    }
  }
`;
