import { useContext } from "react";
import { MGTranslationsContext } from "./MGTranslations/MGTranslationsProvider";
import { logger } from "@/app/services/logging/Logger";

export const useMGTranslate = () => {
  const translateContext = useContext(MGTranslationsContext);

  const t = (text, ...args) => {
    //If we cannot find the context of the Translations provider, return the text as is
    //Allows usage in Storybook without translation library
    if (!translateContext) {
      logger.error(
        "Could not find context of MGTranslationsProvider, ensure app is wrapped with MGTranslationsProvider."
      );
      return text;
    }

    const { translate } = translateContext;

    if (args && !args[0]) {
      return translate(text);
    } else {
      return translate(text, args[0]);
    }
  };

  return [t];
};
