import MGAvatar from "@/components/avatar/MGAvatar";
import { MGLinkButton } from "@/components/buttons";
import { MGSpacing } from "@/components/spacing";
import { MGLabel } from "@/components/typography";
import "./style.css";

export const SidebarFooter = ({ currentUser }) => {
  return (
    <MGLinkButton variant={"text"} to={`/users/${currentUser?.id}/profile`}>
      <MGAvatar
        isSmall={true}
        // src={} add User image URL path once available
        letter={currentUser.firstname}
      />

      <MGLabel>
        <MGSpacing size={"sm"} /> {currentUser?.firstname} {currentUser?.lastname}
      </MGLabel>
    </MGLinkButton>
  );
};
