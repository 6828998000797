export const SiteLoaderComponent = () => (
  <div className="App">
    <div style={{ marginTop: "25%", textAlign: "center" }}>
      {/* <h6>Loading...</h6> */}
      <img src="/images/mg-logo-dark.svg" height={"80px"} width={"80px"} />
    </div>
  </div>
);

export default SiteLoaderComponent;
