import i18next from "i18next";
import { logger } from "@/app/services/logging/Logger";

export const convertISOStringToYearString = (date) => {
  if (typeof date === "object") {
    date = date.toISOString();
  }
  const tempDate = date.replace("-", "/").substr(0, 10);
  const formattedDate = tempDate.replace("-", "/");
  return formattedDate;
};

export const convertISOStringToMonthDay = (date) => {
  var formattedDate = new Date(date);
  var newDate = convertISOStringToYearString(formattedDate);
  var newTime = getAMPMTime(formattedDate);
  return `${newDate} ${newTime}`;
};

export const convertWebfilterDate = (date, start) => {
  var webfilterDate = new Date(date);
  webfilterDate.setHours(start ? 0 : 23);
  webfilterDate.setMinutes(start ? 0 : 59);
  webfilterDate.setSeconds(start ? 1 : 59);
  return new Date(webfilterDate.toUTCString()).toISOString();
};

export const getFullDay = (date) => {
  const dateValue = new Date(date);

  switch (dateValue.getDay()) {
    case 1:
      return i18next.t("Monday");
    case 2:
      return i18next.t("Tuesday");
    case 3:
      return i18next.t("Wednesday");
    case 4:
      return i18next.t("Thursday");
    case 5:
      return i18next.t("Friday");
    case 6:
      return i18next.t("Saturday");
    case 7:
      return i18next.t("Sunday");
    default:
      logger.error("Invalid numeric day of the week, check date passed to util function");
      return null;
  }
};

export const getFullMonth = (date) => {
  const dateValue = new Date(date);
  switch (dateValue.getMonth() + 1) {
    case 1:
      return i18next.t("January");
    case 2:
      return i18next.t("February");
    case 3:
      return i18next.t("March");
    case 4:
      return i18next.t("April");
    case 5:
      return i18next.t("May");
    case 6:
      return i18next.t("June");
    case 7:
      return i18next.t("July");
    case 8:
      return i18next.t("August");
    case 9:
      return i18next.t("September");
    case 10:
      return i18next.t("October");
    case 11:
      return i18next.t("November");
    case 12:
      return i18next.t("December");
    default:
      logger.error("Invalid numeric value for month, check date passed to util function");
      return null;
  }
};

export const deconstructDate = (date) => {
  if (!date) return null;

  let newDate = new Date(date);

  const year = newDate.getFullYear();
  const month = newDate.getMonth();
  const day = newDate.getDate();
  const hour = newDate.getHours();
  const minute = newDate.getMinutes();
  const second = newDate.getSeconds();
  const weekday = newDate.getDay();

  const gmtOffset = newDate.getTimezoneOffset();

  const UTCyear = newDate.getUTCFullYear();
  const UTCmonth = newDate.getUTCMonth();
  const UTCday = newDate.getUTCDate();
  const UTChour = newDate.getUTCHours();
  const UTCminute = newDate.getUTCMinutes();
  const UTCsecond = newDate.getUTCSeconds();
  const UTCweekday = newDate.getUTCDay();

  return {
    year,
    month,
    day,
    hour,
    minute,
    second,
    weekday,
    UTCyear,
    UTCmonth,
    UTCday,
    UTChour,
    UTCminute,
    UTCsecond,
    UTCweekday,
    gmtOffset,
  };
};

export const getTime = (date) => {
  //This is what the function was doing before, but did not cater for different date formats
  // const tempDate = date.replace("T", ", ").substring(12, 17);
  // return tempDate;

  let dateValue = new Date(date);

  return `${dateValue.getHours().toString().padStart(2, "0")}:${dateValue.getMinutes().toString().padStart(2, "0")}`;
};

export const getAMPMTime = (date) => {
  const dateValue = new Date(date);

  let hours = dateValue.getHours();
  const minutes = dateValue.getMinutes();

  if (hours === 24) {
    hours = 0;
  }
  const isPm = hours > 12 && hours;

  return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")} ${isPm ? "PM" : "AM"}`;
};

export const getTimeWithSeconds = (date) => {
  let dateValue = new Date(date);

  return `${dateValue.getHours().toString().padStart(2, "0")}:${dateValue
    .getMinutes()
    .toString()
    .padStart(2, "0")}:${dateValue.getSeconds().toString().padStart(2, "0")}`;
};

export const diffHoursAndMinutes = (start_date, end_date) => {
  const minutes = (new Date(end_date).getTime() - new Date(start_date).getTime()) / 1000 / 60;
  const remainder_minutes = Math.round(minutes % 60);
  const hours = Math.floor(minutes / 60);
  return hours > 0
    ? hours + "hr " + remainder_minutes + (remainder_minutes > 1 ? "mins" : "min")
    : remainder_minutes + (remainder_minutes > 1 ? "mins" : "min");
};

export const getDurationDisplay = (duration) => {
  const remainder_minutes = Math.round(duration % 60);
  const hours = Math.floor(duration / 60);
  return hours > 0
    ? hours + "hr " + remainder_minutes + (remainder_minutes > 1 ? "mins" : "min")
    : remainder_minutes + (remainder_minutes > 1 ? "mins" : "min");
};

export const convertToServerString = (date) => {
  return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${date
    .getDate()
    .toString()
    .padStart(2, "0")}T${date.getUTCHours().toString().padStart(2, "0")}:${date
    .getMinutes()
    .toString()
    .padStart(2, "0")}:${date.getSeconds().toString().padStart(2, "0")}+00:00`;
};
