export const useGetFilteredTeachers = (values, teachersList) => {
  //Start the filtered result with valid options available
  let filteredTeachers = teachersList;

  //ONLY if the field has options selected, proceed to filter

  filteredTeachers = [];

  filteredTeachers = teachersList.filter((x) => !values.teachers?.find((y) => y.id == x.id));

  return filteredTeachers;
};
