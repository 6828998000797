import { useCallback } from "react";
import "./style.css";
import { MGSpacing } from "@/components/spacing";
import { MGLabel } from "@/components/typography";
import { MGIconButton } from "@/components/buttons";
import { MGDropdown, MGDropdownItem, MGTextbox } from "@/components/inputs";
import PropTypes from "prop-types";
import { config } from "@/app/config";

export const MGPagination = ({ paginationInfo, rowOptions, onChange }) => {
  let {
    //totalCount,
    // count,
    perPage,
    currentPage,
    totalPages,
    hasNextPage,
    hasPreviousPage,
  } = paginationInfo;

  const firstPage = useCallback(() => {
    pageChangedHandler(1);
  });

  const lastPage = useCallback(() => {
    pageChangedHandler(totalPages);
  }, [totalPages, currentPage]);

  const previousPage = useCallback(() => {
    pageChangedHandler(currentPage - 1);
  }, [currentPage]);

  const nextPage = useCallback(() => {
    let nextPage = currentPage + 1;
    pageChangedHandler(nextPage);
  }, [currentPage]);

  const pageChangedHandler = useCallback((page) => {
    if (!isNaN(page)) {
      if (!page) {
        setCurrentPage("");
      } else {
        let pageNum = Number.parseInt(page);
        setCurrentPage(pageNum);
      }
    }
  });

  const rowsPerPageChangedHandler = (evt) => {
    let newPagination = Object.assign({}, paginationInfo);
    newPagination.perPage = evt;
    newPagination.currentPage = 1;
    newPagination.totalPages = Math.ceil(newPagination.totalCount / newPagination.perPage);

    if (typeof onChange === "function") onChange(newPagination);
  };

  const setCurrentPage = (pageNumber) => {
    let newPagination = Object.assign({}, paginationInfo);

    newPagination.currentPage = pageNumber;
    if (typeof onChange === "function") onChange(newPagination);
  };

  return (
    <div className="--mg-pagination">
      <div className="--mg-pagination-row-options">
        <div style={{ maxWidth: "100px" }}>
          <MGDropdown value={perPage} onChange={rowsPerPageChangedHandler}>
            {rowOptions?.map((option, idx) => {
              return (
                <MGDropdownItem key={idx} value={option} displayValue={option}>
                  {option}
                </MGDropdownItem>
              );
            })}
          </MGDropdown>
        </div>
        <MGSpacing size="xs"></MGSpacing>
        <MGLabel>per page</MGLabel>
      </div>
      <div className="--mg-pagination-page-selector">
        <MGIconButton disabled={!hasPreviousPage} icon="first" onClick={() => firstPage()}></MGIconButton>
        <MGIconButton disabled={!hasPreviousPage} icon="previous" onClick={() => previousPage()}></MGIconButton>
        <MGSpacing size="lg"></MGSpacing>
        <div style={{ maxWidth: "60px" }}>
          <MGTextbox value={currentPage} onChange={pageChangedHandler} clearable={false}></MGTextbox>
        </div>
        <MGSpacing size="xs"></MGSpacing>
        <div>
          <MGLabel>of {totalPages}</MGLabel>
        </div>
        <MGSpacing size="lg"></MGSpacing>
        <MGIconButton disabled={!hasNextPage} icon="next" onClick={() => nextPage()}></MGIconButton>
        <MGIconButton disabled={!hasNextPage} icon="last" onClick={() => lastPage()}></MGIconButton>
      </div>
    </div>
  );
};

MGPagination.propTypes = {
  rowOptions: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  onChange: PropTypes.func,
  paginationInfo: PropTypes.shape({
    totalCount: PropTypes.number,
    count: PropTypes.number,
    perPage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    currentPage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    totalPages: PropTypes.number,
    hasNextPage: PropTypes.bool,
    hasPreviousPage: PropTypes.bool,
  }),
};

MGPagination.defaultProps = {
  rowOptions: config.PAGINATION.ROW_OPTIONS,
  paginationInfo: {
    totalCount: 1,
    count: 10,
    perPage: 2,
    currentPage: 1,
    totalPages: 1,
    hasNextPage: false,
    hasPreviousPage: false,
  },
};

export default MGPagination;
