import "./style.css";
import PropTypes from "prop-types";

export const MGIcon = ({ icon, size, onClick, type, color }) => {
  function onClickHandler(event) {
    if (typeof onClick == "function") {
      onClick(event);
    }
  }
  const iconStyle = {
    fontSize: size + "px",
    color: color,
  };
  return <span className={`--mg-icon mg-icon-${icon} --mg-icon-${type}`} onClick={onClickHandler} style={iconStyle} />;
};
export default MGIcon;

MGIcon.propTypes = {
  icon: PropTypes.string,
  size: PropTypes.number,
  type: PropTypes.string,
  color: PropTypes.string,
};

MGIcon.defaultProps = {
  type: "small",
  icon: "error",
};
