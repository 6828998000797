import { logger } from "@/app/services/logging/Logger";
export class WebRTCCache {
  static _connections = [];

  static addConnection(connection) {
    let device_signature = connection.device.device_signature;
    logger.debug("Adding WebRTC connection to cache for:", device_signature);
    let existing = this._connections.find((conn) => conn.device.device_signature == device_signature);

    if (!existing) {
      this._connections.push(connection);
      logger.debug(device_signature, " added to cache");
    } else {
      // update the existing connection
      let index = this._connections.indexOf(existing);
      this._connections[index] = connection;
    }
  }

  /**
   *
   * @param {string} device_signature
   * @returns WebRTCConnection | undefined
   */
  static getConnection(device_signature) {
    let existing = this._connections.find((conn) => conn.device.device_signature == device_signature);
    return existing;
  }

  /**
   *
   * @param {string} device_signature
   */
  static removeConnection(device_signature) {
    logger.debug("Removing from cache:", device_signature);
    let existing = this._connections.find((conn) => conn.device.device_signature == device_signature);
    if (existing) {
      let index = this._connections.indexOf(existing);
      this._connections.splice(index, 1);
      logger.debug("Removed from cache:", device_signature);
    } else {
      logger.debug("Connection not found for:", device_signature);
    }
  }

  static removeAllConnections() {
    logger.debug("all connections: ", this._connections);
    for (const conn of this._connections) {
      logger.debug("killing connection for", conn.device.device_signature);
      conn.dispose();
    }
    // this._connections = [];
  }
}
