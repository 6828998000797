import { MGSpacing } from "@/components/spacing";
import { useMGTranslate } from "@/components/translations/useMGTranslate";
import { useEffect, useState } from "react";
import { MGNumberBox } from "../MGNumberBox";
import "./style.css";

export const MGDurationPicker = ({
  value,
  onChange,
  disabled,
  // formikField,
  formikMeta,
}) => {
  const getMinutesFromValue = (val) => {
    if (!val || isNaN(parseInt(val)) || val === "") return "";

    return parseInt(val) % 60;
  };

  const getHoursFromValue = (val) => {
    if (!val || isNaN(parseInt(val)) || val === "") return "";

    return Math.floor(parseInt(val) / 60);
  };

  const [hours, setHours] = useState(getHoursFromValue(value));
  const [minutes, setMinutes] = useState(getMinutesFromValue(value));

  const onChangeHandler = (value) => {
    let newVal = value;
    if (!newVal || newVal === 0) newVal = null;
    if (typeof onChange === "function") onChange(newVal);
  };

  const onHourChange = (value) => {
    onChangeHandler(calculateMinutes(value, minutes));
  };

  const onMinuteChange = (value) => {
    onChangeHandler(calculateMinutes(hours, value));
  };

  const calculateMinutes = (hours, minutes) => {
    return parseInt(hours * 60 + minutes);
  };

  useEffect(() => {
    setHours(getHoursFromValue(value));
    setMinutes(getMinutesFromValue(value));
  }, [value]);

  const [t] = useMGTranslate();

  return (
    <div className="--mg-duration-picker">
      <div className="--mg-duration-picker-container">
        <MGNumberBox
          placeholder={t("Hours")}
          value={hours}
          disabled={disabled}
          onChange={onHourChange}
          // formikField={formikField}                //Don't add formik field to inputs as they might try to set the value directly before min/hour calculations
          formikMeta={formikMeta}
        />
        <MGSpacing size="md" />
        <MGNumberBox
          placeholder={t("Minutes")}
          value={minutes}
          disabled={disabled}
          onChange={onMinuteChange}
          // formikField={formikField}
          formikMeta={formikMeta}
        />
      </div>
    </div>
  );
};

export default MGDurationPicker;
