import "./style.css";

import { ErrorMessage } from "formik";

import PropTypes from "prop-types";
import { MGHelperText } from "@/components/typography";
import MGAutocomplete from "./MGAutocomplete";
import MGField from "../BuildingBlocks/MGField";
import { logger } from "@/app/services/logging/Logger";

export const MGAutocompleteField = ({
  name,
  clearable,
  suggestions,
  isLoading,
  placeholder,
  actionButtonIcon,
  onActionButtonClick,
  enterKeyFiresAction,
  filter,
  suggestionRenderer,
  displayProp,
  onItemSelected,
  onChange,
  inline,
}) => {
  return (
    <MGField
      name={name}
      inline={inline}
      onFocusOut={(e) => {
        logger.log("Blur:", e);
      }}
    >
      {({ meta, field, form }) => {
        const onChangeHandler = (evt) => {
          // logger.log("Ay ", evt);
          //form.setFieldTouched(field.name);
          form.setFieldValue(field.name, evt);
          if (typeof onChange === "function") onChange(evt);
        };

        return (
          <>
            <MGAutocomplete
              suggestions={suggestions}
              isLoading={isLoading}
              placeholder={placeholder}
              value={field.value}
              clearable={clearable}
              onChange={onChangeHandler}
              actionButtonIcon={actionButtonIcon}
              onActionButtonClick={onActionButtonClick}
              enterKeyFiresAction={enterKeyFiresAction}
              filter={filter}
              suggestionRenderer={suggestionRenderer}
              displayProp={displayProp}
              onItemSelected={onItemSelected}
              formikField={field}
              formikMeta={meta}
              formikForm={form}
            ></MGAutocomplete>
            <ErrorMessage component={MGHelperText} name={name} />
          </>
        );
      }}
    </MGField>
  );
};

MGAutocompleteField.propTypes = {
  name: PropTypes.string,
  clearable: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  inline: PropTypes.bool,
};

MGAutocompleteField.defaultProps = {
  clearable: true,
};

export default MGAutocompleteField;
