import "./style.css";
import PropTypes from "prop-types";

export const MGImage = ({ src, width, height, fit = "cover", bordered, round, alt, props }) => {
  let classNames = ["--mg-image"];
  if (bordered) {
    classNames.push("--mg-image-bordered");
  }

  if (round) {
    classNames.push("--mg-image-round");
  }

  return (
    <div className={classNames.join(" ")} style={{ width: width + "px", height: height + "px" }} {...props}>
      <img src={src} style={{ objectFit: fit }} alt={alt} title="" />
    </div>
  );
};

MGImage.propTypes = {
  src: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  fit: PropTypes.string,
  bordered: PropTypes.bool,
  round: PropTypes.bool,
  alt: PropTypes.string,
  props: PropTypes.object,
};

MGImage.defaultProps = {};

export default MGImage;
