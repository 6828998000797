import i18next from "i18next";
import { useContext } from "react";
import { MGNotificationContext } from "./MGNotificationProvider";
import { logger } from "@/app/services/logging/Logger";

export const useNotification = () => {
  const { show } = useContext(MGNotificationContext);

  const notification = {
    error: ({ message, onClose, tiny }) => {
      let displayErrMsg = message;
      if (typeof message !== "string") {
        logger.error(
          "Non-string message received to display to user, generic error message being displayed instead. Error thrown:",
          message
        );
        displayErrMsg = i18next.t("An error has occurred, please try again later");
      }

      show({
        message: displayErrMsg,
        onClose,
        type: "error",
        tiny: tiny ?? false,
      });
    },
    info: ({ message, onClose, tiny }) => {
      show({
        message,
        onClose,
        type: "info",
        tiny: tiny ?? false,
      });
    },
    success: ({ message, onClose, tiny }) => {
      show({
        message,
        onClose,
        type: "success",
        tiny: tiny ?? false,
      });
    },
    warning: ({ message, onClose, tiny }) => {
      show({
        message,
        onClose,
        type: "warning",
        tiny: tiny ?? false,
      });
    },
  };

  return [notification];
};
