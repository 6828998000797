import { MGImage } from "@/components/image";
import "./style.css";

export const HackyTranslationDropdownItem = ({ icon, children }) => (
  <span className={"hackyTranslationDropdownContent"}>
    {/* <span className="htsdc__symbol">{symbol}</span> */}
    <span className="htsdc__symbol">
      <MGImage src={`/images/flags/${icon}`} height={16} width={20} bordered={true} />
    </span>
    <span className="htsdc__title">{children}</span>
  </span>
);
