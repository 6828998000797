import { createContext, useEffect, useState } from "react";
import "./style.css";
import { nanoid } from "nanoid";
import { CSSTransition } from "react-transition-group";
import MGSnackbar from "../MGSnackbar";
import PropTypes from "prop-types";

export const MGSnackbarContext = createContext(null);
/**
 * FIXME: Revisit this entire Provider to ensure good tests and good public interfaces
 */
export const MGSnackbarProvider = ({ children }) => {
  const [snacks, setSnacks] = useState([]);

  //This is the default duration a snackbar will be visible for:
  const duration = 5;

  // This is not needed in main app. Issue only exists in Storybook because when changing stories, the wrapping
  useEffect(() => {
    // cleanup for snack timeouts that complete after the component has been umounted (e.g. navigating to a different page)
    // we could perhaps track the timeout IDs but I think its enough to just clear local state
    return () => {
      // setSnacks([]);
    };
  }, []);

  const show = ({ message, actionText, actionHandler, dismissable }) => {
    const snackId = nanoid();

    setSnacks((prevSnacks) => [
      {
        id: snackId,
        message,
        actionText,
        actionHandler,
        dismissable,
        duration,
        displayed: prevSnacks.length > 0 ? false : true,
      },
      ...prevSnacks,
    ]);

    //State is a step behind, if there is only 1 snack in the array, length will be zero until rerendered
    //Apply timeout to hiding snack if there is only 1 snack in the array - if there are more, the timeout will be applied when the current active snackbar is removed (see removeSnackbarItem())
    if (snacks.length === 0 && duration) {
      setTimeout(() => {
        hide(snackId);
      }, duration * 1000);
    }
  };

  const removeSnackbarItem = (node) => {
    const id = node.getAttribute("data-id");
    setSnacks((prevSnacks) => prevSnacks.filter((snack) => snack.id !== id));

    if (snacks.length > 1) {
      setSnacks((prevSnacks) => {
        return prevSnacks.map((snack, idx) => {
          if (idx !== snacks.length - 2) return snack;

          //When the next "active" snack is found, apply a timeout to hide it after duration has elapsed
          if (snack.duration) {
            setTimeout(() => {
              hide(snack.id);
            }, snack.duration * 1000);
          }

          return {
            ...snack,
            displayed: true,
          };
        });
      });
    }
  };

  /**
   * Would there be any benefit in making this function public?
   * @param id
   */
  const hide = (id) => {
    setSnacks((prevSnacks) => {
      return prevSnacks.map((snack) => {
        if (snack.id !== id) return snack;
        return {
          ...snack,
          displayed: false,
        };
      });
    });
  };

  return (
    <MGSnackbarContext.Provider value={{ show }}>
      {children}

      {/* should we unmount the overlay when snackbars are empty? */}
      {/* can we move the styling to the style.css ? */}
      {/* also remember to cater for sidebar... and both states of sidebar */}
      <div
        className="--mg-snackbar-overlay"
        style={{
          textAlign: "center",
          position: "fixed",
          display: "flex",
          flexDirection: "column",
          width: "100%",
          bottom: "0px",
          pointerEvents: "none",
          maxHeight: "78px",
        }}
      >
        {snacks &&
          snacks.map(({ id, message, actionText, actionHandler, dismissable, displayed }) => {
            return (
              <CSSTransition
                key={id}
                classNames={"--mg-snackbar"}
                timeout={200}
                unmountOnExit={true}
                in={displayed}
                onExit={removeSnackbarItem}
              >
                <MGSnackbar
                  id={id}
                  text={message}
                  actionHandler={(id) => {
                    // always close the snackbar
                    hide(id);
                    if (typeof actionHandler === "function") {
                      actionHandler(id);
                    }
                  }}
                  actionText={actionText}
                  dismissable={dismissable}
                ></MGSnackbar>
              </CSSTransition>
            );
          })}
      </div>
    </MGSnackbarContext.Provider>
  );
};

MGSnackbarProvider.propTypes = {
  children: PropTypes.any,
};

MGSnackbarProvider.defaultProps = {};

export default MGSnackbarProvider;
