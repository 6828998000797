import { getDurations } from "@/app/utils/classes/durations";
import { currentUserVar } from "@/cache";
import { ADD_CLASS_MUTATION } from "@/app/gql/classes/add_class_mutation";
import i18next from "i18next";
import useMGMutation from "@/app/utils/gql/useMGMutation";
import { useCallback, useMemo, useState } from "react";
import useMGQuery from "@/app/utils/gql/useMGQuery";
import { GET_TEACHERS_QUERY } from "@/app/gql/users/teachers/get_teachers_query";
import { debounce } from "lodash";
import { useGetFilteredTeachers } from "../../useGetFilteredTeachers";
export const useAddClassModal = ({ isShowing, onSuccess, onFailure, onCancel }) => {
  const [onAddClass, { loading: loadingAddClass }] = useMGMutation(ADD_CLASS_MUTATION);

  const onAddClassSubmitHandler = async (values) => {
    try {
      const { data, problems } = await onAddClass({
        variables: {
          input: values,
        },
      });
      if (!problems) {
        onSuccessHandler(data);
      } else {
        throw problems[0].message;
      }
    } catch (err) {
      onFailureHandler(err);
    }
  };

  const classDurations = getDurations();

  const currentTeacher = currentUserVar();

  const [searchText, setSearchText] = useState("");

  const { data: teachersData } = useMGQuery(GET_TEACHERS_QUERY, {
    variables: {
      q: searchText,
    },
    notifyOnNetworkStatusChange: false,
  });

  const teachers = useMemo(
    () =>
      teachersData?.currentSchoolUsers?.edges
        ? teachersData?.currentSchoolUsers?.edges?.map(({ node }) => ({
            id: node.id,
            f_name: node.firstname,
            l_name: node.lastname,
            full_name: node.firstname + " " + node.lastname,
          }))
        : [],
    [teachersData]
  );

  const initialEndDate =
    classDurations.length > 0
      ? new Date(new Date().setMinutes(new Date().getMinutes() + 5)) //Default to 5 minutes later than current time if no durations available
      : new Date(new Date().setMinutes(new Date().getMinutes() + classDurations[0].value));

  const initialValues = {
    name: "",
    description: "",
    teacher_id: currentTeacher?.id,
    teacher_ids: [],
    screen_share_enabled: "ON",
    duration: classDurations.length > 0 ? classDurations[0].value : null,
    schedules: {
      start_time: new Date(),
      end_time: initialEndDate,
      weekdays: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
    },
    class_color: "",
  };

  const onSuccessHandler = (data) => {
    if (typeof onSuccess === "function") onSuccess(data);
  };

  const onFailureHandler = (data) => {
    if (typeof onFailure === "function") onFailure(data);
  };

  const onCancelHandler = (data) => {
    if (typeof onCancel === "function") onCancel(data);
  };

  //This returns the suggestions after filtering out options that are already selected (in the Formik.field values prop)
  //It is the list of possible selections prior to filtering the suggestions by the text entered in the field
  const getFilteredTeachers = (values) => {
    return useGetFilteredTeachers(values, teachers);
  };

  const search = (text) => {
    setSearchText(text);
  };
  const debounceSearch = useCallback(debounce(search, 250), [search]);

  return {
    configureClassFormProps: {
      initialValues: initialValues,
      onSubmit: onAddClassSubmitHandler,
      classDurations: classDurations,
      isShowing: isShowing,
      loading: loadingAddClass,
      onCancel: onCancelHandler,
      formHeading: i18next.t("Add Class"),
      getFilteredTeachers: getFilteredTeachers,
      debounceSearch: debounceSearch,
    },
  };
};

export default useAddClassModal;

/* End of hook. Any other required methods below: */
