import "./style.css";

export const MGHeading2 = ({ children }) => {
  return <h2>{children}</h2>;
};

MGHeading2.propTypes = {};

MGHeading2.defaultProps = {};

export default MGHeading2;
