import END_CLASS_MUTATION from "@/app/gql/classes/end_class_mutation";
import EXTEND_CLASS_MUTATION from "@/app/gql/classes/extend_class_mutation";
import useMGMutation from "@/app/utils/gql/useMGMutation";
import { classStartTeacherVar, updateActiveClass } from "@/app/store/activeClassStore";
import { useReactiveVar } from "@apollo/client";

export const useClassEnd = ({
  classId,
  classSessionId,
  onEndClassSuccess,
  onEndClassFailure,
  onExtendClassSuccess,
  onExtendClassFailure,
}) => {
  //Initialize mutations
  const [endClassMutation] = useMGMutation(END_CLASS_MUTATION);
  const [extendClassMutation] = useMGMutation(EXTEND_CLASS_MUTATION);
  const classStartTeacher = useReactiveVar(classStartTeacherVar);

  //Declare initial values for end/extend class form
  const initialValues = {
    classId: classId,
    classSessionId: classSessionId,
    endClassMethod: "end",
    duration: "",
  };

  const submitHandler = async (values, formik) => {
    formik.setSubmitting(true);
    try {
      if (values.endClassMethod !== "end") {
        await extendClassHandler(values);
        if (typeof onExtendClassSuccess === "function") onExtendClassSuccess();
      } else {
        //This needs to be called BEFORE killing the class session via mutation to ensure Nats msgs for a class are sent before the session is killed (messages will be lost in transit)
        //There is also other class cleanup that needs to happen before the session is killed (stop screenshare etc)
        await endClassHandler(values);
        if (typeof onEndClassSuccess === "function") onEndClassSuccess();
      }
    } catch (e) {
      if (values.endClassMethod !== "end") {
        if (typeof onExtendClassFailure === "function") onExtendClassFailure(e);
      } else {
        if (typeof onEndClassFailure === "function") onEndClassFailure(e);
      }
    } finally {
      formik.setSubmitting(false);
    }
  };

  const endClassHandler = async (values) => {
    let endClassMutationInput = {
      classId: values.classId,
      classSessionId: values.classSessionId,
    };

    const response = await endClassMutation({
      variables: {
        input: endClassMutationInput,
      },
    });
    if (classStartTeacher) {
      classStartTeacher.forEach((element, idx) => {
        if (element.classId === values.classId) {
          classStartTeacher.splice(idx, 1);
          updateActiveClass({ classStartTeacher: classStartTeacher });
        }
      });
    }
    if (response?.problems) {
      throw response?.problems;
    }
    return response;
  };

  const extendClassHandler = async (values) => {
    let extendClassMutationInput = {
      classId: values.classId,
      classSessionId: values.classSessionId,
      duration: values.duration,
    };

    const response = await extendClassMutation({
      variables: {
        input: extendClassMutationInput,
      },
    });

    if (response?.problems) {
      throw response?.problems;
    }

    return response;
  };

  return {
    initialValues: initialValues,
    onSubmit: submitHandler,
  };
};
