import "./style.css";
import { MGIcon } from "@/components/icons";
import { MGBadge } from "@/components/badge";
import React from "react";

export const MGIconButton = React.forwardRef(
  ({ variant, color, onClick, disabled, icon, iconSize, badge, tabindex }, ref) => {
    function onClickHandler(event) {
      if (typeof onClick == "function") {
        onClick(event);
      }
    }
    return (
      <button
        ref={ref}
        type="button"
        className={`--mg-icon-button ${"--mg-icon-button-" + variant}`}
        disabled={disabled}
        tabIndex={tabindex}
        onClick={onClickHandler}
      >
        {badge && <MGBadge value={badge} />}
        <MGIcon icon={icon} color={color} size={iconSize}></MGIcon>
      </button>
    );
  }
);

MGIconButton.displayName = "MGIconButton";

MGIconButton.propTypes = {};

MGIconButton.defaultProps = {
  tabindex: null,
  variant: "primary",
};

export default MGIconButton;
