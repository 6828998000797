import { createContext, useEffect, useState } from "react";

import "./style.css";
import { nanoid } from "nanoid";
import { CSSTransition } from "react-transition-group";
import MGBanner from "../MGBanner";

export const MGBannerContext = createContext(null);

export const MGBannerProvider = ({ children }) => {
  const [banners, setBanners] = useState([]);

  // This is not needed in main app. Issue only exists in Storybook because when changing stories, the wrapping
  useEffect(() => {
    // cleanup for banner timeouts that complete after the component has been umounted (e.g. navigating to a different page)
    // we could perhaps track the timeout IDs but I think its enough to just clear local state
    return () => {
      // setBanners([]);
    };
  }, []);

  const show = ({
    bannerClass,
    text,
    positiveText,
    negativeText,
    positiveHandler,
    negativeHandler,
    // duration = 5,
  }) => {
    const bannerId = nanoid();

    setBanners((prevBanners) => [
      {
        id: bannerId,
        bannerClass,
        text,
        positiveText,
        negativeText,
        positiveHandler,
        negativeHandler,
        // duration,
        displayed: true,
      },
      ...prevBanners,
    ]);

    // if (duration) {
    //   setTimeout(() => {
    //     hide(bannerId);
    //   }, duration * 1000);
    // }
  };

  const removeBannerItem = (node) => {
    const id = node.getAttribute("data-id");
    setBanners((prevBanners) => prevBanners.filter((banner) => banner.id !== id));
  };

  /**
   * Would there be any benefit in making this function public?
   * @param id
   */
  const hide = (id) => {
    setBanners((prevBanners) => {
      return prevBanners.map((banner) => {
        if (banner.id !== id) return banner;
        return {
          ...banner,
          displayed: false,
        };
      });
    });
  };

  return (
    <MGBannerContext.Provider value={{ show }}>
      {children}

      {/* should we unmount the overlay when snackbars are empty? */}
      {/* can we move the styling to the style.css ? */}
      {/* also remember to cater for sidebar... and both states of sidebar */}
      <div
        className="--mg-banner-overlay"
        style={{
          position: "fixed",
          display: "flex",
          flexDirection: "column",
          width: "100%",
          top: "0px",
          zIndex: 1000,
        }}
      >
        {banners &&
          banners.map(
            ({ id, bannerClass, text, positiveText, negativeText, negativeHandler, positiveHandler, displayed }) => {
              return (
                <CSSTransition
                  key={id}
                  classNames={"--mg-banner-wrapper"}
                  timeout={200}
                  unmountOnExit={true}
                  in={displayed}
                  onExit={removeBannerItem}
                >
                  <MGBanner
                    id={id}
                    negativeHandler={(id) => {
                      // always close the banner
                      hide(id);
                      if (typeof negativeHandler === "function") {
                        negativeHandler(id);
                      }
                    }}
                    positiveHandler={(id) => {
                      hide(id);
                      if (typeof positiveHandler === "function") {
                        positiveHandler(id);
                      }
                    }}
                    bannerClass={bannerClass}
                    positiveText={positiveText}
                    negativeText={negativeText}
                    text={text}
                  ></MGBanner>
                </CSSTransition>
              );
            }
          )}
      </div>
    </MGBannerContext.Provider>
  );
};

MGBannerProvider.propTypes = {};

MGBannerProvider.defaultProps = {};

export default MGBannerProvider;
