import "./style.css";
import { MGInputListItem } from ".";
import { MGHelperText } from "@/components/typography";
import PropTypes from "prop-types";

export const MGInputList = ({ value, removable, disabled, displayExpr, onItemRemoved }) => {
  const onRemoveClickHandler = (val) => {
    if (typeof onItemRemoved === "function") {
      onItemRemoved(val);
    }
  };

  return (
    <>
      <div className="list-item-container">
        {value.map((val, idx) => {
          let displayVal = val?.[displayExpr] ?? val;
          if (typeof displayExpr === "function") {
            displayVal = displayExpr(val);
          }
          return (
            <MGInputListItem
              key={idx}
              removable={removable && !disabled}
              disabled={disabled}
              onRemoveClick={() => {
                onRemoveClickHandler(val);
              }}
            >
              <MGHelperText>{displayVal}</MGHelperText>
            </MGInputListItem>
          );
        })}
      </div>
    </>
  );
};

MGInputList.propTypes = {
  value: PropTypes.array,
  removable: PropTypes.bool,
  disabled: PropTypes.bool,
  displayExpr: PropTypes.any,
  onItemRemoved: PropTypes.func,
};

MGInputList.defaultProps = {
  value: [],
  removable: true,
  disabled: false,
};

export default MGInputList;
