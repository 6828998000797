import { GET_CURRENT_USER } from "@/app/gql/users/users_queries";
import { currentUserVar, isLoggedInVar, automaticRoleChangeVar } from "@/cache";
import config from "@/config";
import { ApolloClient, NormalizedCacheObject, useReactiveVar } from "@apollo/client";
import i18next from "i18next";
import { BehaviorSubject, Observable } from "rxjs";
import { logger } from "@/app/services/logging/Logger";

const NAMESPACE = "[AuthService.js]";

export class AuthService {

    isBusyRefreshing: boolean;
    _isBusyRefreshingOnChange: BehaviorSubject<boolean>;

    apolloClient: ApolloClient<NormalizedCacheObject>;

    onRefreshFailure: any;
    userNotifiedOfLogout: boolean;
    
    
    constructor(client: ApolloClient<NormalizedCacheObject>) {
        this.isBusyRefreshing = false
        this._isBusyRefreshingOnChange = new BehaviorSubject<boolean>(false);
        this.apolloClient = client;
        this.onRefreshFailure = null;
        this.userNotifiedOfLogout = false;

        this._isBusyRefreshingOnChange.subscribe((isRefreshing)=>{
            if(isRefreshing){
                this.getNewAuthToken();
            }
        })
    }

    /**
     * Observable that notifies consumers when the auth service stops or starts refreshing auth token
     * @returns {Observable<boolean>} isBusyRefreshingOnChange observable
     */
    isBusyRefreshingOnChange(): Observable<boolean>{
        return this._isBusyRefreshingOnChange.asObservable();
    }

    async handleSuccessfulLogin(loginResponse: any){
        localStorage.setItem("cookie_expiry", `${new Date(loginResponse?.data?.expires)}`);
        await this.getCurrentUser();
        isLoggedInVar(true)
        return true;
    }

    async getCurrentUser(automaticRoleChange = false) {
        
        this.ensureMutationIsAuthenticated(async () => {
            const currentUserResponse = await this.apolloClient.query({ query: GET_CURRENT_USER });
        

            if(!currentUserResponse?.data?.currentUser)
            {
                throw {
                    queryName: "GET_CURRENT_USER",
                    message: "Login successful, but could not retrieve user after login",
                    response: currentUserResponse
                }
            }
            localStorage.setItem("currentUser", JSON.stringify(currentUserResponse?.data?.currentUser));
            currentUserVar(currentUserResponse?.data?.currentUser);

            //check if current user role is not teacher and if role reload modal is not open (automaticRoleChange)
            if (currentUserResponse?.data?.currentUser && currentUserResponse?.data?.currentUser?.role?.id !== "3" && currentUserResponse?.data?.currentUser?.role?.id !== 3 && !automaticRoleChange) {
                    window.location.href = config.ADMIN_DASHBOARD_HOST;
                }
            
           
       
        });

        
        
        
    }

    async login(username: string, password:string){
        try {
            const response = await fetch(
                `${config.API_BASE_URL}/loginuser`, 
                {
                    method:"POST", 
                    headers: {"Content-type": "application/json; charset=UTF-8"},
                    credentials: "include",
                    // credentials: "same-origin",
                    mode: "cors",
                    body:JSON.stringify(
                        {username: username, password: password}
                    ),

                }
            );
            const loginResponse = await response.json();
            if(loginResponse?.data?.success)
            {
                return await this.handleSuccessfulLogin(loginResponse);
            } else {
                logger.warn(`${NAMESPACE} - Problem with login response: `, loginResponse)
                throw loginResponse?.message ?? undefined;
            }
        }
        catch(err){
            
            if(typeof err === "string")
                return err
            else
            {
                logger.error(`${NAMESPACE} - poorly handled error on login: `, err)
                return i18next.t("There was a problem logging you in, please try again or contact support if the problem persists")
            }
        }
    }

    async nonceLogin(nonce: string){
        try{
            const response = await fetch(
                `${config.API_BASE_URL}/nonce?nonce=${nonce}`, 
                {
                    method:"GET", 
                    headers: {"Content-type": "application/json; charset=UTF-8"},
                    credentials: "include",
                    mode: "cors"
                }
            );
            const loginResponse = await response.json();
            if(loginResponse?.data?.success)
            {
                return await this.handleSuccessfulLogin(loginResponse);
            } else {
                logger.warn(`${NAMESPACE} - Problem with login response: `, loginResponse)
                throw loginResponse?.message ?? undefined;
            }
        }
        catch(err){
            
            if(typeof err === "string")
                return err
            else
            {
                logger.error(`${NAMESPACE} - poorly handled error on login: `, err)
                return i18next.t("There was a problem logging you in, please try again or contact support if the problem persists")
            }
        }
    }

    logUserOut(){
        const logoutTimer = 8;
        logger.debug(`${NAMESPACE} Logging user out in ${logoutTimer} seconds`)
        this.clearLocalstorage()
        

        const isDevEnv = (!process.env.NODE_ENV || process.env.NODE_ENV === "development");
        setTimeout(()=>{
            if(!isDevEnv)     //Only force logout if on QA or PROD, allows for troubleshooting auth
            {
                window.location.href = config.ADMIN_DASHBOARD_HOST;
            } else {
                alert("[Local DEV env]: You would have been logged out now, but to allow debugging & troubleshooting, you can stay")
            }
        }, logoutTimer * 1000);
    }

    authTokenIsExpired(onRefreshFailure?: any) {

        if(onRefreshFailure)
            this.onRefreshFailure = onRefreshFailure;

        const tokenIsExpired = this.checkAuthToken()
        if(tokenIsExpired)
            this.requestNewAuthToken();
    
       return tokenIsExpired;
    }

    private async checkIfCurrentUserVarExists(){
        //Code below ensures that there is always a current user in localstorage
        if(!this.checkAuthToken())
        {
            try{
                const stringifiedCurrentUser = localStorage.getItem("currentUser");
                if(stringifiedCurrentUser && stringifiedCurrentUser!="")
                {
                    const me:any = JSON.parse(stringifiedCurrentUser);
                    if(!me?.id)
                    {
                        this.getCurrentUser();
                    }
                }
                else {
                    this.getCurrentUser();
                }
            }
            catch(err){
                logger.error("[AuthService] Error getting current user", err)
            }
        }
    }

    async checkAndRefreshToken(onFailure?: any){
        if(onFailure)
            this.onRefreshFailure = onFailure;

        if(this.checkAuthToken())
        {
            logger.debug(`${NAMESPACE} Cookie is expired, attempting refresh`)
            await this.getNewAuthToken()
        }
        return true;
    }

    checkAuthToken() {
        if (window.navigator.onLine) {
            const cookieExpiry = localStorage.getItem("cookie_expiry");
            
            if (!cookieExpiry)
                return true;
            
            if (cookieExpiry) {


                if (new Date(cookieExpiry) < new Date(Date.now())) {
                    return true;
                }
            }
        }
        return false;
    }

    public async requestNewAuthToken() {
        if (window.navigator.onLine) {
            if (!this.isBusyRefreshing) {
                this._isBusyRefreshingOnChange.next(true);
            }
        }
    }

    public async checkIfNewAuthTokenShouldBeRequested(){
        if(this.checkAuthToken())
        {
            this.requestNewAuthToken();
        }
    }

    private async getNewAuthToken() {
        try{
            if(!this.isBusyRefreshing && !this.userNotifiedOfLogout)
            {
                this.isBusyRefreshing= true;
                logger.debug(`${NAMESPACE} Attempting cookie refresh`)

                const response = await fetch(
                    `${config.API_BASE_URL}/refresh`, 
                    {
                        method:"GET", 
                        headers: {"Content-type": "application/json; charset=UTF-8"},
                        credentials: "include",
                        mode: "cors",
                    }
                );      
                const refreshResponse = await response.json();
                if(refreshResponse?.data?.success){  
                    this.resetCookieExpiry(refreshResponse?.data?.expires);
                    // this.resetAuthTokenAndUser(token, currentUser)
                    logger.debug(`${NAMESPACE} Refresh completed successfully. New cookie expiry:`, refreshResponse?.data?.expires);
                }else {
                    throw "Failed to contact server for new cookie"
                }
            }
            
        } catch(err)
        {
            logger.error(`${NAMESPACE} - Could not refresh cookie, terminating user's session`, err)
            this.notifyUserOfSessionExpiry();
            this.logUserOut();
            
        }
    }

    private resetCookieExpiry(expiry: Date | string)
    {
        // localStorage.setItem("cookie_expiry", expiry.toString())
        localStorage.setItem("cookie_expiry", `${new Date(expiry)}`);
        this.isBusyRefreshing = false;
        this._isBusyRefreshingOnChange.next(false);
        this.checkIfCurrentUserVarExists();
    }

    async ensureMutationIsAuthenticated(onAuthenticated: any){
        let mutationHasBeenCalled = false;
        if(this.checkAuthToken()){      //Token expired

            if(!this.isBusyRefreshing){
                this.requestNewAuthToken();
            } 

            const refreshingOnChange:BehaviorSubject<boolean> = this._isBusyRefreshingOnChange;

            refreshingOnChange.subscribe((isRefreshing)=>{
                if(!isRefreshing && !mutationHasBeenCalled){
                    if(typeof onAuthenticated === "function")
                        onAuthenticated();

                    mutationHasBeenCalled = true;
                }
            });
            
        } else {
            if(typeof onAuthenticated === "function")
                onAuthenticated();
        }
    }

    private notifyUserOfSessionExpiry(){
        if(!this.userNotifiedOfLogout)
        {
            if(typeof this.onRefreshFailure === "function")
                this.onRefreshFailure();
            else 
                alert(i18next.t("Your session has timed out, you will be logged out shortly"));
            this.userNotifiedOfLogout = true;
        }
    }

    private clearLocalstorage() {

        isLoggedInVar(false);

        localStorage.removeItem("classId");
        localStorage.removeItem("classSessionId");
        localStorage.removeItem("classForceJoin");
        localStorage.removeItem("cookie_expiry");
        localStorage.removeItem("token");
        localStorage.removeItem("currentUser");
        localStorage.removeItem("classStartTeacher");
    }
}