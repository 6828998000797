import "./style.css";
import MGInputWrapper from "../BuildingBlocks/MGInputWrapper";
import MGInput from "../BuildingBlocks/MGInput";
import { MGIconButton } from "@/components/buttons";
import MGInputButtons from "../BuildingBlocks/MGInputButtons";
import { MGIcon } from "@/components/icons";
import { MGProgressSpinner } from "@/components/progress";

export const MGActionTextbox = ({
  icon,
  value,
  actionIcon,
  onAction,
  onChange,
  isLoading,
  clearable,
  placeholder,
  enterKeyFiresAction,
  disabled,
  formikField,
  formikMeta,
}) => {
  function onChangeHandler(evt) {
    let newValue = evt === "" ? "" : evt.target.value;
    if (typeof onChange == "function") {
      onChange(newValue);
    }
  }

  const onClear = () => {
    onChangeHandler("");
  };

  return (
    <>
      <MGInputWrapper {...{ ...formikMeta }} disabled={disabled}>
        {icon && <MGIcon icon={icon}></MGIcon>}
        <MGInput
          {...formikField}
          type="text"
          value={value}
          disabled={disabled}
          onChange={onChangeHandler}
          placeholder={placeholder}
          onKeyDown={(evt) => {
            if (evt.key === "Enter" && enterKeyFiresAction && typeof onAction === "function") {
              onAction();
              evt.preventDefault();
            }
          }}
        ></MGInput>
        <MGInputButtons>
          {isLoading && <MGProgressSpinner size="16" />}
          {value && clearable && (
            <MGIconButton
              variant="primary"
              tabindex="-1"
              icon="close"
              disabled={disabled}
              onClick={onClear}
            ></MGIconButton>
          )}
          {actionIcon && (
            <MGIconButton
              variant="primary"
              tabindex={"-1"}
              icon={actionIcon}
              disabled={disabled}
              onClick={onAction}
            ></MGIconButton>
          )}
        </MGInputButtons>
      </MGInputWrapper>
    </>
  );
};

MGActionTextbox.propTypes = {};

MGActionTextbox.defaultProps = {
  value: "",
  clearable: true,
  enterKeyFiresAction: false,
};

export default MGActionTextbox;
