import "./style.css";

export const MGModalHeader = ({ children }) => {
  return <div className="--mg-modal-header">{children}</div>;
};

MGModalHeader.propTypes = {};

MGModalHeader.defaultProps = {};

export default MGModalHeader;
