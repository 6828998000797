import { logger } from "@/app/services/logging/Logger";
export const findGQLProblem = (data) => {
  if (!data) return null;

  try {
    let problemsArray = [];

    Object.keys(data).map((childOfData) => {
      if (data[childOfData].__typename.toString().toLowerCase().includes("problem")) {
        let problem = {
          __typename: data[childOfData].__typename,
          message: data[childOfData].message,
        };
        problemsArray.push(problem);
      }

      if (data[childOfData].errors) {
        data[childOfData].errors.map((err) => {
          problemsArray.push(err);
        });
      }
    });

    if (problemsArray.length > 0) {
      logger.warn("Problems detected from GQL response: ", problemsArray);
      return problemsArray;
    } else {
      return null;
    }
  } catch (e) {
    logger.error("Error while finding GQL 'problem', exception caught: ", e);
    return null;
  }
};

export default findGQLProblem;
