import "./style.css";
import classNames from "classnames";
import { useRef } from "react";
import { useEffect } from "react";
import PropTypes from "prop-types";

export const MGCheckbox = ({ value, children, onChange, disabled, indeterminate, formikField }) => {
  const checkboxRef = useRef();
  useEffect(() => {
    checkboxRef.current.indeterminate = indeterminate;
    checkboxRef.current.checked = value;
  });

  function onChangeHandler(event) {
    if (typeof onChange == "function") {
      onChange(event);
    }
  }

  return (
    <label className={`--mg-checkbox ${classNames({ disabled: disabled })}`}>
      {children}
      <input {...formikField} disabled={disabled} ref={checkboxRef} type="checkbox" onChange={onChangeHandler} />
      <span
        className={`--mg-checkbox-checkmark ${classNames({
          disabled: disabled,
          indeterminate: indeterminate,
        })}`}
      ></span>
    </label>
  );
};

MGCheckbox.propTypes = {
  value: PropTypes.bool,
  children: PropTypes.any,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  indeterminate: PropTypes.bool,
  formikField: PropTypes.any,
};

MGCheckbox.defaultProps = {
  disable: false,
  indeterminate: false,
};

export default MGCheckbox;
