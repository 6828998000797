import UserUtils from "@/app/utils/users/user_utils";
import { BehaviorSubject } from "rxjs";
import io from "socket.io-client";
import Config from "@/config";
import { automaticRoleChangeVar } from "@/cache";
import { logger } from "@/app/services/logging/Logger";

class SocketService {
  NAMESPACE = "SocketService";
  options = {
    transports: ["websocket"],
    rememberUpgrade: true,
    reconnectionDelay: 5000,
  };
  schoolUser = UserUtils.getCurrentSchoolUser();
  currentUserId = UserUtils.getCurrentUserId();
  currentUser;

  API_BASE_URL = Config.API_BASE_URL;
  // getUserToken() {
  //   return localStorage.getItem("token") ?? null;
  // }

  roleNames = {
    1: "admin",
    3: "teacher",
    4: "parent",
  };

  getMimeType(format) {
    switch (format.toLowerCase()) {
      case "csv":
        return "text/csv";
      case "pdf":
        return "application/pdf";
      case "excel":
        return "application/vnd.ms-excel";
      case "word":
        return "application/msword";
      default:
        return "text/csv";
    }
  }

  getExtension(format) {
    switch (format.toLowerCase()) {
      case "pdf":
        return ".pdf";
      case "word":
        return ".docx";
      case "csv":
        return ".csv";
      case "excel":
        return ".xlsx";
      default:
        return ".csv";
    }
  }

  /**
   * @var {socket.io-client} socket
   */
  socket = io(Config.SOCKET_HOST, this.options);
  // private socket = io('https://us-api.stg.mobileguardian.com/mg-stg');

  constructor() {
    this.socket.on("connect", () => {
      logger.debug(this.NAMESPACE, "socket connected!");
    });

    this.socket.on(`reports.student.${this.schoolUser?.school_id}`, async (socketData) => {
      logger.debug(
        this.NAMESPACE,
        `Socket data received on channel: reports.student.${this.schoolUser?.school_id}`,
        socketData
      );
    });

    this.socket.on("connect_error", (err) => {
      logger.debug(this.NAMESPACE, "socket error", err);
    });

    this.socket.on(`user.${this.currentUserId}`, (socketData) => {
      this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
      this.reloadCheck(socketData);
    });
  }

  /**
   * @var {BehaviourSubjecy(any)} webRTC
   */
  webRTC = new BehaviorSubject("");

  /**
   *
   * @param {string} device_uuid
   * @returns {BehaviorSubject(any)}
   */
  getWebRTC(device_uuid) {
    /**
     * @param {any} data
     */
    this.socket.on("device." + device_uuid + ".webrtc", (data) => {
      this.webRTC.next(data);
    });
    return this.webRTC.asObservable();
  }

  /**
   * @var {BehaviorSubject(any)} classUpdatedSubject
   */
  classUpdatedSubject = new BehaviorSubject("");

  /**
   * @var {BehaviorSubject(any)} syncStatusSubject
   */
  syncStatusSubject = new BehaviorSubject("");

  /**
   * @var {BehaviorSubject(any)} deviceUpdatedSubject
   */
  deviceUpdatedSubject = new BehaviorSubject("");

  /**
   *
   * @returns BehaviourSubject(any)
   */
  deviceUpdated() {
    this.socket.on(`device.${this.schoolUser?.school_id}.status-updated`, (data) => {
      this.deviceUpdatedSubject.next(this.sanatizeData(data));
    });
    return this.deviceUpdatedSubject.asObservable();
  }

  /**
   *
   * @param {string} classId
   * @returns BehaviourSubject(any)
   */
  classUpdated(classId) {
    /**
     * @param {any} data
     */
    this.socket.on(`class.${classId}.updated`, (data) => {
      this.classUpdatedSubject.next(this.sanatizeData(data));
    });
    return this.classUpdatedSubject.asObservable();
  }

  /**
   *
   * @returns BehaviourSubject(any)
   */
  syncGoogleClasses() {
    /**
     * @param {any} data
     */
    this.socket.on(`sync-status.${this.schoolUser?.school_id}.teacher.${this.currentUserId}`, (data) => {
      this.syncStatusSubject.next(this.sanatizeData(data));
    });
    return this.syncStatusSubject.asObservable();
  }

  /**
   *
   * @param {string} type
   * @returns BehaviourSubject(any)
   */
  downloadExport(type, format, onSuccess, onFailure) {
    /**
     * @param {any} data
     */
    this.socket.on(`reports.${type}.${this.schoolUser?.school_id}`, async (socketData) => {
      socketData = JSON.parse(socketData);
      if (socketData.status === "ERROR") {
        if (typeof onFailure === "function") onFailure();
      } else {
        const params = "base64=0";

        const download = await fetch(`${this.API_BASE_URL}/apireports/download/${socketData.payload}?${params}`, {
          method: "GET",
          credentials: "include",
          mode: "cors",
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
          },
        });

        if (typeof onSuccess === "function") onSuccess();

        const file = await download.blob();

        await this.createDownloadLinkAndClick(file, `${type}_Export${this.getExtension(format)}`);
        this.socket.off(`reports.${type}.${this.schoolUser.school_id}`);
      }
    });
  }

  reloadCheck(data) {
    try {
      data = JSON.parse(data);
    } catch (e) {
      logger.error(e);
    }

    switch (data.entity) {
      case "auth":
        if (this.schoolUser && data.school_id != this.schoolUser.school_id) {
          automaticRoleChangeVar(true);
          return;
        }
        break;
      case "user":
        if (data.school_id && this.schoolUser && data.school_id != this.schoolUser.school_id) {
          automaticRoleChangeVar(true);
          return;
        }
        if (data.role_id && !UserUtils.isInRole(this.currentUser, this.roleNames[data.role_id + ""])) {
          automaticRoleChangeVar(true);
          return;
        }
        break;
    }
  }

  /**
   *
   * @param {blob} downloadFile
   * @param {string}  fileName
   * @returns Event
   */
  createDownloadLinkAndClick(downloadFile, fileName) {
    //Function creates & appends a hyperlink that is programatically clicked to begin a download
    var a = document.createElement("a");
    a.style = "display: none";
    document.body.appendChild(a);

    var blob = new Blob([downloadFile], { type: "octet/stream" }),
      url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  /**
   *
   * @param {any} data
   * @returns
   */
  sanatizeData(data) {
    if (typeof data == "string") {
      try {
        data = JSON.parse(data);
      } catch (e) {
        logger.error(e);
      }
    }

    if (!data.payload) {
      data.payload = {};
    }

    if (typeof data.payload == "string") {
      try {
        data.payload = JSON.parse(data.payload);
      } catch (e) {
        logger.error(e);
      }
    }

    if (data.from && data.from === this.socket.id) {
      return;
    }

    if (data.to && data.to !== this.socket.id) {
      return;
    }

    if (!data.payload.type) {
      data.payload.type = data.payload.action;
    }
    return data;
  }
}

export default new SocketService();
