import "../mg-button-style.css";
import "./style.css";
import { MGIcon } from "@/components/icons";
import { MGProgressSpinner } from "@/components/progress";

import PropTypes from "prop-types";
import { MGSpacing } from "@/components/spacing";

export const MGButton = ({ iconLeft, iconRight, children, disabled, onClick, isLoading, type, variant }) => {
  function onClickHandler(event) {
    if (typeof onClick == "function") {
      onClick(event);
    }
  }

  return (
    <button
      className={`--mg-button ${variant ? "--mg-button-" + variant : ""}`}
      disabled={disabled}
      onClick={onClickHandler}
      type={type}
    >
      {iconLeft && (
        <span className="--mg-button-icon-left">
          <MGIcon icon={iconLeft} />
        </span>
      )}
      {children}
      {iconRight && (
        <span className="--mg-button-icon-right">
          <MGIcon icon={iconRight} />
        </span>
      )}
      {isLoading && (
        <>
          <MGSpacing size="xs" />
          <MGProgressSpinner size={24} />
        </>
      )}
    </button>
  );
};

MGButton.propTypes = {
  type: PropTypes.string,
};

MGButton.defaultProps = {
  variant: "primary",
  type: "button",
};

export default MGButton;
