import { getDurations } from "@/app/utils/classes/durations";

import { UPDATE_CLASS_MUTATION } from "@/app/gql/classes/update_class_mutation";
import { VIEW_CLASS } from "@/app/gql/classes/classes_queries";
import i18next from "i18next";
import useMGMutation from "@/app/utils/gql/useMGMutation";
import useMGQuery from "@/app/utils/gql/useMGQuery";
import { useCallback, useMemo, useState } from "react";
import { debounce } from "lodash";
import { GET_TEACHERS_QUERY } from "@/app/gql/users/teachers/get_teachers_query";
import { useGetFilteredTeachers } from "../../useGetFilteredTeachers";

export const useEditClassModal = ({ classId, onSuccess, onFailure, onCancel }) => {
  const { data: viewClass, loading: loadingViewClass } = useMGQuery(VIEW_CLASS, {
    variables: {
      classId: classId,
    },
    notifyOnNetworkStatusChange: true,
  });

  const [onUpdateClass, { loading: loadingUpdateClass }] = useMGMutation(UPDATE_CLASS_MUTATION);

  const [searchText, setSearchText] = useState("");

  const classDurations = getDurations();

  const { data: teachersData } = useMGQuery(GET_TEACHERS_QUERY, {
    variables: {
      q: searchText,
    },
    notifyOnNetworkStatusChange: false,
  });

  const initialTeachers = useMemo(
    () =>
      viewClass?.class?.teachers
        ? viewClass?.class?.teachers?.map((node) => ({
            id: node?.id,
            f_name: node?.firstname,
            l_name: node?.lastname,
            full_name: node?.firstname + " " + node?.lastname,
          }))
        : [],
    [loadingViewClass]
  );

  const teachers = useMemo(
    () =>
      teachersData?.currentSchoolUsers?.edges
        ? teachersData?.currentSchoolUsers?.edges?.map(({ node }) => ({
            id: node.id,
            f_name: node.firstname,
            l_name: node.lastname,
            full_name: node.firstname + " " + node.lastname,
          }))
        : [],
    [teachersData]
  );

  const onUpdateClassSubmitHandler = async (values) => {
    try {
      const { data, problems } = await onUpdateClass({
        variables: {
          input: values,
        },
      });
      if (!problems) {
        onSuccessHandler(data);
      } else {
        throw problems[0].message;
      }
    } catch (err) {
      onFailureHandler(err);
    }
  };

  const initialValues = {
    classId: viewClass?.class?.id,
    name: viewClass?.class?.name,
    description: viewClass?.class?.description,
    teacher_id: viewClass?.class?.teacher?.id,
    teacher_ids: viewClass?.class?.teachers?.map((teacher) => {
      return teacher.id;
    }),
    teachers: initialTeachers,
    duration: viewClass?.class?.duration,
    screen_share_enabled: viewClass?.class.screen_share_enabled,
    schedules: {
      start_time: viewClass?.class?.schedules ? new Date(viewClass?.class?.schedules[0].start_time) : new Date(),
      end_time: viewClass?.class?.schedules ? new Date(viewClass?.class?.schedules[0].end_time) : new Date(),
      weekdays: viewClass?.class?.schedules ? viewClass?.class?.schedules[0].weekdays : [],
    },
    class_color: viewClass?.class?.meta_data?.color,
  };

  const onSuccessHandler = (data) => {
    if (typeof onSuccess === "function") onSuccess(data);
  };

  const onFailureHandler = (data) => {
    if (typeof onFailure === "function") onFailure(data);
  };

  const onCancelHandler = (data) => {
    if (typeof onCancel === "function") onCancel(data);
  };

  //This returns the suggestions after filtering out options that are already selected (in the Formik.field values prop)
  //It is the list of possible selections prior to filtering the suggestions by the text entered in the field
  const getFilteredTeachers = (values) => {
    return useGetFilteredTeachers(values, teachers);
  };

  const search = (text) => {
    setSearchText(text);
  };
  const debounceSearch = useCallback(debounce(search, 250), [search]);

  return {
    configureClassFormProps: {
      initialValues: initialValues,
      onSubmit: onUpdateClassSubmitHandler,
      classDurations: classDurations,
      isShowing: classId,
      loading: loadingUpdateClass,
      loadingViewClass: loadingViewClass,
      onCancel: onCancelHandler,
      formHeading: i18next.t("Edit class"),
      getFilteredTeachers: getFilteredTeachers,
      debounceSearch: debounceSearch,
    },
  };
};

export default useEditClassModal;

/* End of hook. Any other required methods below: */
