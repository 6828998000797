import { MGButton } from "@/components/buttons";
import { MGModalBody, MGModalFooter, MGModalHeader } from "@/components/modals";
import { MGHeading3 } from "@/components/typography";
import PropTypes from "prop-types";
function MGConfirmationModal({
  title,
  message,
  positive,
  positiveIsLoading,
  negative,
  negativeIsLoading,
  onClose,
  onSuccess,
}) {
  const onSuccessHandler = () => {
    if (typeof onSuccess == "function") {
      onSuccess();
    }
  };

  const onCloseHandler = () => {
    if (typeof onClose == "function") {
      onClose();
    }
  };
  return (
    <>
      <MGModalHeader>
        <MGHeading3>{title}</MGHeading3>
      </MGModalHeader>
      <MGModalBody>{message}</MGModalBody>
      <MGModalFooter>
        <MGButton
          variant="tertiary"
          isLoading={negativeIsLoading}
          disabled={negativeIsLoading}
          onClick={onCloseHandler}
        >
          {negative}
        </MGButton>
        <MGButton
          variant="primary"
          isLoading={positiveIsLoading}
          disabled={positiveIsLoading}
          onClick={onSuccessHandler}
        >
          {positive}
        </MGButton>
      </MGModalFooter>
    </>
  );
}

MGConfirmationModal.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  positive: PropTypes.string,
  positiveIsLoading: PropTypes.bool,
  negative: PropTypes.string,
  negativeIsLoading: PropTypes.bool,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
};

export default MGConfirmationModal;
