import "./style.css";

export const MGInputButtons = ({ children }) => {
  return <span className="input-button-section">{children}</span>;
};

MGInputButtons.propTypes = {};

MGInputButtons.defaultProps = {};

export default MGInputButtons;
