import { createContext, useEffect, useState } from "react";
import "./style.css";
import { logger } from "@/app/services/logging/Logger";
import MGModal from "../MGModal";

export const MGModalContext = createContext(null);

export const MGModalProvider = ({ children }) => {
  const [modal, setModal] = useState({});

  // This is not needed in main app. Issue only exists in Storybook because when changing stories, the wrapping
  useEffect(() => {
    return () => {
      // setModal([]);
    };
  }, []);

  const show = ({ component, data }) => {
    // const modalId = nanoid();
    logger.log("show");
    setModal({
      // id: modalId,
      component,
      data,
      displayed: true,
    });
  };

  //   const removeModal = () => {

  //     setBanner({});
  //   };

  //    const hide = (id) => {
  //     setModal({...modal, displayed: false})
  //   };

  return (
    <MGModalContext.Provider value={{ show }}>
      {children}
      <div
        className="--mg-modal-overlay"
        // style={{
        //     position: "fixed",
        //     display: "flex",
        //     flexDirection: "column",
        //     width: "100%",
        //     height: "100vh",

        // }}
      >
        {/* {modal && (
          <MGModal open={modal.displayed}>
            {modal.component}
          </MGModal>
        )} */}

        {modal && (
          <MGModal isShowing={modal.displayed} component={modal.component}>
            {/* {modal.component} */}
          </MGModal>
        )}
      </div>
    </MGModalContext.Provider>
  );
};

MGModalProvider.propTypes = {};

MGModalProvider.defaultProps = {};

export default MGModalProvider;
