import { createContext, useEffect } from "react";
import PropTypes from "prop-types";
import { logger } from "@/app/services/logging/Logger";

export const MGTranslationsContext = createContext(null);
/**
 * FIXME: Revisit this entire Provider to ensure good tests and good public interfaces
 */
export const MGTranslationsProvider = ({ children, translateFunction }) => {
  useEffect(() => {
    return () => {
      // setSnacks([]);
    };
  }, []);

  function translate(text, ...args) {
    try {
      if (!text) return text;

      if (!translateFunction) throw "No Translate function";

      if (args) return translateFunction(text, ...args);
      else return translateFunction(text);
    } catch (e) {
      if (!translateFunction) {
        logger.warn("MGTranslationsProvider: No 'translateFunction' available, unable to perform translation");
        return text;
      }

      logger.warn(
        "MGTranslationsProvider: Could not translate using the provided translateFunction - double check implementation"
      );
      return text;
    }
  }

  return <MGTranslationsContext.Provider value={{ translate }}>{children}</MGTranslationsContext.Provider>;
};

MGTranslationsProvider.propTypes = {
  children: PropTypes.any,
};

MGTranslationsProvider.defaultProps = {};

export default MGTranslationsProvider;
