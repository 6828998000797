import { gql } from "@apollo/client";

export const MUTATION_START_CLASS = gql`
  mutation startClass($input: StartClassInput!) {
    startClass(input: $input) {
      __typename
      ... on StartClassPayload {
        startClassData {
          classSessionId
          pin
          created_at
          updated_at
          active_session {
            has_ended
            id
          }
          class {
            force_join
            screen_share_enabled
          }
          already_in_session_devices {
            id
            f_name
            l_name
            devices {
              device_uuid
              device_signature
              device_name
              device_name_actual
            }
          }
        }
        errors {
          __typename
          ... on NotQuiteSureProblem {
            message
          }
          ... on Problem {
            message
          }
        }
      }
    }
  }
`;

export default MUTATION_START_CLASS;
