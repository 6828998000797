import PropTypes from "prop-types";
import "./style.css";

export const MGMenuItem = ({ children, onClick }) => {
  const onClickHandler = (evt) => {
    if (typeof onClick === "function") {
      onClick(evt);
    }
  };

  return (
    <div className={`--mg-menu-item`} onClick={onClickHandler}>
      {children}
    </div>
  );
};

MGMenuItem.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.any,
};

MGMenuItem.defaultProps = {};

export default MGMenuItem;
