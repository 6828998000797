import { gql } from "@apollo/client";

export const PageInfoFragments = {
  allParts: gql`
    fragment PageInfoAllParts on PageInfo {
      count
      currentPage
      hasNextPage
      hasPreviousPage
      perPage
      totalCount
      totalPages
    }
  `,
};
