import { useNotification } from "@/components/notification/MGNotificationProvider/useNotification";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { authService } from "@/main";
import SiteLoaderComponent from "@/app/app-components/SiteLoaderComponent/SiteLoaderComponent";
import { logger } from "@/app/services/logging/Logger";

export const NonceLogin = ({ nonce }) => {
  const navigate = useNavigate();

  const [notification] = useNotification();

  const { t } = useTranslation();

  let from = location.state?.from?.pathname || "/";

  const [loginFailed, setLoginFailed] = useState(false);

  const performNonceLogin = async (nonce) => {
    const nonceLoginResult = await authService.nonceLogin(nonce);

    if (nonceLoginResult === true) {
      navigate(from, { replace: true });
    } else {
      notification.error({
        message: t("There was a problem authenticating you from the admin dashboard, please attempt login again"),
      });
      setLoginFailed(true);
      authService.logUserOut();
      logger.error("Problems encountered with nonce login", nonceLoginResult);
    }
  };

  useEffect(() => {
    performNonceLogin(nonce);
  }, [nonce]);

  if (loginFailed) return <SiteLoaderComponent />;
  else return null;
};
