import { MGDropdownField, MGDropdownItem } from "@/components/inputs";
import { MGSpacing } from "@/components/spacing";
import { MGHelperText } from "@/components/typography";
import { useTranslation } from "react-i18next";

export const ScreenshareModePicker = ({ name, placeholder, onChange, loading, disabled }) => {
  const { t } = useTranslation();

  const onChangeHandlder = (value) => {
    if (typeof onChange === "function") onChange(value);
  };

  return (
    <MGDropdownField
      name={name}
      placeholder={placeholder}
      onChange={onChangeHandlder}
      loading={loading}
      disabled={disabled}
    >
      <MGDropdownItem value="ON">
        {t("On")} <MGSpacing size={"xxs"} />
        <MGHelperText>{t("(Chromebook and Android only)")}</MGHelperText>
      </MGDropdownItem>
      <MGDropdownItem value="OFF">
        {t("Off")} <MGSpacing size={"xxs"} />
        <MGHelperText>{t("(All)")}</MGHelperText>
      </MGDropdownItem>
      <MGDropdownItem value="ACTIVETAB">
        {t("Active Tab")} <MGSpacing size={"xxs"} />
        <MGHelperText>{t("(Android only)")}</MGHelperText>
      </MGDropdownItem>
    </MGDropdownField>
  );
};
