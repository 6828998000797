import { RECENTLY_ENDED_CLASSES_QUERY } from "@/app/gql/classes/classes_queries";
import { deconstructDate, getAMPMTime, getFullDay, getFullMonth } from "@/app/hooks/useDateFormattingUtils";
import useMGQuery from "@/app/utils/gql/useMGQuery";
import i18next from "i18next";

export const useRecentlyEndedClasses = () => {
  const { loading, error, data, refetch } = useMGQuery(RECENTLY_ENDED_CLASSES_QUERY, {
    variables: {
      page: 1,
      limit: 5,
      filter: {
        in_progress: false,
      },
      sortBy: [
        {
          field: "last_session_ended",
          direction: "DESC",
        },
      ],
    },
  });

  return {
    data: data?.classes?.edges?.map(mapRecentlyEndedClassData),
    loading,
    error,
    refetch,
  };
};

const mapRecentlyEndedClassData = ({ node }) => {
  const deconstructedDate = node?.last_session?.session_end_time
    ? deconstructDate(node?.last_session?.session_end_time)
    : null;

  return {
    id: node.id, //<--Replace this
    className: node.name,
    sessionId: node?.last_session?.id,
    schedule: node?.last_session?.session_end_time
      ? // ? `Ended: ${getFullDay(node.last_session.session_end_time)} ${getAMPMTime(node.last_session.session_end_time )}`
        i18next.t("Ended: {{%s}}", {
          "%s": `${getFullDay(node.last_session.session_end_time)}, ${deconstructedDate?.day} ${getFullMonth(
            node.last_session.session_end_time
          )} ${getAMPMTime(node.last_session.session_end_time)}`,
        })
      : null,
    color: node?.meta_data?.color,
  };
};

export default useRecentlyEndedClasses;
