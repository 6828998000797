import { gql } from "@apollo/client";

export const MUTATION_SWITCH_ROLE = gql`
  mutation switchCurrentSchoolUserToAdmin($input: SwitchSchoolUserInput!) {
    switchCurrentSchoolUser(input: $input) {
      currentUser {
        id
        firstname
        lastname
        username
        email
        phone_number
        timezone
        timezone_offset
        confirmation_code
        marketing_subscribe
        created_at
        telegram_chat_id
        slack_webhook_url
        registration_code_formatted
        device_cap
        asm_synced
        google_synced
        ad_synced
        account_status
        school_disabled
        google_authenticator_active
        role {
          id
          name
          permissions {
            name
            granted
          }
        }
        roles {
          id
          name
          permissions {
            name
            granted
          }
        }
        school_users {
          school_id
          school {
            id
            name
            allow_screen_share_configuration
            school_type {
              id
              name
            }
          }
          is_current
          role_id
        }
        pretend {
          id
          client_id
          owner_type
          owner_id
          created_at
          updated_at
          admin_user_id
          admin_username
          admin_firstname
          admin_lastname
        }
      }
      currentUserId
      errors {
        __typename

        # handle specific problem
        ... on NotQuiteSureProblem {
          message
        }

        # interface handles generic problem
        ... on Problem {
          message
        }
      }
    }
  }
`;
