import i18next from "i18next";

class DeviceUtils {
  //Use 18inext translations in a class by defining it once for reuse
  // static t = (str) =>{
  //   const {t} = useTranslation();

  //   return t(str);
  // };

  static usesNatsMessaging = (device) => {
    return (
      (device.messaging_enabled && this.isChrome(device)) ||
      (device.messaging_enabled && this.isAndroid(device)) ||
      (device.messaging_enabled && this.isIOS(device)) ||
      device.is_chrome_managed
    );
  };

  static isChromeManaged = (device) => {
    return (
      device.computed?.isChromeManaged ||
      device.is_chrome_managed === 1 ||
      device.is_chrome_managed === "1" ||
      device.is_chrome_managed == true
    );
  };

  static isInEyesUp = (device) => {
    return device?.class_device_status?.isHeadsUp ?? false;
  };

  static isAndroid = (device) => {
    return device.computed?.isAndroid || (!this.isChromeManaged(device) && device.os_type_id === "2");
  };

  static isIOS = (device) => {
    return device.computed?.isIOS || (!this.isChromeManaged(device) && device.os_type_id === "4");
  };

  static isChrome = (device) => {
    return device.computed?.isChrome || this.isChromeManaged(device) || device.os_type_id === "5";
  };

  static isOSX = (device) => {
    return device.computed?.isOSX || (!this.isChromeManaged(device) && device.os_type_id == "6");
  };

  static isWindows = (device) => {
    return device.computed?.isWindows || (!this.isChromeManaged(device) && device.os_type_id == "7");
  };

  static isEnrolled = (device) => {
    return device.computed?.isEnrolled || device.is_enrolled === "1" || device.is_enrolled === 1;
  };

  static isMDM = (device) => {
    return device.computed?.isMDM || device.os_type_id === "6" || device.os_type_id === "4";
  };

  static canPerformAction = (device) => {
    return !!(device.push_token && (device.has_mg_app == "1" || device.has_mg_app));
  };

  static isLostMode = (device) => {
    return device.computed?.isLostMode || device.is_lostmode === "1" || device.is_lostmode === 1;
  };

  static isSchoolOwned = (device) => {
    if (device.computed?.isSchoolOwned) return true;
    if (!device.device_configuration) return false;
    return (
      device.device_configuration.is_school_owned_device === 1 ||
      device.device_configuration.is_school_owned_device === "1" ||
      device.device_configuration.is_school_owned_device === true
    );
  };

  static isProvisioned = (device) => {
    return (
      device.device_configuration.is_provisioned === 1 ||
      device.device_configuration.is_provisioned === "1" ||
      device.device_configuration.is_provisioned === true
    );
  };

  static pushNotificationsRegistered = (device) => {
    return device.push_token && device.push_token !== "";
  };

  static hasMGWebfilter = (device) => {
    return device.has_mg_webfilter === 1 || device.has_mg_webfilter === "1";
  };

  static hasMGApp = (device) => {
    return device.has_mg_app === 1 || device.has_mg_app === "1" || device.has_mg_app === true;
  };

  static isSupervised = (device) => {
    return device.computed?.isSupervised || device.is_supervised === 1 || device.is_supervised === "1";
  };

  static getGigabytes(bytes) {
    if (bytes && !isNaN(bytes)) {
      var gigabytes = bytes / (1000 * 1000 * 1000);
      return gigabytes.toFixed(1);
    }
    return 0;
  }

  static getHardwareEncryption = (device) => {
    if (!device.hardware_encryption_caps) return i18next.t("N/A");
    switch (device.hardware_encryption_caps) {
      case "1":
      case 1:
        return i18next.t("Block-Level encryption");
      case "2":
      case 2:
        return i18next.t("File-Level encryption");
      case "3":
      case 3:
        return i18next.t("Data Protection");
      default:
        return i18next.t("N/A");
    }
  };

  static getOS = (os_type_id) => {
    let osType = "Unknown";
    switch (parseInt(os_type_id)) {
      case 1:
        osType = "Symbian";
        break;
      case 2:
        osType = "Android";
        break;
      case 3:
        osType = "Blackberry";
        break;
      case 4:
        osType = "iOS";
        break;
      case 5:
        osType = "ChromeOS";
        break;
      case 6:
        osType = "MacOS";
        break;
    }
    return osType;
  };
}

export default DeviceUtils;
