import { Form, Formik } from "formik";
import { MGHeading3, MGHelperText, MGLabel } from "@/components/typography";
import {
  MGDropdown,
  MGDropdownField,
  MGDropdownItem,
  MGDurationPickerField,
  MGTextboxField,
} from "@/components/inputs";
import { MGSwitchField } from "@/components/controls";
import { MGModal, MGModalBody, MGModalFooter, MGModalHeader } from "@/components/modals";
import { MGButton } from "@/components/buttons";
import { MGSpacing } from "@/components/spacing";
import { MGProgressSpinner } from "@/components/progress";
import PropTypes from "prop-types";
import * as yup from "yup";
import UserUtils from "@/app/utils/users/user_utils";
import { useTranslation } from "react-i18next";
import PermissionsService from "@/app/services/permissions/PermissionsService";
import PermissionConstants from "@/app/services/permissions/PermissionConstants";
import { ScreenshareModePicker } from "@/app/app-components/ScreenshareModePicker/ScreenshareModePicker";
import RegexUtils from "@/app/utils/regex/RegexUtils";
import { useState } from "react";

export const ClassStartModal = ({
  isShowing,
  initialValues,
  loading,
  classDurations,
  modes,
  classContent,
  className,
  onSubmit,
  onCancel,
}) => {
  const { t } = useTranslation();

  const validationSchema = yup.object({
    url: yup.string().matches(RegexUtils.URL_PATTERN, t("Please enter a valid URL")),
  });

  const [selectedClassContent, setSelectedClassContent] = useState(null);

  return (
    <MGModal isShowing={isShowing}>
      {loading && <MGProgressSpinner isLarge={true} />}
      {!loading && (
        <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
          {({ isSubmitting, isValid, /*status,*/ setFieldValue, setFieldTouched, values }) => {
            const setUrlFromClassContent = async (item) => {
              setSelectedClassContent(item);
              setFieldValue("url", item.meta_url);
              setFieldTouched("url");
            };

            return (
              <Form noValidate autoComplete={"off"}>
                {/* Leaving Status here to show how you can communicate back to form from the submit handler*/}

                {/* <h2 style={{ color: "red" }}>{status && status}</h2> */}
                <MGModalHeader>
                  {className ? <MGHeading3>{className}</MGHeading3> : <MGHeading3>{t("Start Class")}</MGHeading3>}

                  <p>{t("Basic settings before you start your class")}</p>
                </MGModalHeader>
                <MGModalBody>
                  <MGLabel htmlFor="duration">{t("Class Duration")}</MGLabel>

                  <MGDropdownField
                    name="duration"
                    placeholder={t("Duration")}
                    onChange={(v) => {
                      if (v != 0) {
                        setFieldValue("custom_duration", v);
                      } else {
                        setFieldValue("custom_duration", null);
                      }
                    }}
                  >
                    {classDurations.map((duration) => (
                      <MGDropdownItem key={duration.value} value={duration.value}>
                        {duration.content}
                      </MGDropdownItem>
                    ))}
                  </MGDropdownField>
                  {values.duration === 0 && (
                    <>
                      <br />
                      <MGLabel>{t("Custom Class Duration")}</MGLabel>
                      <MGDurationPickerField name="custom_duration" />
                    </>
                  )}

                  <MGLabel htmlFor="url">{t("Student Home Screen")}</MGLabel>
                  <br />
                  <MGTextboxField
                    name={"url"}
                    placeholder="'eg. www.mobileguardian.com'"
                    onChange={() => {
                      setSelectedClassContent(null);
                    }}
                  ></MGTextboxField>

                  {classContent && classContent.length > 0 && (
                    <>
                      <center style={{ margin: "var(--mg-spacing-sm) 0px" }}>
                        <MGHelperText>{t("or")}</MGHelperText>
                      </center>
                      <div style={{ margin: "var(--mg-spacing-md) 0px" }}>
                        <MGDropdown
                          placeholder={t("Select from class content")}
                          value={selectedClassContent}
                          onChange={setUrlFromClassContent}
                        >
                          {classContent.map((item) => (
                            <MGDropdownItem key={item.id} value={item}>
                              {item.name}
                            </MGDropdownItem>
                          ))}
                        </MGDropdown>
                      </div>
                    </>
                  )}

                  <MGLabel htmlFor="classModeId">{t("Class Mode")}</MGLabel>
                  <MGDropdownField
                    name="classModeId"
                    placeholder={
                      !modes || modes.length < 1 ? t("No class modes available") : t("Please select Class Mode")
                    }
                    disabled={!modes || modes.length < 1}
                  >
                    <MGDropdownItem key={-1} value={""}>
                      <span style={{ color: "var(--mg-color-side-bar-text-selected)" }}>{t("Mode off")}</span>
                    </MGDropdownItem>
                    {modes?.map((mode) => (
                      <MGDropdownItem key={mode.value} value={mode.value}>
                        {mode.content}
                      </MGDropdownItem>
                    ))}
                  </MGDropdownField>

                  {PermissionsService.hasPermission(PermissionConstants.ConfigureBluetooth) && (
                    <MGSwitchField helperText="iOS Only" name="bluetooth">
                      {t("Bluetooth")}
                    </MGSwitchField>
                  )}

                  {PermissionsService.hasPermission(PermissionConstants.ConfigureForceJoin) && (
                    <MGSwitchField name="forceJoin">{t("Force Join")}</MGSwitchField>
                  )}

                  {UserUtils.getCurrentSchoolUser()?.school?.allow_screen_share_configuration && (
                    <>
                      <MGLabel htmlFor="screen_share_enabled">{t("Screen share mode")}</MGLabel>
                      <ScreenshareModePicker
                        name={"screen_share_enabled"}
                        placeholder={t("Select screen share mode")}
                      />
                      {/* <MGDropdownField name="screen_share_enabled">
                        <MGDropdownItem value="ON">
                          {t("On")} <MGSpacing size={"xxs"} />
                          <MGHelperText>{t("(Chromebook and Android only)")}</MGHelperText>
                        </MGDropdownItem>
                        <MGDropdownItem value="OFF">
                          {t("Off")} <MGSpacing size={"xxs"} />
                          <MGHelperText>{t("(All)")}</MGHelperText>
                        </MGDropdownItem>
                        <MGDropdownItem value="ACTIVETAB">
                          {t("Active Tab")} <MGSpacing size={"xxs"} />
                          <MGHelperText>{t("(Chromebook only)")}</MGHelperText>
                        </MGDropdownItem>
                      </MGDropdownField> */}
                    </>
                  )}
                  <MGModalFooter>
                    <MGButton variant="tertiary" onClick={onCancel}>
                      {t("Cancel")}
                    </MGButton>
                    <MGSpacing size={"md"} />
                    <MGButton
                      variant="primary"
                      disabled={isSubmitting || !isValid}
                      isLoading={isSubmitting}
                      type={"submit"}
                    >
                      {t("Start Class")}
                    </MGButton>
                  </MGModalFooter>
                </MGModalBody>
              </Form>
            );
          }}
        </Formik>
      )}
    </MGModal>
  );
};

ClassStartModal.propTypes = {
  isShowing: PropTypes.bool,
  initialValues: PropTypes.any, // FIXME: Fixme
  loading: PropTypes.bool,
  classDurations: PropTypes.any, // FIXME: Fixme
  modes: PropTypes.any, // FIXME: Fixme
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default ClassStartModal;
