import React from "react";
import PropTypes from "prop-types";
import "./style.css";
import { useState } from "react";
import { useEffect } from "react";
import classNames from "classnames";
import MGInputWrapper from "../BuildingBlocks/MGInputWrapper";
import MGInput from "../BuildingBlocks/MGInput";
import MGInputButtons from "../BuildingBlocks/MGInputButtons";

import { useRef } from "react";
import { createRef } from "react";
import { MGIconButton } from "@/components/buttons";
import MGInputsOverlay from "../BuildingBlocks/MGInputsOverlay";
import { MGProgressSpinner } from "@/components/progress";
import { logger } from "@/app/services/logging/Logger";

export const MGDropdown = ({
  children,
  value,
  placeholder,
  onChange,
  multiselect,
  pluralText,
  actionButtonIcon,
  onActionButtonClick,
  loading,
  formikMeta,
  ...rest
}) => {
  const { error, disabled, success, touched } = rest;

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(!Array.isArray(value) ? value : null);
  const [selectedOptions, setSelectedOptions] = useState(Array.isArray(value) ? value : []);
  const [focusedOption, setFocusedOption] = useState(null);

  var controlRef = useRef();
  var inputWrapperRef = useRef();
  var overlayRef = useRef();
  var options = [];

  useEffect(() => {
    if (!Array.isArray(value) && selectedOption != value) {
      setSelectedOption(value);
    }
    if (Array.isArray(value) && multiselect) {
      setSelectedOptions(value);
    }
    if (!isOpen) {
      // controlRef?.current.focus();
      // logger.log('REF', inputWrapperRef.current.parentElement.parentElement);
      // inputWrapperRef?.current.focus();
      setFocusedOption(null);
    } else {
      if (!focusedOption) {
        overlayRef.current?.focus();
        if (selectedOption && !multiselect) {
          setFocusedOption(selectedOption ?? options[0]);
          refs[getFocusedIndex()]?.current?.focus();
        }
        if (selectedOptions && selectedOptions[0] && multiselect) {
          setFocusedOption(selectedOptions[0] ?? options[0]);
          refs[getFocusedIndex()]?.current?.focus();
        }
      } else {
        refs[getFocusedIndex()]?.current?.focus();
      }
    }
  }, [value]);

  var childElements = [];
  var refs = React.Children.map(children, (child) => {
    if (child != null) {
      childElements.push(child);
      options.push(child.props.value);
      return createRef();
    }
  });

  const onActionButtonClickHandler = (evt) => {
    if (typeof onActionButtonClick === "function") onActionButtonClick(evt);

    evt.stopPropagation();
  };

  const toggleDropdown = (event) => {
    event.stopPropagation();
    if (!disabled) {
      setIsOpen((state) => {
        return !state;
      });
    }
  };

  const optionSelected = (option) => {
    if (!multiselect) {
      if (option != selectedOption) {
        //only update state if value changes
        setSelectedOption(option);
      }
      if (typeof onChange === "function") {
        onChange(option);
      }
    } else {
      let selected = findMultiselectOption(option);
      let newSelectedOptions = Object.assign([], selectedOptions);
      if (!selected) {
        newSelectedOptions.push(option);
        setSelectedOptions(newSelectedOptions);
      } else {
        var index = selectedOptions.indexOf(option);
        newSelectedOptions.splice(index, 1);
        setSelectedOptions(newSelectedOptions);
      }

      if (typeof onChange === "function") {
        onChange(newSelectedOptions);
      }
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !isOpen) {
      toggleDropdown();
      event.preventDefault();
    }
    if (!isOpen && (event.key === "ArrowUp" || event.key === "ArrowDown")) {
      toggleDropdown();
    }
  };

  const handleKeyUp = (event) => {
    if (event.keyCode === 32 && !multiselect) {
      toggleDropdown();
    }
  };

  const handleOptionsKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
    }

    if (event.key === "Escape") {
      toggleDropdown();
    }

    var currentIndex = getFocusedIndex();
    logger.log("cIndex", currentIndex);
    if (event.key === "ArrowDown") {
      if (currentIndex != options.length - 1) {
        currentIndex++;
      }
      refs[currentIndex]?.current?.focus();
      setFocusedOption(options[currentIndex]);
    }

    if (event.key === "ArrowUp") {
      if (currentIndex != 0) {
        currentIndex--;
      }
      refs[currentIndex]?.current?.focus();
      setFocusedOption(options[currentIndex]);
    }
  };

  function getFocusedIndex() {
    let index = options.findIndex((option) => {
      return JSON.stringify(option) === JSON.stringify(focusedOption);
    });
    return index;
  }

  function isSelected(option) {
    if (!multiselect) {
      return JSON.stringify(option) === JSON.stringify(selectedOption);
    } else {
      let op = findMultiselectOption(option);
      return op != null;
    }
  }

  function findMultiselectOption(option) {
    if (selectedOptions && selectedOptions.length) {
      var selected = selectedOptions.filter((selectedOption) => {
        return JSON.stringify(option) == JSON.stringify(selectedOption);
      });
      if (selected && selected.length == 1) {
        return selected[0];
      }
    }
    return null;
  }

  function isFocused(option) {
    if (!multiselect) {
      return JSON.stringify(option) === JSON.stringify(focusedOption);
    } else {
      let selected = selectedOptions.filter((o) => JSON.stringify(o) == JSON.stringify(option));
      // logger.log("isFocused", option, selected);
      return selected && selected.length == 1 && JSON.stringify(selected[0]) == JSON.stringify(focusedOption);
    }
  }

  return (
    <div
      className="dropdown-wrapper"
      ref={controlRef}
      onKeyUp={handleKeyUp}
      onKeyDown={handleKeyDown}
      // tabIndex={0}
      onClick={multiselect ? null : toggleDropdown}
    >
      <MGInputWrapper
        onClick={multiselect ? toggleDropdown : null}
        className={`${classNames({
          disabled: disabled,
          success: success,
          error: error && touched,
          focused: isOpen,
        })}`}
        disabled={disabled}
        // tabIndex={0}
        aria-haspopup="dropdownOptions"
        ref={inputWrapperRef}
        aria-expanded={isOpen}
        {...formikMeta}
        {...rest}
      >
        {(selectedOption != null || (selectedOptions && selectedOptions.length == 1)) &&
          React.Children.map(childElements, (child) => {
            if (!child) return;
            if (isSelected(child.props.value)) return child;
          })}
        {selectedOptions && selectedOptions.length > 1 && (
          <div style={{ width: "100%" }}>
            {selectedOptions.length} {pluralText}
          </div>
        )}
        {selectedOption == null && !(selectedOptions && selectedOptions.length != 0) && (
          <MGInput type="text" value={""} placeholder={placeholder ?? "Select"} readOnly tabIndex={-1}></MGInput>
        )}
        <MGInputButtons>
          {loading && <MGProgressSpinner isLarge={false} />}

          {actionButtonIcon && (
            <MGIconButton onClick={onActionButtonClickHandler} icon={actionButtonIcon} tabindex={0} />
          )}

          <MGIconButton
            onClick={multiselect ? toggleDropdown : null}
            disabled={disabled}
            icon={isOpen && children ? "arrow_up" : "arrow_down"}
            tabindex={0}
          />
        </MGInputButtons>
      </MGInputWrapper>
      {isOpen && children && (
        <MGInputsOverlay
          trigger={controlRef}
          inheritWidthFromTrigger={true}
          tabIndex={-1}
          onOutsideClick={multiselect ? toggleDropdown : null}
        >
          <div
            className={`dropdown-options${multiselect ? " dropdown-options-multiselect" : ""}`}
            role="dropdownOptions"
            aria-activedescendant={selectedOption}
            onKeyDown={handleOptionsKeyDown}
            tabIndex={0}
            ref={overlayRef}
          >
            {React.Children.map(childElements, (child, index) => {
              if (!child) return;
              var newChild = React.cloneElement(child, {
                ref: refs[index],
                selected: isSelected(child.props.value) ?? false,
                focused: isFocused(child.props.value) ?? false,
                onClick: () => optionSelected(child.props.value),
                selectable: multiselect,
              });
              return newChild;
            })}
          </div>
        </MGInputsOverlay>
      )}
    </div>
  );
};

MGDropdown.propTypes = {
  onChange: PropTypes.func,
  children: PropTypes.any,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  multiselect: PropTypes.bool,
  disabled: PropTypes.bool,
  pluralText: PropTypes.string,
};

MGDropdown.defaultProps = {
  pluralText: "Options selected",
};

export default MGDropdown;

// export const DropdownOptionPrototype = React.forwardRef((props, ref) => {
//   return (
//     <button
//       ref={ref}
//       tabIndex={0}
//       role="dropdownOption"
//       className="dropdown-option"
//       aria-selected={props.selected ?? false}
//       data-focused={props.focused ?? false}
//       data-value={props.value}
//       onClick={props.onClick}
//     >
//       {props.children}
//     </button>
//   );
// });
// DropdownOptionPrototype.displayName = "DropdownOptionPrototype";
// DropdownOptionPrototype.propTypes = {
//   selected: PropTypes.bool,
//   focused: PropTypes.bool,
//   value: PropTypes.any,
//   onClick: PropTypes.any,
//   children: PropTypes.any,
// };

// DropdownOptionPrototype.defaultProps = {
//   selected: false,
//   focused: false,
// };
