import { useState, useRef, useEffect } from "react";
import "./style.css";

export const MGProgressBar = ({ mode, value }) => {
  const [internalValue, setInternalValue] = useState(value);
  const [internalMode] = useState(mode ?? "indeterminate");

  const progressValue = useRef();

  useEffect(() => {
    if (internalMode != "determinate") {
      animateProgress();
    } else {
      if (internalValue < 0) {
        setInternalValue(0);
      } else if (internalValue > 100) {
        setInternalValue(100);
      }
      progressValue.current.style.width = internalValue + "%";
    }
  });

  function animateProgress() {
    let bar = progressValue.current;
    bar.style.width = "0%";
    setInterval(() => {
      let progress = Number.parseInt(bar.style.width.replaceAll("%", ""));
      if (progress == 100) {
        progress = 0;
      }
      let newProgress = progress + 1;
      let width = newProgress + 0.2;
      let left = newProgress;
      bar.style.width = width + "%";
      bar.style.left = left + "%";
    }, 20);
  }

  return (
    <div className="--mg-progress-bar">
      <div className="--mg-progress-bar-track">
        <div ref={progressValue} className="--mg-progress-bar-value"></div>
      </div>
    </div>
  );
};

MGProgressBar.propTypes = {};

MGProgressBar.defaultProps = {};

export default MGProgressBar;
