import { DUPLICATE_CLASS_MUTATION } from "@/app/gql/classes/duplicate_class_mutation";
import useMGMutation from "@/app/utils/gql/useMGMutation";
import i18next from "i18next";

export const useDuplicateClassModal = ({ classId, className, onSuccess, onFailure, onCancel }) => {
  const [duplicateClass, { loading: loadingDuplicateClass }] = useMGMutation(DUPLICATE_CLASS_MUTATION);

  const initialValues = {
    classId: classId,
    name: i18next.t("{{%s}} Copy", { "%s": className }),
    content: true,
    students: true,
    modes: true,
  };

  const onDuplicateClassSubmit = async (values) => {
    try {
      const { data, problems } = await duplicateClass({
        variables: {
          input: values,
        },
      });

      //We no longer need to traverse the response object (shown in line below) to find any errors, we can instead pull 'problems' out of mutation
      // if (data?.duplicateClass?.errors) throw data?.duplicateClass?.errors[0].message;
      if (problems && problems.length > 0) {
        throw problems[0].message;
      }

      //Check that a class is being received in response
      if (data?.duplicateClass?.class) onSuccessHandler(data);
      else throw i18next.t("An error has occurred, please try again later"); //Generic error thrown if class data is not found
    } catch (err) {
      onFailureHandler(err);
    }
  };

  const onSuccessHandler = (data) => {
    if (typeof onSuccess === "function") onSuccess(data);
  };

  const onFailureHandler = (data) => {
    if (typeof onFailure === "function") onFailure(data);
  };

  const onCancelHandler = (data) => {
    if (typeof onCancel === "function") onCancel(data);
  };

  return {
    loadingDuplicateClass: loadingDuplicateClass,
    initialValues: initialValues,
    onDuplicateClassSubmit: onDuplicateClassSubmit,
    onCancelHandler: onCancelHandler,
  };
};
