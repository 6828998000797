import "./style.css";
import { MGHeading1 } from "@/components/typography";
import { MGFlexBoxSpacer } from "@/components/layout";

export const MGTopBar = ({ children, backTitle, url, title }) => {
  return (
    <>
      <nav className="--mg-topbar">
        <div>
          <a href={url}>{backTitle}</a>
          <MGHeading1>{title}</MGHeading1>
        </div>
        <MGFlexBoxSpacer />
        {children}
        {/* <hr /> */}
      </nav>
      {/* <hr /> */}
    </>
  );
};

MGTopBar.propTypes = {};

MGTopBar.defaultProps = {};

export default MGTopBar;
