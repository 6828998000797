import "./style.css";
import { useEffect } from "react";
import { useRef } from "react";
import classNames from "classnames";

export const MGProgressSpinner = ({ mode, isLarge, value }) => {
  const circleProgressRef = useRef();

  useEffect(() => {
    if (value) {
      if (value > 100) {
        value = 100;
      }
      let dash = circleProgressRef.current.style.strokeDasharray;
      dash = Number.parseFloat(dash.replaceAll("px", ""));
      let y = (value / 100) * dash;
      circleProgressRef.current.style.strokeDashoffset = dash - y + "px";
    }
  }, [mode, isLarge, value]);

  return (
    <div className="--mg-progress-spinner" mode={mode}>
      <svg
        preserveAspectRatio="xMidYMid meet"
        focusable="false"
        aria-hidden="true"
        className={`
          ${classNames({
            "--mg-large-spinner": isLarge,
            "--mg-small-spinner": !isLarge,
          })}`}
        viewBox="0 0 100 100"
        // style={{ width: size + "px", height: size + "px" }}
      >
        <circle
          cx="50%"
          cy="50%"
          r="45"
          className="circle-track"
          style={{ strokeDasharray: "282.743px", transformOrigin: "50% 50%" }}
        ></circle>
        <circle
          cx="50%"
          cy="50%"
          r="45"
          className="circle-progress"
          ref={circleProgressRef}
          style={{
            animationName: "spinner-stroke-rotate",
            strokeDasharray: "282.743px",
            transformOrigin: "50% 50%",
          }}
        ></circle>
      </svg>
    </div>
  );
};

MGProgressSpinner.propTypes = {};

MGProgressSpinner.defaultProps = {
  mode: "indeterminate",
  value: 0,
};

export default MGProgressSpinner;
