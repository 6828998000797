import PropTypes from "prop-types";
import { forwardRef } from "react";
import "./style.css";

// eslint-disable-next-line react/display-name
export const MGInput = forwardRef(({ type, placeholder, ...rest }, ref) => {
  return (
    <input autoComplete="off" {...rest} type={type} placeholder={placeholder} ref={ref} className="input-control" />
  );
});

MGInput.propTypes = {
  type: PropTypes.string,
  placeholder: PropTypes.string,
};

MGInput.defaultProps = {
  type: "text",
  placeholder: "",
};

export default MGInput;
