import "./style.css";

export const MGHelperText = ({ children, disabled, bold, ...rest }) => {
  return (
    <span
      className={`--mg-helper-text ${bold ? "--mg-helper-text__bold" : ""} ${
        disabled ? "--mg-helper-text__disabled" : ""
      }  `}
      {...rest}
    >
      {children}
    </span>
  );
};

MGHelperText.propTypes = {};

MGHelperText.defaultProps = {};

export default MGHelperText;
