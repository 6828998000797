import { MGIcon } from "@/components/icons";
import PropTypes from "prop-types";
import "./style.css";

export const MGNotification = ({ id, message, type, onClose }) => {
  const cssClassNames = ["mg-notification", `mg-notification-${type}`, "--mg-effect-shadow-level1"];

  const handleClose = () => {
    if (typeof onClose === "function") {
      onClose();
    }
  };

  return (
    <div className={cssClassNames.join(" ")} data-id={id}>
      <div className="mg-notification__message">
        <div className={`mg-notification__icon`}>
          <span className={`mg-notification__icon-${type}`}>
            <MGIcon size={22} icon={type} />
          </span>
          <p style={{ margin: "0px" }}>{type?.charAt(0).toUpperCase() + type.slice(1)}</p>
        </div>
        <p style={{ fontSize: "12px" }}>{message}</p>
      </div>
      <div className="mg-notification__dismiss" onClick={handleClose} style={{ fontSize: "12px" }}>
        Close
      </div>
    </div>
  );
};

MGNotification.propTypes = {
  id: PropTypes.string,
  message: PropTypes.string,
  type: PropTypes.string,
  onClose: PropTypes.func,
};

MGNotification.defaultProps = {
  type: "info",
  message: "This is a notification",
};

export default MGNotification;
