import { gql } from "@apollo/client";

export const END_CLASS_MUTATION = gql`
  mutation endClass($input: EndClassInput!) {
    endClass(input: $input) {
      __typename
      endClassData {
        result
      }
      errors {
        __typename
        ... on NotQuiteSureProblem {
          message
        }
        ... on Problem {
          message
        }
      }
    }
  }
`;

export default END_CLASS_MUTATION;
