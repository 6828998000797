class PermissionsService {
  constructor() {}

  getCurrentUserPermissions() {
    let me = JSON.parse(localStorage.getItem("currentUser"));
    let permissions = me?.role?.permissions ?? [];
    return permissions;
  }

  hasPermission(permission) {
    let a = false;
    this.getCurrentUserPermissions().forEach((p) => {
      if (p.name.toString() === permission.toString()) {
        a = p.granted;
      }
    });
    return a;
  }
}
export default new PermissionsService();
