import classNames from "classnames";
import { useRef } from "react";
import MGInput from "../BuildingBlocks/MGInput";
import "./style.css";

export const MGColorPicker = ({ value, error, success, disabled, onChange, formikField, formikMeta }) => {
  // const { error, disabled, success, touched } = formikMeta;

  const nativeColorPickerRef = useRef();

  const onChangeHandler = (evt) => {
    if (typeof onChange === "function") onChange(evt.target.value);
  };

  const onColorPickerClick = () => {
    if (!disabled && !formikMeta?.disabled) nativeColorPickerRef.current.click();
  };

  const determineDisplayColor = () => {
    let displayColor = "transparent";
    if (!value && !formikField?.value) {
      return displayColor;
    }
    if (formikField?.value) {
      return formikField?.value;
    }
    if (value) {
      return value;
    }
    return displayColor;
  };

  const displayColor = determineDisplayColor();

  return (
    <div className={"--mg-color-picker"} onClick={onColorPickerClick}>
      <div
        className={`--mg-color-picker-border ${classNames({
          disabled: formikMeta?.disabled ?? disabled,
          success: formikMeta?.success ?? success ?? false,
          error: (formikMeta?.error && formikMeta?.touched) ?? error ?? false,
        })}`}
      >
        <div className={"--mg-color-picker-selected-color"} style={{ backgroundColor: displayColor }}></div>

        {/* <span className="--mg-color-picker-input-container"> */}
        <MGInput
          {...formikField}
          style={{
            // display: "none",
            maxHeight: "0px",
            maxWidth: "0px",
            bottom: "0px",
            padding: "0px",
            margin: "0px",
            position: "absolute",
            visibility: "hidden",
          }}
          className={"--mg-color-picker-selected-color"}
          type="color"
          value={value ?? formikField?.value ?? displayColor}
          onChange={onChangeHandler}
          ref={nativeColorPickerRef}
        ></MGInput>
        {/* </span> */}
      </div>
    </div>
  );
};

export default MGColorPicker;
