import { MGHelperText } from "@/components/typography";
import { ErrorMessage } from "formik";
import MGDurationPicker from "./MGDuratonPicker";
import MGField from "../BuildingBlocks/MGField";
import PropTypes from "prop-types";

export const MGDurationPickerField = ({ name, disabled, onChange, inline }) => {
  return (
    <MGField name={name} inline={inline}>
      {({ meta, form, field }) => {
        const onChangeHandler = (evt) => {
          form.setFieldValue(field.name, evt);
          if (typeof onChange === "function") onChange(evt);
        };

        return (
          <>
            <MGDurationPicker
              value={field.value}
              disabled={disabled}
              onChange={onChangeHandler}
              formikField={field}
              formikMeta={meta}
            />
            <ErrorMessage component={MGHelperText} name={name} />
          </>
        );
      }}
    </MGField>
  );
};

MGDurationPickerField.propTypes = {
  name: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  inline: PropTypes.bool,
};

export default MGDurationPickerField;
