import { createContext, useState } from "react";

import "./style.css";
import { nanoid } from "nanoid";
import MGNotification from "../MGNotification/MGNotification";
import PropTypes from "prop-types";
import MGMiniNotification from "../MGNotification/MGMiniNotification";

export const MGNotificationContext = createContext(null);

export const MGNotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);

  const show = ({ message, type, onClose, duration = 7, tiny }) => {
    const notificationId = nanoid();
    let notification = {
      id: notificationId,
      message,
      type,
      onClose,
      displayed: true,
      duration,
      tiny,
    };
    setNotifications((prevNotifications) => [notification, ...prevNotifications]);

    if (duration) {
      setTimeout(() => {
        hide(notification);
      }, duration * 1000);
    }
  };

  const removeNotification = (notification) => {
    setNotifications((prevNotifications) => prevNotifications.filter((n) => n.id !== notification.id));
  };

  const hide = async (notification) => {
    while (notification.hovering) {
      await new Promise((resolve) => setTimeout(resolve, notification.duration * 1000));
    }

    setNotifications((prevNotifications) => {
      return prevNotifications.map((n) => {
        if (n.id !== notification.id) return n;
        return {
          ...n,
          displayed: false,
        };
      });
    });
  };

  const handleClose = (notification, onClose) => {
    removeNotification(notification);
    if (typeof onClose === "function") {
      onClose();
    }
  };

  return (
    <MGNotificationContext.Provider value={{ show }}>
      {children}
      {notifications && notifications.length > 0 && (
        <div className="--mg-notification-panel">
          {notifications.map((notification) => {
            const { id, onClose, ...rest } = notification;
            if (!notification.displayed) return;

            if (notification?.tiny === true) {
              return (
                <MGMiniNotification
                  key={id}
                  id={id}
                  onClose={() => handleClose(notification, onClose)}
                  onMouseEnter={() => (notification.hovering = true)}
                  onMouseLeave={() => (notification.hovering = false)}
                  {...rest}
                />
              );
            } else
              return (
                <MGNotification
                  key={id}
                  id={id}
                  onClose={() => handleClose(notification, onClose)}
                  onMouseEnter={() => (notification.hovering = true)}
                  onMouseLeave={() => (notification.hovering = false)}
                  {...rest}
                />
              );
          })}
        </div>
      )}
    </MGNotificationContext.Provider>
  );
};

MGNotificationProvider.propTypes = {
  children: PropTypes.any,
};

MGNotificationProvider.defaultProps = {};

export default MGNotificationProvider;
