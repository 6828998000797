import PropTypes from "prop-types";
import { forwardRef } from "react";

import "./style.css";

export const MGMenuTrigger = forwardRef((props, ref) => {
  return (
    <div className="--mg-menu-trigger" ref={ref}>
      {props.children}
    </div>
  );
});

MGMenuTrigger.displayName = "MGMenuTrigger";

MGMenuTrigger.propTypes = {
  children: PropTypes.any,
};

MGMenuTrigger.defaultProps = {};
