import "./style.css";

import { ErrorMessage } from "formik";

import PropTypes from "prop-types";
import { MGHelperText } from "@/components/typography";
import { MGNumberBox } from "./MGNumberBox";
import MGField from "../BuildingBlocks/MGField";

export const MGNumberBoxField = ({ name, placeholder, disabled, onChange, inline }) => {
  return (
    <MGField name={name} inline={inline}>
      {({ meta, field, form }) => {
        const onChangeHandler = (evt) => {
          form.setFieldValue(field.name, evt);
          if (typeof onChange === "function") onChange(evt);
        };

        return (
          <>
            <MGNumberBox
              placeholder={placeholder}
              value={field.value}
              disabled={disabled}
              onChange={onChangeHandler}
              formikField={field}
              formikMeta={meta}
            ></MGNumberBox>
            <ErrorMessage component={MGHelperText} name={name} />
          </>
        );
      }}
    </MGField>
  );
};

MGNumberBoxField.propTypes = {
  name: PropTypes.string,
  clearable: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  inline: PropTypes.bool,
};

MGNumberBoxField.defaultProps = {
  clearable: true,
};

export default MGNumberBoxField;
