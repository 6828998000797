import { defaultDataIdFromObject, gql, InMemoryCache, makeVar } from "@apollo/client";

// reactive variable
export const isLoggedInVar = makeVar(!!localStorage.getItem("cookie_expiry"));
export const currentUserVar = makeVar(JSON.parse(localStorage.getItem("currentUser")) ?? null);
export const backLinkVar = makeVar(null);
export const pageTitleVar = makeVar(null);
export const backTitleVar = makeVar(null);
export const automaticRoleChangeVar = makeVar(null);
export const manualRoleChangeVar = makeVar(null);

// Client Side Schema
// https://www.apollographql.com/docs/react/local-state/client-side-schema/
export const typeDefs = gql(`
  extend type Query {
    isLoggedIn: Boolean!
  }
`);

export const cache = new InMemoryCache({
  dataIdFromObject(responseObject) {
    switch (responseObject.__typename) {
      // case "ClassStudentDevice":
      //   return `ClassStudentDevice:${responseObject.student?.id}`;
      default:
        return defaultDataIdFromObject(responseObject);
    }
  },
  typePolicies: {
    Query: {
      fields: {
        isLoggedIn: {
          read() {
            return isLoggedInVar();
          },
        },
      },
    },
    Class: {
      // from https://www.apollographql.com/docs/react/local-state/managing-state-with-field-policies/
      // TODO: Move common functionality here
      // fields: {
      //   // Field policy map for the Class type
      //   scheduleWeekdayText: {
      //     // Field policy for the scheduleWeekdayText field
      //     read(_, { variables }) {
      //       return "Weee";
      //     },
      //   },
      //   scheduleWeekdayTimes: {
      //     // Field policy for the isInCart field
      //     read(_, { variables }) {
      //       // The read function for the scheduleWeekdayTimes field
      //       return "Mee";
      //     },
      //   },
      // },
    },
  },
});
