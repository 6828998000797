import "./style.css";
import { MGHelperText } from "@/components/typography";
import PropTypes from "prop-types";
import { ErrorMessage } from "formik";
import { MGSwitch } from "./MGSwitch";
import { MGField } from "@/components/inputs";

export const MGSwitchField = ({ name, children, onChange, disabled, helperText, inline }) => {
  return (
    <MGField name={name} inline={inline}>
      {({ field, form }) => {
        const onChangeHandler = (evt) => {
          if (typeof onChange === "function") {
            onChange(evt);
          }

          form.setFieldTouched(field.name, true); //This can be removed later
          form.setFieldValue(field.name, evt);
        };
        return (
          <>
            <MGSwitch helperText={helperText} value={field.value} disabled={disabled} onChange={onChangeHandler}>
              {children}
              <ErrorMessage component={MGHelperText} name={name} />
            </MGSwitch>
          </>
        );
      }}
    </MGField>
  );
};

MGSwitchField.propTypes = {
  name: PropTypes.string,
  children: PropTypes.any,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  helperText: PropTypes.string | PropTypes.node,
  inline: PropTypes.bool,
};

MGSwitchField.defaultProps = {};

export default MGSwitchField;
