import {
  MUTATION_DEVICE_ACTION_SHARE_SCREEN,
  MUTATION_DEVICE_ACTION_SHARE_TEACHER_SCREEN,
} from "@/app/gql/devices/device_actions_mutations";
import { Subject } from "rxjs";
import screenShareSocket from "../socket/screen-share-socket";
import socket from "../socket/socket";

import { WebRTCConnection } from "./web-rtc-connection";
import { NatsService } from "../nats/nats-service";
import UserUtils from "@/app/utils/users/user_utils";
import { activeClassVar } from "@/app/store/activeClassStore";
import { authService } from "@/main";
import { logger } from "@/app/services/logging/Logger";

// import i18next from "i18next";

const TEACHER_SCREENSHARE_WINDOW_WIDTH = 1920;
// const TEACHER_SCREENSHARE_WINDOW_HEIGHT = 1080;

class WebRTCShare {
  mediaStream;
  classId;
  peerConnections = [];
  remoteSessionDescriptions = [];
  remoteSessionDescriptionSizes = [];
  remoteIceCandidates = [];
  studentScreenShare = {};
  // teacherScreenShareSubScription;
  sharingToDevices;

  video = document.createElement("video");
  canvas = document.createElement("canvas");
  canvasContext = this.canvas.getContext("2d");
  canvasStream;
  canvasDrawInterval;

  constructor(apolloClient) {
    this.apolloClient = apolloClient;
    var device = localStorage.getItem("sharing_device");
    if (device && typeof device === "string") {
      this.studentScreenShare = { device: JSON.parse(device) };
    }
  }

  _isSharing = new Subject();
  isSharing() {
    return this._isSharing.asObservable();
  }

  checkIsSharing() {
    if (!this.peerConnections) {
      this._isSharing.next(false);
    }

    let device_signatures = Object.keys(this.peerConnections);
    if (!device_signatures) {
      this._isSharing.next(false);
    }

    if (this.studentScreenShare && this.studentScreenShare.device) {
      this._isSharing.next(false);
    }

    let filtered = [];
    device_signatures.forEach((device_signature) => {
      let peerConnection = this.peerConnections[device_signature];
      if (peerConnection && peerConnection.connectionState !== "failed") {
        filtered.push(peerConnection);
      }
    });

    this._isSharing.next(filtered.length > 0);
    // if(filtered.length>0)
    // {
    //   this.setupDOMComponentsForTeacherScreenshare(this.mediaStream)
    // }
  }

  _isDeviceSharing = new Subject();
  isDeviceSharing() {
    return this._isDeviceSharing.asObservable();
  }

  /**
   *
   * @param {object} device
   */
  checkIsDeviceSharing(device) {
    if (this.studentScreenShare && this.studentScreenShare.device) {
      if (this.studentScreenShare.device.device_signature == device.device_signature) {
        this._isDeviceSharing.next(this.studentScreenShare.device);
      } else {
        this._isDeviceSharing.next(null);
      }
    } else {
      this._isDeviceSharing.next(null);
    }
  }

  /**
   *
   * @param {array[object]} devices
   * @param {string} classId
   */
  // shareMyScreen(devices, classId) {
  //   this.classId = classId;
  //   this.sharingToDevices = devices;
  //   if (devices.length) {
  //     logger.debug("sharing screen to devices", devices, this.peerConnections);
  //     this.registerSocketListeners(devices);
  //     this.getMedia()
  //       .then(() => {
  //         if (this.mediaStream) {
  //           this.mediaStream.oninactive = () => {
  //             this.stopSharingMyScreen(devices);
  //           };

  //           this.mediaStream.onended = () => {
  //             this.stopSharingMyScreen(devices);
  //           };
  //         }
  //         if (this.canvasStream) {
  //           this.canvasStream.oninactive = () => {
  //             this.stopSharingMyScreen(devices);
  //           };

  //           this.canvasStream.onended = () => {
  //             this.stopSharingMyScreen(devices);
  //           };
  //         }
  //         this.startTeacherScreenShare(devices);
  //         this.registerNatsListener();
  //         this.checkIsSharing();
  //       })
  //       .catch((err) => {
  //         logger.error("shareMyScreen", err);
  //         if (err?.message?.includes("Permission denied")) {
  //           alert(
  //             i18next.t(
  //               "A permission error was thrown trying to initiate Teacher Screenshare. Please ensure browser has necessary permissions to share all screens & windows & try again"
  //             )
  //           );
  //           this.stopSharingMyScreen(devices);
  //         }
  //         this.checkIsSharing();
  //       });
  //   }
  // }

  setupDOMComponentsForTeacherScreenshare() {
    const isDevEnv = !process.env.NODE_ENV || process.env.NODE_ENV === "development";
    const maxWidth = localStorage.getItem("teacher-screenshare-maximum-width-px");

    if (!maxWidth || isNaN(maxWidth)) {
      localStorage.setItem("teacher-screenshare-maximum-width-px", TEACHER_SCREENSHARE_WINDOW_WIDTH);
    }

    if (document.getElementById("teacher-screenshare-DOM").children.length < 2) {
      if (isDevEnv) {
        // document.getElementById("teacher-screenshare-DOM").appendChild(this.canvas);
        // document.getElementById("teacher-screenshare-DOM").appendChild(this.video);
      }

      if (!this.video) {
        this.video = document.createElement("video");
        this.canvas = document.createElement("canvas");
        if (!this.canvasContext) {
          this.canvasContext = this.canvas.getContext("2d");
        }
      }
      this.video.srcObject = this.mediaStream;
      this.video.play();
      this.video.onloadedmetadata = () => {
        // eslint-disable-next-line no-console
        console.log("Loaded video");
        this.canvasStream = this.canvas.captureStream(0);

        var videoWidth = 1920;
        var videoHeight = 1080;

        const vWidth = this.video.videoWidth;
        const vHeight = this.video.videoHeight;
        let widthRatio = vWidth / videoWidth;
        let heightRatio = vHeight / videoHeight;

        var finalWidth = vWidth;
        var finalHeight = vHeight;
        if (videoWidth < vWidth && videoHeight < vHeight) {
          if (widthRatio < heightRatio) {
            finalWidth = videoWidth;
            videoHeight = finalHeight = vHeight / widthRatio;
          } else {
            finalHeight = videoHeight;
            videoWidth = finalWidth = vWidth / heightRatio;
          }
        }
        this.canvas.width = finalWidth;
        this.canvas.height = finalHeight;
        // let ratio  = Math.min(this.canvas.width / this.video.videoWidth, this.canvas.height / this.video.videoHeight);

        // this.canvasContext.imageSmoothingEnabled = false;
        this.captureLoop();
        // this.canvasDrawInterval = setInterval(() => {
        //   if (!this.canvasStream) {
        //     clearInterval(this.canvasDrawInterval);
        //   }
        //   // else {
        //     // this.canvasContext.drawImage(this.video, 0, 0, this.canvas.width, this.canvas.height);
        //     this.canvasContext.drawImage(this.video, 0, 0, this.video.videoWidth, this.video.videoHeight, 0, 0, this.video.videoWidth * ratio, this.video.videoHeight * ratio);
        //     // this.canvasStream?.getVideoTracks()[0]?.requestFrame();
        //   // }
        // }, 1000);
      };

      // this.scaleCanvasSizeAfterDelay(2, 1);

      // this.scaleCanvasSizeAfterDelay(2, 2);
      // this.scaleCanvasSizeAfterDelay(22, 1);
    }
  }

  webRTCTimeout;
  capture() {
    // if (this.mediaStream && this.mediaStream.active && this.canvasStream){
    this.canvasContext.drawImage(this.video, 0, 0, this.canvas.width, this.canvas.height);
    this.canvasStream.getVideoTracks()[0].requestFrame();
    this.canvasContext.drawImage(this.video, 0, 0, this.canvas.width, this.canvas.height);

    // }
  }

  captureLoop() {
    if (this.webRTCTimeout) {
      clearTimeout(this.webRTCTimeout);
    }
    this.webRTCTimeout = setTimeout(async () => {
      // if (this.mediaStream && this.mediaStream.active){
      this.capture();
      this.captureLoop();
      // }
    }, 1000);
  }

  // scaleCanvasSizeAfterDelay(delayInSeconds, scaleFactor) {
  //   setTimeout(() => {
  //     const requestedMaxWidth = localStorage.getItem("teacher-screenshare-maximum-width-px");
  //     const vWidth = this.video.videoWidth;
  //     const vHeight = this.video.videoHeight;

  //     const aspectRatio = vWidth / vHeight;

  //     let finalWidth = vWidth;
  //     let finalHeight = vHeight;

  //     if (finalWidth > requestedMaxWidth) {
  //       finalWidth = requestedMaxWidth;
  //       finalHeight = finalWidth / aspectRatio;
  //     }

  //     if (finalHeight > vHeight) {
  //       finalHeight = vHeight;

  //       finalWidth = finalHeight * aspectRatio;
  //     }
  //     // this.canvas.width = scaleFactor * finalWidth;
  //     // this.canvas.height = scaleFactor * finalHeight;
  //     this.canvas.width = this.video.videoWidth;
  //     this.canvas.height = this.video.videoHeight;

  //     const prevSettings = this.canvasStream.getVideoTracks()[0].getSettings();
  //     this.canvasStream.getVideoTracks()[0].applyConstraints({
  //       ...prevSettings,
  //       width: scaleFactor * finalWidth,
  //       height: scaleFactor * finalHeight,
  //     });
  //   }, delayInSeconds * 1000);
  // }

  tearDownDOMComponentsForTeacherScreenshare() {
    //remove video & canvas from dom
    clearInterval(this.canvasDrawInterval);
    clearTimeout(this.webRTCTimeout);

    if (this.canvasStream && this.canvasStream.active) {
      this.canvasStream.getTracks().forEach((track) => track.stop());
    }

    this.canvasStream = null;
    this.canvasContext = null;
    this.canvas = null;
    if (this.mediaStream && this.mediaStream.active) {
      this.mediaStream.getTracks().forEach((track) => track.stop());
    }
    if (this.canvasStream && this.canvasStream.active) {
      this.canvasStream.getTracks().forEach((track) => track.stop());
    }
    this.mediaStream = null;

    document.getElementById("teacher-screenshare-DOM").innerHTML = "";

    if (this.video && typeof this.video.stop === "function") this.video?.stop();

    this.video = null;
  }

  startTeacherScreenShare(devices) {
    let device_signatures = [];
    let device_uuids = [];

    devices?.map((device) => {
      device_signatures.push(device.device_signature);
      device_uuids.push(device.device_uuid);
    });

    authService.ensureMutationIsAuthenticated(() => {
      this.apolloClient.mutate({
        mutation: MUTATION_DEVICE_ACTION_SHARE_TEACHER_SCREEN,
        variables: {
          input: {
            payload: JSON.stringify({
              action: "start",
              module: "share",
              to: device_signatures,
              from: screenShareSocket.socket.id,
              teacherClientId: NatsService.getInstance().teacherSignature,
              iceUrl: localStorage.getItem("iceUrl"),
            }),
            deviceUUID: device_uuids,
          },
        },
      });
      // .then(
      //   (data) => {
      //     if (data.data.deviceActionShareTeacherScreen.errors) {
      //       let errors = data.data.deviceActionShareTeacherScreen.errors;
      //       for (var error of errors) {
      //         if (error.message.toLowerCase() == "access token is not valid") {
      //           logger.warn("ACCESS TOKEN INVALID RETRYING!");
      //           if (!this.deviceRetries[device.device_signature]) {
      //             this.deviceRetries[device.device_signature] = 0;
      //           }
      //           if (this.deviceRetries[device.device_signature] < 5) {
      //             this.deviceRetries[device.device_signature] += 1;
      //             this.startTeacherScreenShare(device);
      //           } else {
      //             logger.warn("max retries reached for starting teacher screen share with ", device);
      //             this.deviceRetries[device.device_signature] = 0;
      //           }
      //         }
      //       }
      //     } else {
      //       if (this.deviceRetries[device.device_signature]) {
      //         delete this.deviceRetries[device.device_signature];
      //       }
      //     }
      //   },
      //   (error) => {
      //     logger.error("there was an error sending the query", error);
      //   }
      // );
    });
  }

  bulkStopsScreenSharing(devices) {
    let device_signatures = [];
    let device_uuids = [];

    devices?.map((device) => {
      device_signatures.push(device.device_signature);
      device_uuids.push(device.device_uuid);
    });

    //stopSharingMyScreen
    devices?.forEach((device) => {
      this.deregisterRTCEvents(device, this.peerConnections[device.device_signature]);
    });
    authService.ensureMutationIsAuthenticated(() => {
      this.apolloClient
        .mutate({
          mutation: MUTATION_DEVICE_ACTION_SHARE_TEACHER_SCREEN,
          variables: {
            input: {
              payload: JSON.stringify({
                action: "stop",
                module: "share",
                to: device_signatures,
                from: screenShareSocket.socket.id,
              }),
              deviceUUID: device_uuids,
            },
          },
        })
        .then(
          (data) => {
            logger.debug("gql response:", data);
          },
          (error) => {
            logger.debug("there was an error sending the query", error);
          }
        );
    });

    // this.teacherScreenShareSubScription?.unsubscribe();
    // this.teacherScreenShareSubScription = undefined;
    localStorage.removeItem("isSharingScreen");
    this.sharingToDevices = [];
    this.peerConnections = [];
    this.checkIsSharing();
    window.onbeforeunload = null;
    window.onunload = null;

    //stopScreenShare
    this.sendToMultipleDevices(
      devices,
      {
        action: "stop",
      },
      "stop",
      false
    );

    if (devices && devices.length) {
      devices.forEach((device) => {
        let peerConnection = this.peerConnections[device.device_signature];
        if (peerConnection) {
          peerConnection.onicecandidate = null;
          peerConnection.oniceconnectionstatechange = null;
          peerConnection.close();
          this.peerConnections[device.device_signature] = undefined;
          delete this.peerConnections[device.device_signature];
        }
      });
      if (this.mediaStream && this.mediaStream.active) {
        this.mediaStream.getTracks().forEach((track) => track.stop());
      }
      if (this.canvasStream && this.canvasStream.active) {
        this.canvasStream.getTracks().forEach((track) => track.stop());
      }
    }
    localStorage.removeItem("sharing_device");
    this.checkIsSharing();
  }

  // stopSharingMyScreen(devices) {
  //   this.tearDownDOMComponentsForTeacherScreenshare();

  //   let device_signatures = [];
  //   let device_uuids = [];

  //   devices?.map((device) => {
  //     device_uuids.push(device.device_uuid);
  //     device_signatures.push(device.device_signature);
  //     this.deregisterRTCEvents(device, this.peerConnections[device.device_signature]);
  //     this.dispose(this.peerConnections[device.device_signature]);
  //   });

  //   if (this.replacementIntervals) {
  //     for (var key of Object.keys(this.replacementIntervals)) {
  //       clearInterval(this.replacementIntervals[key].interval);
  //     }
  //     this.replacementIntervals = null;
  //   }

  //   if (this.webRTCTimeout) {
  //     clearTimeout(this.webRTCTimeout);
  //   }

  //   authService.ensureMutationIsAuthenticated(() => {
  //     this.apolloClient
  //       .mutate({
  //         mutation: MUTATION_DEVICE_ACTION_SHARE_TEACHER_SCREEN,
  //         variables: {
  //           input: {
  //             payload: JSON.stringify({
  //               action: "stop",
  //               module: "share",
  //               to: device_signatures,
  //               from: screenShareSocket.socket.id,
  //             }),
  //             deviceUUID: device_uuids,
  //           },
  //         },
  //       })
  //       .then(
  //         (data) => {
  //           logger.debug("gql response:", data);
  //         },
  //         (error) => {
  //           logger.debug("there was an error sending the query", error);
  //         }
  //       );
  //   });

  //   this.teacherScreenShareSubScription?.unsubscribe();
  //   this.teacherScreenShareSubScription = undefined;
  //   localStorage.removeItem("isSharingScreen");
  //   this.sharingToDevices = [];
  //   this.peerConnections = [];
  //   this.checkIsSharing();
  //   window.onbeforeunload = null;
  //   window.onunload = null;
  //   this.remoteIceCandidates = [];
  //   this.remoteSessionDescriptionSizes = [];
  //   this.remoteSessionDescriptions = [];
  // }

  /**
   *
   * @param {array[object]} devices
   */
  stopScreenShare(devices, socketDisabled = false) {
    if (devices && devices.length) {
      let targetDevices = [];
      devices.forEach((device) => {
        targetDevices.push(device);
      });

      this.sendToMultipleDevices(
        targetDevices,
        {
          action: "stop",
        },
        "stop",
        socketDisabled
      );

      //close connection
      targetDevices.forEach((device) => {
        let peerConnection = this.peerConnections[device.device_signature];
        if (peerConnection) {
          peerConnection.onicecandidate = null;
          peerConnection.oniceconnectionstatechange = null;
          peerConnection.close();
          this.peerConnections[device.device_signature] = undefined;
          delete this.peerConnections[device.device_signature];
        }
      });

      if (this.mediaStream && this.mediaStream.active) {
        this.mediaStream.getTracks().forEach((track) => track.stop());
      }
      if (this.canvasStream && this.canvasStream.active) {
        this.canvasStream.getTracks().forEach((track) => track.stop());
      }
    }
    localStorage.removeItem("sharing_device");
    this.checkIsSharing();
  }

  /**
   *
   * @param {object} device
   * @returns
   */
  shareStudentScreen(device, screenshare_stream_protocol) {
    const schoolId = UserUtils.getCurrentSchoolUser()?.school_id;
    const classId = activeClassVar().classId;

    logger.debug("sharing student device", device.device_signature);
    if (this.studentScreenShare.device) {
      logger.debug("stopping existing device screen hare");
      let targetDevices = [];
      targetDevices.push(device);
      this.sendToMultipleDevices(targetDevices, { on: false }, "share", true);
    }
    this.studentScreenShare.device = device;

    NatsService.getInstance().publish(`school.${schoolId}.class.${classId}`, {
      type: "student_screen_share",
      targets: [device.device_signature],
      payload: {
        action: "share",
        on: true,
        stream_source: screenshare_stream_protocol,
        iceUrl: localStorage.getItem("iceUrl"),
      },
    });

    // this.sendToDevice(device,
    //   {
    //     on: true,
    //     stream_source: stream_source
    //   },
    //   "share",
    //   true);
    this.checkIsDeviceSharing(device);
    return { refresh: true };
  }

  /**
   *
   * @param {object} device
   * @returns
   */
  stopSharingStudentsScreen(device) {
    if (!this.studentScreenShare) {
      this.checkIsDeviceSharing(device);
      return;
    }
    if (!this.studentScreenShare.device) {
      this.checkIsDeviceSharing(device);
      return;
    }

    ///this.sendToDevice(this.studentScreenShare.device, { on: false }, "share", true);
    const schoolId = UserUtils.getCurrentSchoolUser()?.school_id;
    const classId = activeClassVar().classId;
    NatsService.getInstance().publish(`school.${schoolId}.class.${classId}`, {
      type: "student_screen_share",
      targets: [device.device_signature],
      payload: {
        action: "share",
        on: false,
      },
    });

    this.studentScreenShare = {};
    this.checkIsDeviceSharing(device);
    return { refresh: true };
  }

  /**
   *
   * @returns Promise(any)
   */
  getMedia() {
    if (this.mediaStream && this.mediaStream.active) {
      return Promise.resolve(this.mediaStream);
    }
    return navigator.mediaDevices
      .getDisplayMedia({
        video: {
          frameRate: 30,
        },
        audio: false,
      })
      .then((mediaStream) => {
        this.mediaStream = mediaStream;
        this.setupDOMComponentsForTeacherScreenshare();
        return this.mediaStream;
      })
      .catch((err) => {
        throw err;
      });
  }

  /**
   *
   * @param {object} device
   * @param {any} data
   * @param {string} action
   * @param {bool} socketDisabled
   */
  sendToDevice(device, data, action = "", socketDisabled = false) {
    let payload = {
      ...data,
      action,
      module: "share",
      to: device.device_signature,
      from: screenShareSocket.socket.id,
    };

    if (screenShareSocket.connected && !socketDisabled) {
      screenShareSocket.socket.emit(screenShareSocket.SCREEN_SHARE_SEND, {
        to: device.device_signature,
        from: screenShareSocket.socket.id,
        class: this.classId,
        payload: payload,
      });
    } else {
      authService.ensureMutationIsAuthenticated(() => {
        this.apolloClient
          .mutate({
            mutation: MUTATION_DEVICE_ACTION_SHARE_SCREEN,
            variables: {
              input: {
                //payload: JSON.stringify(payload),
                on: payload.on,
                to: device.device_signature,
                from: screenShareSocket.socket.id,
                deviceUUIDs: [device.device_uuid],
              },
            },
          })
          .then(
            (data) => {
              logger.debug("gql response:", data);
            },
            (error) => {
              logger.debug("there was an error sending the query", error);
            }
          );
      });
    }

    // if (device.messaging_enabled) {
    const schoolId = UserUtils.getCurrentSchoolUser()?.school_id;
    const classId = activeClassVar().classId;
    if (!schoolId || !classId) {
      logger.error("sendToDevice", "School ID or Class ID is null");
      return;
    }
    NatsService.getInstance().publish(`school.${schoolId}.class.${classId}.teacher_screen_share`, {
      type: "teacher_screen_share",
      targets: device ? [device.device_signature] : [],
      payload: data,
    });
    // }
  }

  sendToMultipleDevices(devices, data, action = "", socketDisabled = false) {
    let device_signatures = [];
    let device_uuids = [];

    devices?.map((device) => {
      device_signatures.push(device.device_signature);
      device_uuids.push(device.device_uuid);
    });

    let payload = {
      ...data,
      action,
      module: "share",
      to: "",
      from: screenShareSocket.socket.id,
    };

    if (screenShareSocket.connected && !socketDisabled) {
      devices?.map((device) => {
        payload.to = device.device_signature;
        screenShareSocket.socket.emit(screenShareSocket.SCREEN_SHARE_SEND, {
          to: device.device_signature,
          from: screenShareSocket.socket.id,
          class: this.classId,
          payload: payload,
        });
      });
    } else {
      authService.ensureMutationIsAuthenticated(() => {
        this.apolloClient
          .mutate({
            mutation: MUTATION_DEVICE_ACTION_SHARE_SCREEN,
            variables: {
              input: {
                //payload: JSON.stringify(payload),
                on: payload.on,
                to: device_signatures,
                from: screenShareSocket.socket.id,
                deviceUUIDs: device_uuids,
              },
            },
          })
          .then(
            (data) => {
              logger.debug("gql response:", data);
            },
            (error) => {
              logger.debug("there was an error sending the query", error);
            }
          );
      });
    }

    const schoolId = UserUtils.getCurrentSchoolUser()?.school_id;
    const classId = activeClassVar().classId;
    if (!schoolId || !classId) {
      logger.error("sendToDevice", "School ID or Class ID is null");
      return;
    }

    NatsService.getInstance().publish(`school.${schoolId}.class.${classId}.teacher_screen_share`, {
      type: "teacher_screen_share",
      targets: device_signatures ? device_signatures : [],
      payload: data,
    });
  }

  /**
   *
   * @param {array[object]} devices
   */
  registerSocketListeners(devices) {
    devices.forEach((device) => {
      if (device.messaging_enabled) {
        return;
      }
      socket.socket.on("device." + device.device_uuid + ".webrtc", (data) => {
        this.handleSocketMessage(data, device);
      });
    });

    screenShareSocket.screenShareReceived().subscribe((data) => {
      if (!data) return;
      this.handleSocketMessage(data, data.from);
    });
  }

  // registerNatsListener() {
  //   const schoolId = UserUtils.getCurrentSchoolUser()?.school_id;
  //   const classId = activeClassVar().classId;
  //   if (!schoolId || !classId) {
  //     logger.error("School ID or Class ID is null");
  //     return;
  //   }

  //   this.teacherScreenShareSubScription?.unsubscribe();
  //   this.teacherScreenShareSubScription = undefined;

  //   this.teacherScreenShareSubScription = NatsService.getInstance().subscribe(
  //     `school.${schoolId}.class.${classId}.teacher_screen_share`
  //   );

  //   (async () => {
  //     for await (var message of this.teacherScreenShareSubScription) {
  //       // logger.warn("[Teacger sceenshare] Msg:", message);
  //       const messageData = NatsService.getInstance().sanitizeMessage(message);
  //       logger.debug("teacher screen share message =>", messageData?.payload?.action, messageData);
  //       if (messageData) {
  //         const device = { device_signature: messageData.sender };
  //         switch (messageData.payload.action) {
  //           case "start-ack":
  //             // this.createConnection(device, this.mediaStream);
  //             this.createConnection(device);
  //             break;
  //           case "answer":
  //             this.onAnswer(
  //               device,
  //               messageData.payload.answer,
  //               messageData.payload.has_more,
  //               messageData.payload.chunk_number
  //             );
  //             break;
  //           case "ice_candidate":
  //             this.iceCandidateReceived(device, messageData.payload.iceCandidate);
  //             break;
  //           case "stop":
  //             return;
  //         }
  //       }
  //     }
  //   })().then(() => {
  //     logger.warn("teacher screen share subscription ended.");
  //     this.stopSharingMyScreen(this.sharingToDevices);
  //     this.teacherScreenShareSubScription?.unsubscribe();
  //     this.teacherScreenShareSubScription = undefined;
  //   });

  //   window.onbeforeunload = (event) => {
  //     logger.warn("window unloading...");
  //     const message =
  //       "Are you sure you want to close this window, if you are sharing your screen it will be terminated?";
  //     event = event || window.event;
  //     if (event) {
  //       event.returnValue = message;
  //     }
  //     return message;
  //   };

  //   window.onunload = (event) => {
  //     logger.debug("onunload()", event);
  //     localStorage.removeItem("isSharing");
  //     this.stopSharingMyScreen(this.sharingToDevices);
  //     this.teacherScreenShareSubScription?.unsubscribe();
  //     this.teacherScreenShareSubScription = undefined;
  //   };
  // }

  /**
   *
   * @param {any} data
   * @param {object} device
   * @returns
   */
  handleSocketMessage(data, device) {
    if (!data || !data.payload) return;

    if (data.payload.sharingType != "share") return;

    if (typeof device == "string" || !device) {
      device = Object.assign({}, { device_signature: device });
    }

    if (data.payload.type == "start-ack") {
      logger.debug("start-ack for", device.device_signature);
      // this.getMedia().then((mediaStream) => {
      // if(this.mediaStream){
      this.createConnection(device);
      // }else {
      //   this.getMedia().then((mediaStream) => {
      //     this.createConnection(device, mediaStream);
      //   });
      // }
    }

    if (data.payload.type == "answer") {
      logger.debug("answer for", device.device_signature);
      this.onAnswer(device, data.payload.answer, data.payload.has_more, data.payload.chunk_number);
      return;
    }

    if (data.payload.iceCandidate) {
      this.iceCandidateReceived(device, data.payload.iceCandidate);
    }
  }

  /**
   *
   * @param {object} device
   * @param {any} mediaStream
   */

  async createConnection(device) {
    let peerConnection = this.peerConnections[device.device_signature];
    if (peerConnection) {
      logger.warn("PEER CONNECTION ALREADY EXISTS!");
      this.deregisterRTCEvents(device, peerConnection);
      this.dispose(peerConnection);
    }
    logger.warn("Setting up peer connection");
    peerConnection = new RTCPeerConnection(WebRTCConnection._configuration);
    this.registerRTCEvents(device, peerConnection);
    this.canvasStream.getTracks().forEach((track) => {
      peerConnection.videoSender = peerConnection.addTrack(track, this.canvasStream);
    });
    var offer = await peerConnection.createOffer({
      iceRestart: true,
      offerToReceiveAudio: false,
      offerToReceiveVideo: false,
    });

    offer = this.changeBitrate(offer);
    await peerConnection.setLocalDescription(offer);
    this.peerConnections[device.device_signature] = peerConnection;
    this.sendOffer(device, offer);
  }

  changeBitrate(offer) {
    let sdpParts = offer?.sdp?.split("\r\n");
    let bitratePosition;
    let finalSdp = "";
    if (sdpParts) {
      sdpParts = sdpParts.slice(0, -1);
      for (let index = 0; index < sdpParts.length; index++) {
        const element = sdpParts[index];
        if (element.match(/b=AS:/)) {
          bitratePosition = index;
        }
      }

      if (bitratePosition) {
        sdpParts.splice(bitratePosition, 1);
      }
      for (let index = 0; index < sdpParts.length; index++) {
        if (sdpParts[index].match(/m=video/)) {
          //modify and add the new lines for video
          finalSdp += sdpParts[index] + "\r\n" + "b=AS:" + "256" + "\r\n";
        } else {
          finalSdp += sdpParts[index] + "\r\n";
        }
      }

      offer.sdp = finalSdp;
      return offer;
    }
    return offer;
  }

  dispose(peerConnection) {
    var index = this.peerConnections.indexOf(peerConnection);
    if (index >= 0) {
      logger.debug("Disposing RTC connection");
      peerConnection?.close();
      peerConnection = null;
      this.peerConnections.splice(index, 1);
    }
  }

  /**
   *
   * @param {object} device
   * @param {RTCPeerConnection} peerConnection
   */
  registerRTCEvents(device, peerConnection) {
    peerConnection.onicecandidate = (event) => this.onIceCandidate(device, event);
    peerConnection.oniceconnectionstatechange = (event) => this.onIceConnectionStateChange(device, event);
  }

  /**
   *
   * @param {bnject} device
   * @param {RTCPeerConnection} peerConnection
   */
  deregisterRTCEvents(device, peerConnection) {
    if (peerConnection != null) {
      peerConnection.getSenders().forEach((sender) => {
        sender.track.stop();
      });
      peerConnection.onicecandidate = null;
      peerConnection.oniceconnectionstatechange = null;
    }
    this.peerConnections[device.device_signature] = undefined;
    delete this.peerConnections[device.device_signature];
  }

  /**
   *
   * @param {object} device
   * @param {any} event
   */
  onIceCandidate(device, event) {
    logger.debug("onIceCandidate", device.device_signature, event);
    if (event.candidate != null) {
      let targetDevices = [];
      targetDevices.push(device);
      this.sendToMultipleDevices(
        targetDevices,
        { action: "ice_candidate", iceCandidate: event.candidate },
        "icecandidate"
      );
    }
  }

  replacementIntervals = {};
  /**
   *
   * @param {object} device
   * @param {any} event
   */
  onIceConnectionStateChange(device, event) {
    // eslint-disable-next-line no-console
    console.log("onIceConnectionStateChange", event);
    let peerConnection = this.peerConnections[device.device_signature];
    if (
      peerConnection &&
      (peerConnection.iceConnectionState == "failed" || peerConnection.iceConnectionState == "disconnected")
    ) {
      this.deregisterRTCEvents(device, peerConnection);
      this.dispose(peerConnection);
    }
    if (peerConnection && peerConnection.iceConnectionState == "connected") {
      // eslint-disable-next-line no-console
      // console.log("[WebRTCShare]", "Ice Connected, starting replacement loop");
      // if (!this.replacementIntervals){
      //   this.replacementIntervals = {};
      // }
      // if (!this.replacementIntervals[device.device_signature]){
      //     const replaceInt = setInterval(() => {
      //       this.replacementIntervals[device.device_signature].ticks++;
      //       if (this.replacementIntervals[device.device_signature].ticks == 5){
      //           clearInterval(this.replacementIntervals[device.device_signature].interval);
      //       }
      //       this.peerConnections[device.device_signature].videoSender?.replaceTrack(this.canvasStream.getTracks()[0]);
      //     }, 1000);
      //     this.replacementIntervals[device.device_signature] = {
      //         interval: replaceInt,
      //         ticks: 0
      //     }
      // }
      // this.ticks++;
      // if (this.ticks <= 5) {
      //   // this.canvasStream = this.canvas.captureStream(0);
      //   let track = this.canvasStream.getTracks()[0];
      //   for (var pc in this.peerConnections) {
      //       // eslint-disable-next-line no-console
      //       console.log("[WebRTCShare]", "replacing track", pc);
      //       this.peerConnections[pc].videoSender?.replaceTrack(track);
      //   }
      //   // this.ticks = 0;
      // }
    }
  }

  /**
   *
   * @param {object} device
   * @param {RTCSessionDescriptionInit} offer
   */
  sendOffer(device, offer) {
    screenShareSocket.socket.emit(screenShareSocket.SCREEN_SHARE_SEND, {
      to: device.device_signature,
      from: screenShareSocket.socket.id,
      class: this.classId,
      payload: {
        module: "share",
        action: "offer",
        offer: {
          type: offer.type,
          sdp: offer.sdp,
        },
        has_more: 0,
        chunk_number: 0,
        to: device.device_signature,
        from: screenShareSocket.socket.id,
      },
    });
    const schoolId = UserUtils.getCurrentSchoolUser()?.school_id;
    const classId = activeClassVar().classId;
    if (!schoolId || !classId) {
      logger.error("sendOffer()", "School ID or Class ID is null");
      return;
    }
    NatsService.getInstance().publish(`school.${schoolId}.class.${classId}.teacher_screen_share`, {
      type: "teacher_screen_share",
      targets: [device.device_signature],
      payload: {
        action: "offer",
        offer: {
          type: offer.type,
          sdp: offer.sdp,
        },
        has_more: 0,
        chunk_number: 0,
      },
    });
  }

  /**
   *
   * @param {object} device
   * @param {any} answer
   * @param {any} hasMore
   * @param {any} chunkOrder
   * @returns
   */
  onAnswer(device, answer, hasMore, chunkOrder) {
    logger.debug("ON ANSWER");
    let sdp = answer.sdp;
    let device_signature = device.device_signature;

    if (!this.remoteSessionDescriptions[device_signature]) {
      this.remoteSessionDescriptions[device_signature] = [];
    }
    let remoteSession = this.remoteSessionDescriptions[device_signature];

    remoteSession[chunkOrder] = sdp;
    if (!hasMore) {
      this.remoteSessionDescriptionSizes[device_signature] = chunkOrder + 1;
    }
    let len = Object.keys(remoteSession).length;
    if (!hasMore && len !== this.remoteSessionDescriptionSizes[device_signature]) {
      return;
    }
    logger.debug("ON ANSWER", 1);

    if (this.remoteSessionDescriptionSizes[device_signature] !== len) {
      return;
    }
    logger.debug("ON ANSWER", 2);

    answer.sdp = remoteSession.reduce((obj, str) => {
      obj += str;
      return obj;
    }, "");

    logger.debug("ON ANSWER", 3, this.peerConnections[device_signature]);

    this.peerConnections[device_signature]
      ?.setRemoteDescription(answer)
      .then((value) => {
        logger.debug("set description for", device.device_signature);
        if (this.remoteIceCandidates[device_signature] && this.remoteIceCandidates[device_signature].length) {
          logger.debug(
            `adding ${this.remoteIceCandidates[device_signature].length} ice candidates for`,
            device_signature
          );
          this.remoteIceCandidates.forEach((candidate) => {
            this.peerConnections[device_signature]?.addIceCandidate(candidate);
          });
          this.remoteIceCandidates[device_signature] = [];
        }
        this.remoteSessionDescriptions[device_signature] = [];
        this.checkIsSharing();
        return value;
      })
      .catch((ex) => {
        logger.error(`${device_signature} failed to set remote description`, ex);
        this.remoteSessionDescriptions[device_signature] = [];
        this.remoteIceCandidates[device_signature] = [];
        this.deregisterRTCEvents(device, this.peerConnections[device_signature]);
        this.dispose(this.peerConnections[device_signature]);
        this.checkIsSharing();
      });
  }

  /**
   *
   * @param {object} device
   * @param {any} iceCandidate
   */
  iceCandidateReceived(device, iceCandidate) {
    let peerConnection = this.peerConnections[device.device_signature];
    if (!peerConnection || !(peerConnection.remoteDescription && peerConnection.remoteDescription.type)) {
      if (!this.remoteIceCandidates[device.device_signature]) {
        this.remoteIceCandidates[device.device_signature] = [];
      }
      this.remoteIceCandidates[device.device_signature].push(iceCandidate);
    } else {
      logger.debug(`adding ice candidates for`, device.device_signature);
      peerConnection.addIceCandidate(iceCandidate);
    }
  }
}

export default (apolloClient) => {
  return new WebRTCShare(apolloClient);
};
