import { useContext } from "react";
import { ClassActionContext } from "./ClassActionsProvider";

export const useStartClass = () => {
  const { classAction_startClass } = useContext(ClassActionContext);

  const startClass = ({ classId, screenShareMode, duration, onSuccess, onFailure, onCancel, className }) => {
    classAction_startClass({
      classId,
      screenShareMode,
      duration,
      onSuccess,
      onFailure,
      onCancel,
      className,
    });
  };

  return [startClass];
};
