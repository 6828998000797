import "./style.css";
import { MGHelperText } from "@/components/typography";
import PropTypes from "prop-types";
import { ErrorMessage } from "formik";
import MGRadioGroup from "./MGRadioGroup";
import { MGField } from "@/components/inputs";

export const MGRadioGroupField = ({ name, children, asList, inline }) => {
  return (
    <MGField name={name} inline={inline}>
      {({ field, form }) => {
        const onChangeHandler = (evt) => {
          form.setFieldTouched(field.name, true); //This can be removed later
          form.setFieldValue(field.name, evt);
        };
        return (
          <>
            <MGRadioGroup onChange={onChangeHandler} asList={asList}>
              {children}
            </MGRadioGroup>
            <ErrorMessage component={MGHelperText} name={name} />
          </>
        );
      }}
    </MGField>
  );
};

MGRadioGroupField.propTypes = {
  name: PropTypes.string,
  children: PropTypes.any,
  asList: PropTypes.bool,
  inline: PropTypes.bool,
};

MGRadioGroupField.defaultProps = {};

export default MGRadioGroupField;
