import { useCallback } from "react";
import "./style.css";
import { useState } from "react";
import { MGIcon } from "@/components/icons";
import { useEffect } from "react";
import { MGIconButton } from "@/components/buttons";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useMGTranslate } from "@/components/translations/useMGTranslate";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

export const MGDatePickerControl = ({ value, unbordered, minDate, maxDate, onChange }) => {
  const [currentDate, setCurrentDate] = useState(value ?? new Date());
  const [currentYear, setCurrentYear] = useState(currentDate.getFullYear());
  const [currentMonth, setCurrentMonth] = useState(currentDate.getMonth());
  const [currentDay, setCurrentDay] = useState(currentDate.getDate());
  const [showYears, setShowYears] = useState(false);
  const [startYear, setStartYear] = useState(currentYear);
  const [currentYearRange, setCurrentYearRange] = useState([]);

  const [t] = useMGTranslate();

  useEffect(() => {
    //logger.log(value);
    if (!currentYearRange || currentYearRange.length == 0) {
      getYearRange();
    }
  });

  function getDaysInMonth(month, year) {
    let days = new Date(year, month + 1, 0).getDate();

    let daysArray = [];
    for (let index = 1; index <= days; index++) {
      daysArray.push(index);
    }
    return daysArray;
  }

  function getDate(day, month, year) {
    return new Date(year ?? currentYear, month ?? currentMonth, day ?? currentDay, 0, 0, 0, 0);
  }

  // const logDate = (val) =>{
  //   const userLocale = globalThis.navigator.language;
  //   logger.log("Picker says rawdate: ", val)
  //   logger.log("Picker says formatted: ",val.toLocaleDateString(userLocale));
  // }

  const isToday = (day) => {
    let today = new Date();
    let evalDate = getDate(day);
    if (
      today.getDate() === evalDate.getDate() &&
      today.getMonth() === evalDate.getMonth() &&
      today.getFullYear() === evalDate.getFullYear()
    ) {
      return true;
    } else {
      return false;
    }
  };

  function isSelected(day) {
    if (value) {
      let evalDate = getDate(day);
      let selectedDate = value;

      if (
        selectedDate.getDate() === evalDate.getDate() &&
        selectedDate.getMonth() === evalDate.getMonth() &&
        selectedDate.getFullYear() === evalDate.getFullYear()
      ) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  function getMonthOffset() {
    let offset = getDate(1).getDay();
    let offsets = [];
    for (let index = 0; index < offset; index++) {
      offsets.push(index);
    }
    return offsets;
  }

  function getYearRange(year) {
    setCurrentYearRange([]);
    let start = year ?? 0;

    let prevYears = 6;
    let size = 24 - prevYears;
    let years = [];
    if (start == 0) {
      start = currentYear - prevYears;
    }

    setStartYear(start);
    for (let index = start; index < start + size + prevYears; index++) {
      years.push(index);
    }

    setCurrentYearRange(years);
  }

  function changeMonth(month) {
    if (month > 11) {
      setCurrentMonth(0);
      setCurrentYear(currentYear + 1);
    } else if (month < 0) {
      setCurrentMonth(11);
      setCurrentYear(currentYear - 1);
    } else {
      setCurrentMonth(month);
    }

    // this.daysInMonth = this.getDaysInMonth(this.currentMonth, this.currentYear);
  }

  const yearClick = useCallback((year) => {
    setCurrentYear(year);
    yearsToggleHandler();
  });

  const yearsToggleHandler = useCallback(() => {
    setShowYears(!showYears);
  }, [showYears]);

  const dayClickHandler = useCallback((evt) => {
    let day = Number.parseInt(evt.target.attributes["data-day"].value);
    if (day) {
      setCurrentDay(day);

      let newCurrentDate = getDate(day);
      //logDate(newCurrentDate);
      setCurrentDate(newCurrentDate);
      if (typeof onChange === "function") onChange(newCurrentDate);
    }
  });

  const isDisabled = (day) => {
    const minimumDate = new Date(minDate);
    const maximumDate = new Date(maxDate);

    const currentDay = new Date(currentYear, currentMonth, day);

    if (maximumDate && maximumDate < currentDay) return true;

    if (minimumDate && minimumDate > currentDay) return true;

    return false;
  };

  return (
    <>
      <div className={`--mg-date-picker-control ${classNames({ "--mg-date-picker-control-unbordered": unbordered })}`}>
        <div className="--mg-date-picker-control__header">
          <div className="--mg-date-picker-control__month-year">
            <div onClick={yearsToggleHandler} className="--mg-date-picker-control__selected_month">
              {t(months[currentMonth])} {currentYear}
              <MGIcon icon={showYears ? "arrow_up" : "arrow_down"}></MGIcon>
            </div>
          </div>
          <div className="--mg-date-picker-control__actions">
            <MGIconButton
              icon="previous"
              onClick={() => {
                !showYears ? changeMonth(currentMonth - 1) : getYearRange(startYear - 24);
              }}
            ></MGIconButton>
            <MGIconButton
              icon="next"
              onClick={() => {
                !showYears ? changeMonth(currentMonth + 1) : getYearRange(startYear + 24);
              }}
            ></MGIconButton>
          </div>
        </div>
        {!showYears && (
          <>
            <div className="--mg-date-picker-control__weekdays">
              {weekdays.map((weekday) => (
                <div className="--mg-date-picker-control__weekday_name" key={weekday}>
                  {t(weekday).substring(0, 2)}
                </div>
              ))}
            </div>
            <div className="--mg-date-picker-control__days">
              {getMonthOffset().map((offset) => (
                <div className="--mg-date-picker-control__day" key={offset}>
                  <div></div>
                </div>
              ))}
              {getDaysInMonth(currentMonth, currentYear).map((day) => {
                const dayIsDisabled = isDisabled(day);

                return (
                  <div className="--mg-date-picker-control__day" key={day}>
                    <div
                      // onClick={ (dayIsDisabled) ? dayClickHandler : null}
                      onClick={dayClickHandler}
                      data-day={day}
                      className={`
                      ${classNames({
                        active: isSelected(day),
                        today: isToday(day),
                        disabled: dayIsDisabled,
                      })}`}
                    >
                      {day}
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}
        {showYears && (
          <div className="--mg-date-picker-control__years">
            {currentYearRange.map((year) => (
              <div className="--mg-date-picker-control__year" key={year}>
                <div onClick={() => yearClick(year)} className={year == currentYear ? "active" : ""}>
                  {year}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

MGDatePickerControl.propTypes = {
  value: PropTypes.instanceOf(Date),
  unbordered: PropTypes.bool,
  onChange: PropTypes.func,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
};

MGDatePickerControl.defaultProps = {};

export default MGDatePickerControl;
