import { gql } from "@apollo/client";

export const MUTATION_DEVICE_ACTION_REFRESH = gql`
  mutation deviceActionRefresh($input: DeviceActionRefreshInput!) {
    deviceActionRefresh(input: $input) {
      data {
        handled
      }
      errors {
        ... on NotQuiteSureProblem {
          message
        }
        ... on Problem {
          message
        }
      }
    }
  }
`;

export const MUTATION_DEVICE_ACTION_PUSH_MG_MANAGED_APP = gql`
  mutation deviceActionPushMGManagedApp($input: DeviceActionPushMGManagedAppInput!) {
    deviceActionPushMGManagedApp(input: $input) {
      data {
        handled
      }
      errors {
        ... on NotQuiteSureProblem {
          message
        }
        ... on Problem {
          message
        }
      }
    }
  }
`;

export const MUTATION_DEVICE_ACTION_APP_REFRESH = gql`
  mutation deviceActionAppRefresh($input: DeviceActionAppRefreshInput!) {
    deviceActionAppRefresh(input: $input) {
      data {
        handled
      }
      errors {
        ... on NotQuiteSureProblem {
          message
        }
        ... on Problem {
          message
        }
      }
    }
  }
`;

export const MUTATION_DEVICE_ACTION_APP_SYNC = gql`
  mutation deviceActionAppSync($input: DeviceActionAppSyncInput!) {
    deviceActionAppSync(input: $input) {
      data {
        handled
      }
      errors {
        ... on NotQuiteSureProblem {
          message
        }
        ... on Problem {
          message
        }
      }
    }
  }
`;

export const DEVICE_ACTION_EYES_UP_MUTATION = gql`
  mutation deviceActionEyesUp($input: DeviceActionEyesUpInput!) {
    deviceActionEyesUp(input: $input) {
      __typename
      ... on DeviceActionEyesUpPayload {
        data {
          handled
        }
        errors {
          ... on NotQuiteSureProblem {
            message
          }
          ... on Problem {
            message
          }
        }
      }
    }
  }
`;

export const MUTATION_DEVICE_ACTION_SEND_MESSAGE = gql`
  mutation deviceActionSendMessage($input: DevicesActionSendMessageInput!) {
    deviceActionSendMessage(input: $input) {
      __typename
      ... on DeviceActionSendMessagePayload {
        data {
          handled
        }
        errors {
          ... on NotQuiteSureProblem {
            message
          }
          ... on Problem {
            message
          }
        }
      }
    }
  }
`;

export const MUTATION_CLASS_DEVICE_ACTION_PUSH_GOOGLE_DRIVE_CONTENT = gql`
  mutation classDeviceActionPushGoogleDriveContent($input: ClassDeviceActionPushGoogleDriveContentInput!) {
    classDeviceActionPushGoogleDriveContent(input: $input) {
      data {
        session_id
        file_id
        file_name
        mime_type
        view_url

        has_public_access
        granted_public_access
        updated_at
        created_at
      }
      errors {
        __typename
        ... on NotQuiteSureProblem {
          message
        }
        ... on Problem {
          message
        }
      }
    }
  }
`;

export const MUTATION_DEVICE_ACTION_PUSH_CONTENT = gql`
  mutation classDeviceActionPushContent($input: DevicesPushContentWebsiteInput!) {
    classDeviceActionPushContent(input: $input) {
      __typename
      ... on ContentListConnection {
        edges {
          node {
            handled
          }
        }
        #errors {
        #  ... on NotQuiteSureProblem {
        #    message
        #  }
        #  ... on Problem {
        #    message
        #  }
        #}
      }
    }
  }
`;

export const MUTATION_DEVICE_ACTION_SEND_TAB_CONTROL_COMMAND = gql`
  mutation deviceActionSendTabControlCommand($input: DeviceActionSendTabControlCommandInput!) {
    deviceActionSendTabControlCommand(input: $input) {
      __typename
      ... on DeviceActionSendTabControlCommandPayload {
        data {
          handled
        }
        errors {
          ... on NotQuiteSureProblem {
            message
          }
          ... on Problem {
            message
          }
        }
      }
    }
  }
`;

export const MUTATION_DEVICE_ACTION_ALARM = gql`
  mutation deviceActionAlarm($input: DeviceActionAlarmInput!) {
    deviceActionAlarm(input: $input) {
      data {
        handled
      }
      errors {
        ... on NotQuiteSureProblem {
          message
        }
        ... on Problem {
          message
        }
      }
    }
  }
`;

export const MUTATION_DEVICE_ACTION_SHARE_SCREEN = gql`
  mutation deviceActionShareScreen($input: DeviceActionShareScreenInput!) {
    deviceActionShareScreen(input: $input) {
      data {
        handled
      }
      errors {
        __typename
        ... on NotQuiteSureProblem {
          message
        }
        ... on Problem {
          message
        }
      }
    }
  }
`;

export const MUTATION_DEVICE_ACTION_SHARE_TEACHER_SCREEN = gql`
  mutation deviceActionShareTeacherScreen($input: DeviceActionShareTeacherScreenInput!) {
    deviceActionShareTeacherScreen(input: $input) {
      data {
        handled
      }
      errors {
        __typename
        ... on NotQuiteSureProblem {
          message
        }
        ... on Problem {
          message
        }
      }
    }
  }
`;

export const MUTATION_DEVICE_ACTION_LOCK = gql`
  mutation deviceActionLock($input: DeviceActionLockInput!) {
    deviceActionLock(input: $input) {
      data {
        handled
      }
      errors {
        ... on NotQuiteSureProblem {
          message
        }
        ... on Problem {
          message
        }
      }
    }
  }
`;

export const MUTATION_DEVICE_ACTION_LOCK_WITH_PIN = gql`
  mutation deviceActionLockWithPin($input: DeviceActionLockWithPinInput!) {
    deviceActionLockWithPin(input: $input) {
      data {
        handled
      }
      errors {
        ... on NotQuiteSureProblem {
          message
        }
        ... on Problem {
          message
        }
      }
    }
  }
`;

export const MUTATION_DEVIC_ACTION_CLEAR_PASSCODE = gql`
  mutation deviceActionClearPasscode($input: DeviceActionClearPasscodeInput!) {
    deviceActionClearPasscode(input: $input) {
      data {
        handled
      }
      errors {
        ... on NotQuiteSureProblem {
          message
        }
        ... on Problem {
          message
        }
      }
    }
  }
`;

export const MUTATION_DEVICE_ACTION_FLUSH_MDM_MESSAGES = gql`
  mutation deviceActionFlushMDMMessages($input: DeviceActionFlushMDMMessagesInput!) {
    deviceActionFlushMDMMessages(input: $input) {
      data {
        handled
      }
      errors {
        ... on NotQuiteSureProblem {
          message
        }
        ... on Problem {
          message
        }
      }
    }
  }
`;

export const MUTATION_DEVICE_ACTION_PUSH_WEBFILTER = gql`
  mutation deviceActionPushWebfilter($input: DeviceActionPushWebfilterInput!) {
    deviceActionPushWebfilter(input: $input) {
      data {
        handled
      }
      errors {
        ... on NotQuiteSureProblem {
          message
        }
        ... on Problem {
          message
        }
      }
    }
  }
`;

export const MUTATION_DEVICE_ACTION_REMOVE_WEBFILTER = gql`
  mutation deviceActionRemoveWebfilter($input: DeviceActionRemoveWebfilterInput!) {
    deviceActionRemoveWebfilter(input: $input) {
      data {
        handled
      }
      errors {
        ... on NotQuiteSureProblem {
          message
        }
        ... on Problem {
          message
        }
      }
    }
  }
`;

export const MUTATION_DEVICE_ACTION_ENABLE_LOST_MODE = gql`
  mutation deviceActionEnableLostMode($input: DeviceActionEnableLostModeInput!) {
    deviceActionEnableLostMode(input: $input) {
      data {
        handled
      }
      errors {
        ... on NotQuiteSureProblem {
          message
        }
        ... on Problem {
          message
        }
      }
    }
  }
`;

export const MUTATION_DEVICE_ACTION_DISABLE_LOST_MODE = gql`
  mutation deviceActionDisableLostMode($input: DeviceActionDisableLostModeInput!) {
    deviceActionDisableLostMode(input: $input) {
      data {
        handled
      }
      errors {
        ... on NotQuiteSureProblem {
          message
        }
        ... on Problem {
          message
        }
      }
    }
  }
`;

export const MUTATION_DEVICE_ACTION_LOCATE_LOST = gql`
  mutation deviceActionLocateLost($input: DeviceActionLocateLostInput!) {
    deviceActionLocateLost(input: $input) {
      data {
        handled
      }
      errors {
        ... on NotQuiteSureProblem {
          message
        }
        ... on Problem {
          message
        }
      }
    }
  }
`;

export const MUTATION_DEVICE_ACTION_KICK_MDM = gql`
  mutation deviceActionKickMDM($input: DeviceActionKickMDMInput!) {
    deviceActionKickMDM(input: $input) {
      data {
        handled
      }
      errors {
        ... on NotQuiteSureProblem {
          message
        }
        ... on Problem {
          message
        }
      }
    }
  }
`;

export const MUTATION_DEVICE_ACTION_RESTART = gql`
  mutation deviceActionRestart($input: DeviceActionRestartInput!) {
    deviceActionRestart(input: $input) {
      data {
        handled
      }
      errors {
        ... on NotQuiteSureProblem {
          message
        }
        ... on Problem {
          message
        }
      }
    }
  }
`;

export const MUTATION_DEVICE_ACTION_SHUTDOWN = gql`
  mutation deviceActionShutdown($input: DeviceActionShutdownInput!) {
    deviceActionShutdown(input: $input) {
      data {
        handled
      }
      errors {
        ... on NotQuiteSureProblem {
          message
        }
        ... on Problem {
          message
        }
      }
    }
  }
`;

export const MUTATION_DEVICE_ACTION_STOP_SINGLE_APP_MODE = gql`
  mutation deviceActionStopSingleAppMode($input: DeviceActionStopSingleAppModeInput!) {
    deviceActionStopSingleAppMode(input: $input) {
      data {
        handled
      }
      errors {
        ... on NotQuiteSureProblem {
          message
        }
        ... on Problem {
          message
        }
      }
    }
  }
`;

export const MUTATION_DEVICE_ACTION_CLEAR_PASSCODE = gql`
  mutation deviceActionClearPasscode($input: DeviceActionClearPasscodeInput!) {
    deviceActionClearPasscode(input: $input) {
      data {
        handled
      }
      errors {
        ... on NotQuiteSureProblem {
          message
        }
        ... on Problem {
          message
        }
      }
    }
  }
`;

export const MUTATION_DEVICE_ACTION_VACATION_MODE = gql`
  mutation deviceVacationMode($input: DeviceActionVacationModeInput!) {
    deviceVacationMode(input: $input) {
      data {
        handled
      }
      errors {
        ... on NotQuiteSureProblem {
          message
        }
        ... on Problem {
          message
        }
      }
    }
  }
`;

export const MUTATION_DEVICE_ACTION_DESTROY = gql`
  mutation deviceActionDestroy($input: DeviceActionDestroyInput!) {
    deviceActionDestroy(input: $input) {
      data {
        result
      }
      errors {
        ... on NotQuiteSureProblem {
          message
        }
        ... on Problem {
          message
        }
      }
    }
  }
`;

export const MUTATION_DEVICE_ACTION_MASS_ASSIGN_TAGS = gql`
  mutation massAssignTagsDevice($input: TagsDeviceMassAssignInput!) {
    massAssignTagsDevice(input: $input) {
      data {
        result
      }
      errors {
        __typename
        ... on NotQuiteSureProblem {
          message
        }
        ... on Problem {
          message
        }
      }
    }
  }
`;

export const MUTATION_DEVICE_ACTION_MASS_UNASSIGN_TAGS = gql`
  mutation massUnAssignTagsDevice($input: TagsDeviceMassUnassignInput!) {
    massUnAssignTagsDevice(input: $input) {
      data {
        result
      }
      errors {
        __typename
        ... on NotQuiteSureProblem {
          message
        }
        ... on Problem {
          message
        }
      }
    }
  }
`;

export const MUTATION_DEVICE_ACTION_START_MIRROR = gql`
  mutation deviceActionMirror($input: DeviceActionMirrorInput!) {
    deviceActionMirror(input: $input) {
      result
      errors {
        ... on NotQuiteSureProblem {
          message
        }
        ... on Problem {
          message
        }
      }
    }
  }
`;

export const MUTATION_DEVICE_ACTION_STOP_MIRROR = gql`
  mutation deviceActionStopMirror($input: DeviceActionStopMirrorInput!) {
    deviceActionStopMirror(input: $input) {
      result
      errors {
        ... on NotQuiteSureProblem {
          message
        }
        ... on Problem {
          message
        }
      }
    }
  }
`;

export const MUTATION_DEVICE_ACTION_BLUETOOTH = gql`
  mutation deviceActionBluetooth($input: DeviceActionBluetoothInput!) {
    deviceActionBluetooth(input: $input) {
      data {
        handled
      }
      errors {
        ... on NotQuiteSureProblem {
          message
        }
        ... on Problem {
          message
        }
      }
    }
  }
`;

export const MUTATION_DEVICE_ACTION_SINGLE_APP_MODE = gql`
  mutation deviceActionSingleAppMode($input: DeviceActionSingleAppModeInput!) {
    deviceActionSingleAppMode(input: $input) {
      data {
        handled
      }
      errors {
        ... on NotQuiteSureProblem {
          message
        }
        ... on Problem {
          message
        }
      }
    }
  }
`;
