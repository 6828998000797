import { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import "./style.css";

import { MGIcon } from "@/components/icons";
import { MGInput, MGInputsOverlay, MGInputWrapper } from "@/components/inputs";
import MGTimePickerControl from "../MGTimePickerControl";

export const MGTimePicker = ({ value, onChange, disabled, formikField, formikMeta, ...rest }) => {
  const { showAMPM } = rest;
  const formatTime = (val) => {
    let valCopy = Object.assign({}, val);
    if (!val || Object.keys(val).length === 0) {
      valCopy = MGTimePicker.defaultProps.value;
    }

    let hour = leadingZeroFormatter(valCopy.hours);
    let min = leadingZeroFormatter(valCopy.minutes);
    // logger.log(hour, min);

    // logger.log(MGTimePicker.defaultProps.value);
    let displayString = hour + ":" + min;
    if (showAMPM) {
      displayString += " " + valCopy.am_pm.toUpperCase();
    }
    return displayString;
  };

  const leadingZeroFormatter = (hoursOrMinutes) => {
    let time = "0" + hoursOrMinutes?.toString();
    if (time.length > 2) {
      time = time.toString().substring(1, 3);
    }
    return time;
  };

  const convertDateToTime = (inputDate) => {
    let date = new Date(inputDate);

    let timeObj = {
      hours: date.getHours() > 12 ? date.getHours() - 12 : date.getHours(),
      minutes: date.getMinutes(),
      am_pm: date.getHours() > 12 ? "PM" : "AM",
    };

    return timeObj;
  };

  const convertTimeToDate = (inputTime) => {
    //logger.log(inputTime);
    let date = new Date();
    let newHours = inputTime.hours;
    if (inputTime.am_pm == "PM") newHours = newHours + 12;
    date.setHours(newHours);
    date.setMinutes(inputTime.minutes);

    return date;
  };

  const [isOpen, setIsOpen] = useState(false);
  const [displayVal, setDisplayVal] = useState(formatTime(convertDateToTime(value)));

  const trigger = useRef();

  useEffect(() => {
    if (value) {
      setDisplayVal(formatTime(convertDateToTime(value)));
    }
  }, [value]);

  const toggle = useCallback(() => {
    if (!disabled) setIsOpen(!isOpen);
  });

  const onChangeHandler = (evt) => {
    if (typeof onChange === "function") onChange(convertTimeToDate(evt));
    setDisplayVal(formatTime(evt));
  };

  return (
    // <div className={`--mg-time-picker`} ref={trigger}>
    <div ref={trigger} className="--mg-time-picker__input">
      <MGInputWrapper onClick={toggle} {...{ ...formikMeta }} disabled={disabled}>
        <MGIcon icon="time" size={16}></MGIcon>
        <MGInput {...formikField} type="text" value={displayVal} readOnly></MGInput>
      </MGInputWrapper>

      {isOpen && (
        <MGInputsOverlay trigger={trigger} onOutsideClick={toggle}>
          <MGTimePickerControl
            unbordered
            time={convertDateToTime(value)}
            onChange={onChangeHandler}
            {...rest}
          ></MGTimePickerControl>
        </MGInputsOverlay>
      )}
    </div>
  );
};

MGTimePicker.propTypes = {
  value: PropTypes.shape({
    hours: PropTypes.number,
    minutes: PropTypes.number,
    am_pm: PropTypes.string,
  }),
  onChange: PropTypes.func,
  formikField: PropTypes.any,
  formikMeta: PropTypes.any,
};

MGTimePicker.defaultProps = {
  value: {
    hours: Number.parseInt(new Date().getHours()),
    minutes: Number.parseInt(new Date().getMinutes()),
    am_pm: new Date().getHours() > 12 ? "PM" : "AM",
  },
};

export default MGTimePicker;
