import "./style.css";

export const MGLabel = ({ children, disabled }) => {
  return <label className={`--mg-label ${disabled ? "--mg-label__disabled" : ""} `}>{children}</label>;
};

MGLabel.propTypes = {};

MGLabel.defaultProps = {};

export default MGLabel;
