import "./style.css";
import classNames from "classnames";

export const MGRadio = ({ children, disabled, name, value, checked, onChange }) => {
  function onChangeHandler(event) {
    if (typeof onChange == "function") {
      onChange(event);
    }
  }
  return (
    <span className={`--mg-radio-item ${classNames({ disabled: disabled })}`}>
      {children}
      <input type="radio" name={name} value={value} checked={checked} disabled={disabled} onChange={onChangeHandler} />
      <span className={`--mg-radio-item-checkmark ${disabled ? "disabled" : ""}`}></span>
    </span>
  );
};

MGRadio.propTypes = {};

MGRadio.defaultProps = {};

export default MGRadio;
