import ConfigureClassForm from "@/app/features/plan/pages/class/components/ConfigureClassForm";
import useAddClassModal from "./pageState";

export const AddClassModalContainer = ({ isShowing, onSuccess, onFailure, onCancel }) => {
  const { configureClassFormProps } = useAddClassModal({
    isShowing,
    onSuccess,
    onFailure,
    onCancel,
  });

  return (
    <>
      <ConfigureClassForm {...configureClassFormProps} />
    </>
  );
};

export default AddClassModalContainer;
