import "./style.css";

export const MGCardContent = ({ children }) => {
  return <div className="card__card-content">{children}</div>;
};

MGCardContent.propTypes = {};

MGCardContent.defaultProps = {};

export default MGCardContent;
