import { useState } from "react";
import PropTypes from "prop-types";
import "./style.css";
import MGInputWrapper from "../BuildingBlocks/MGInputWrapper";
import MGInput from "../BuildingBlocks/MGInput";
import MGInputButtons from "../BuildingBlocks/MGInputButtons";
import { MGIconButton } from "@/components/buttons";
import { MGTag } from "@/components/tag";

export const MGTagInput = ({ placeholder, value, onChange, tagValidator, formikField, formikMeta }) => {
  const [text, setText] = useState("");
  const [tagList, setTagList] = useState(value ? value.slice() : []);

  function onChangeHandler(data) {
    if (typeof onChange == "function") {
      onChange(data);
    }
  }

  const removeTagHandler = (tag) => {
    let tempTagList = tagList.slice();
    tempTagList.splice(tempTagList.indexOf(tag), 1);
    setTagList(tempTagList);
    onChangeHandler(tempTagList);
  };

  const tagIsValid = () => {
    if (text != "") {
      if (typeof tagValidator === "function") {
        if (tagValidator(text)) return true;
        else return false;
      } else {
        return true;
      }
    }
    return false;
  };

  const addTagHandler = () => {
    if (tagIsValid()) {
      let tempTagList = tagList.slice();
      tempTagList.push(text);
      setTagList(tempTagList);
      setText("");
      onChangeHandler(tempTagList);
    }
  };

  const popTagList = () => {
    let tempTagList = tagList.slice();
    tempTagList.pop();
    setTagList(tempTagList);
    onChangeHandler(tempTagList);
  };

  return (
    <span className="--mg-tag-input">
      <MGInputWrapper {...{ ...formikMeta }}>
        <span className="--mg-tag-input-outer">
          {tagList && (
            <span className="--mg-tag-container">
              {tagList.length > 0 &&
                tagList.map((tag, index) => (
                  <MGTag icon="close" key={index} onclick={() => removeTagHandler(tag)}>
                    {tag}
                  </MGTag>
                ))}
            </span>
          )}
          <span className="--mg-tag-input-spacer">
            <MGInput
              {...formikField}
              type="text"
              value={text}
              onChange={(e) => setText(e.target.value)}
              placeholder={tagList && !tagList.length > 0 ? placeholder : ""}
              onKeyDown={(evt) => {
                if (evt.key === "Enter") {
                  addTagHandler();
                  evt.preventDefault();
                }
                if (evt.key === "Backspace" && text === "") {
                  popTagList();
                  //evt.preventDefault();
                }
              }}
            ></MGInput>
          </span>
        </span>
        <MGInputButtons>
          <MGIconButton variant="primary" tabindex="-1" icon="add" onClick={addTagHandler}></MGIconButton>
        </MGInputButtons>
      </MGInputWrapper>
    </span>
  );
};

MGTagInput.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  tagValidator: PropTypes.func,
  formikField: PropTypes.any,
  formikMeta: PropTypes.any,
};

MGTagInput.defaultProps = {
  tagValidator: () => {
    return true;
  },
};

export default MGTagInput;
