import "./style.css";
import MGInputList from "./MGInputList";
import { ErrorMessage } from "formik";
import { MGHelperText } from "@/components/typography";
import PropTypes from "prop-types";
import MGField from "../MGField";

export const MGInputListField = ({ name, addItem, displayExpr, removable, onChange, inline, onItemRemoved }) => {
  return (
    <MGField name={name} inline={inline}>
      {({ field, form }) => {
        const onChangeHandler = (evt) => {
          form.setFieldValue(field.name, evt);
          if (typeof onChange === "function") onChange(evt);
        };

        const onItemRemovedHandler = (item) => {
          if (typeof onItemRemoved === "function") onItemRemoved(item);
        };

        const addItemToList = (item) => {
          if (typeof addItem == "function") {
            addItem(item);
          }

          form.setFieldValue(field.name, field.value.push(item));
        };

        return (
          <>
            <MGInputList
              removable={removable}
              value={field.value}
              formikField={field}
              displayExpr={displayExpr}
              onChange={onChangeHandler}
              addItem={addItemToList}
              onItemRemoved={onItemRemovedHandler}
            ></MGInputList>
            <ErrorMessage component={MGHelperText} name={name} />
          </>
        );
      }}
    </MGField>
  );
};

MGInputListField.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  removable: PropTypes.bool,
  onChange: PropTypes.func,
  inline: PropTypes.bool,
  addItem: PropTypes.func,
  displayExpr: PropTypes.func,
};

MGInputListField.defaultProps = {};

export default MGInputListField;
