import ConfigureClassForm from "@/app/features/plan/pages/class/components/ConfigureClassForm";
import useEditClassModal from "./pageState";

export const EditClassModalContainer = ({ classId, onSuccess, onFailure, onCancel }) => {
  const { configureClassFormProps } = useEditClassModal({
    classId,
    onSuccess,
    onFailure,
    onCancel,
  });

  return (
    <>
      <ConfigureClassForm {...configureClassFormProps} />
    </>
  );
};

export default EditClassModalContainer;
