import { useEffect, useRef, useState } from "react";
import { useCombobox } from "downshift";
import MGInputWrapper from "../BuildingBlocks/MGInputWrapper";
import MGInputButtons from "../BuildingBlocks/MGInputButtons";
import { MGProgressSpinner } from "@/components/progress";
import MGDropdownItem from "../MGDropdownItem";
import MGInput from "../BuildingBlocks/MGInput";
import PropTypes from "prop-types";
import "./style.css";
import { MGIconButton } from "@/components/buttons";

export const MGAutocomplete = ({
  value,
  suggestions,
  placeholder,
  onChange,
  isLoading,
  actionButtonIcon,
  onActionButtonClick,
  enterKeyFiresAction,
  formikField,
  formikMeta,
  filter,
  suggestionRenderer,
  displayProp,
  onItemSelected,
  clearable,
}) => {
  const hasCustomFilter = () => {
    return filter && typeof filter === "function";
  };

  const [inputItems, setInputItems] = useState(hasCustomFilter() ? null : suggestions);
  const [selectedItem, setSelectedItem] = useState(null);
  const box = useRef();

  useEffect(() => {
    if (!hasCustomFilter()) {
      setInputItems(defaultFilter(value));
    } else {
      setInputItems(filter(value));
    }
  }, [suggestions, value]);

  const defaultFilter = (inputValue) =>
    suggestions?.filter((item) => {
      if (typeof item === "string") {
        return item?.toLowerCase().startsWith(inputValue?.toLowerCase());
      }
      return item?.[displayProp]?.toLowerCase().startsWith(inputValue?.toLowerCase());
    });

  const onChangeHandler = (evt) => {
    if (typeof onChange === "function") onChange(evt);
    formikField?.onChange(evt);
  };

  const onActionButtonClickHandler = (val) => {
    if (typeof onActionButtonClick == "function") onActionButtonClick();

    //After action button function is called, reset suggestions
    let newSuggestions = suggestions;
    newSuggestions.splice(suggestions.indexOf(val), 1);
    setInputItems(hasCustomFilter() ? null : newSuggestions);
  };

  const onInputPropsChange = (evt) => {
    onChangeHandler(evt.target.value);
  };

  const {
    isOpen,
    getMenuProps,
    getInputProps,
    getToggleButtonProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    selectItem,
  } = useCombobox({
    items: inputItems ?? suggestions,
    onInputValueChange: ({ inputValue }) => {
      if (!hasCustomFilter()) {
        setInputItems(defaultFilter(inputValue));
      }
      onChangeHandler(inputValue);
    },
    selectedItem,
    onSelectedItemChange: ({ selectedItem: newSelectedItem }) => {
      if (typeof onItemSelected === "function") {
        onItemSelected(newSelectedItem);
      }
      setSelectedItem(newSelectedItem?.[displayProp] ?? newSelectedItem);
    },
  });

  return (
    <div className="mg-autocomplete-control" {...getComboboxProps()}>
      <MGInputWrapper {...{ ...formikMeta }} ref={box}>
        <MGInput
          {...formikField}
          {...getInputProps({
            onBlur: formikField?.onBlur,
            value: formikField?.value,
            onChange: onInputPropsChange,
            onKeyUp: (evt) => {
              if (evt.key === "Enter" && enterKeyFiresAction && typeof onActionButtonClick === "function") {
                onActionButtonClickHandler(evt.target.value);
              }
            },
          })}
          onClick={getToggleButtonProps().onClick}
          value={value ?? ""}
          placeholder={placeholder}
        />

        <MGInputButtons>
          {isLoading && <MGProgressSpinner size="16" />}
          {actionButtonIcon && (
            <MGIconButton
              variant={"primary"}
              icon={actionButtonIcon}
              onClick={() => {
                onActionButtonClickHandler(value);
              }}
            />
          )}
          {clearable && value != null && value != "" && (
            <MGIconButton
              variant="primary"
              icon={"close"}
              onClick={() => {
                selectItem(null);
                onChangeHandler("");
              }}
            />
          )}
          <MGIconButton
            variant="primary"
            icon={isOpen && inputItems?.length > 0 ? "arrow_up" : "arrow_down"}
            {...getToggleButtonProps()}
          />
        </MGInputButtons>
      </MGInputWrapper>

      <div
        className="mg-autocomplete-scroll-preventer"
        style={{
          display: isOpen && inputItems?.length > 0 ? "block" : "none",
        }}
      >
        <div
          className="mg-autocomplete-suggestions"
          style={{
            display: isOpen && inputItems?.length > 0 ? "" : "none",
            width: box?.current?.getBoundingClientRect().width,
            top: (box?.current?.getBoundingClientRect().top ?? 0) + (box?.current?.getBoundingClientRect().height ?? 0),
            left: box?.current?.getBoundingClientRect().left ?? 0,
          }}
          {...getMenuProps()}
        >
          {isOpen &&
            (inputItems ?? suggestions)?.map((item, index) => {
              return (
                <MGDropdownItem
                  key={`${item}${index}`}
                  {...getItemProps({ item, index })}
                  selected={index === highlightedIndex}
                >
                  {suggestionRenderer && typeof suggestionRenderer === "function" && suggestionRenderer(item)}
                  {!suggestionRenderer && typeof item === "object" && item?.[displayProp]}
                  {!suggestionRenderer && typeof item === "string" && item}
                </MGDropdownItem>
              );
            })}
        </div>
      </div>
    </div>
  );
};

MGAutocomplete.displayName = "MGAutocomplete";

MGAutocomplete.propTypes = {
  /**
   *  List of suggestions to appear as the user types
   */
  suggestions: PropTypes.arrayOf(PropTypes.any),
  /**
   *  Boolean to show loading icon on the right of the textbox
   */
  isLoading: PropTypes.bool,
  /**
   *  Text value of the input
   */
  value: PropTypes.string,
  /**
   *  Placeholder text for input
   */
  placeholder: PropTypes.string,
  /**
   * Function that returns new value of input as the user types in the input
   */
  onChange: PropTypes.func,
  /**
   *  Icon to be used in the button of the input
   */
  actionButtonIcon: PropTypes.string,
  /**
   *  Callback function to be fired when the icon button is clicked
   */
  onActionButtonClick: PropTypes.func,
  /**
   *  If true, will fire the onActionButtonClick function if user presses enter while focussed on the input
   */
  enterKeyFiresAction: PropTypes.bool,
  /**
   *  Formik field data, not to be used outside of a Formik Field tag
   */
  formikField: PropTypes.object,
  /**
   *  Formik field metadata object, not to be used outside of a Formik Field tag
   */
  formikMeta: PropTypes.object,
  /**
   * Filter which determines how suggestions are filtered
   */
  filter: PropTypes.func,
  /**
   * Text or Node to be displayed in the field
   */
  suggestionRenderer: PropTypes.func,
  /**
   * Property used to display in the input field once an Item is selected
   */
  displayProp: PropTypes.string,
  /**
   * Called when an item is selected from the list of available options
   */
  onItemSelected: PropTypes.func,
  /**
   * Determines if the input is clearable or not via a button
   */
  clearable: PropTypes.bool,
};

MGAutocomplete.defaultProps = {
  suggestions: [],
  isLoading: false,
  value: "",
  placeholder: "",
  //onChange: ()=>{},
  actionButtonIcon: null,
  //onActionButtonClick : ()=>{},
  enterKeyFiresAction: true,
  // formikField: {},
  // formikMeta: {},
};

export default MGAutocomplete;
