import classNames from "classnames";
import PropTypes from "prop-types";
import "./style.css";

export const MGModalBody = ({ children, center }) => {
  return <div className={`--mg-modal-body ${classNames({ "--mg-modal-body-center": center })}`}>{children}</div>;
};

MGModalBody.propTypes = {
  children: PropTypes.node,
  center: PropTypes.bool,
};

MGModalBody.defaultProps = {};

export default MGModalBody;
