import PropTypes from "prop-types";
import "./style.css";
import classNames from "classnames";
import { MGIcon } from "@/components/icons";

export const MGTextArea = ({ value, placeholder, success, onChange, formikField, formikMeta, width, height }) => {
  let { error, disabled, touched } = formikMeta;

  const onChangeHandler = (evt) => {
    let newValue = evt === "" ? "" : evt.target.value;
    if (typeof onChange == "function") {
      onChange(newValue);
    }
  };

  return (
    <div>
      <textarea
        {...formikField}
        value={value}
        onChange={onChangeHandler}
        placeholder={placeholder}
        className={`textarea-control ${classNames({
          disabled: disabled,
          error: error && touched,
          success: success,
        })}`}
        style={{
          width: width,
          height: height,
        }}
      ></textarea>
      <span className={`handlebar ${classNames({ disabled: disabled })}`}>
        <MGIcon icon="handle" className={`${classNames({ disabled: disabled })}`} size={15}></MGIcon>
      </span>
    </div>
  );
};

MGTextArea.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  success: PropTypes.bool,
  onChange: PropTypes.func,
  formikField: PropTypes.any,
  formikMeta: PropTypes.any,
};

MGTextArea.defaultProps = {
  formikMeta: {
    error: false,
    disabled: false,
    touched: false,
  },
  value: "",
};

export default MGTextArea;
