import "./style.css";

export const MGHeading1 = ({ children }) => {
  return <h1>{children}</h1>;
};

MGHeading1.propTypes = {};

MGHeading1.defaultProps = {};

export default MGHeading1;
