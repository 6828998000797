class PermissionConstants {
  static AccessRequestModule = "access_request_module";
  static BlockAllTraffic = "block_all_traffic";
  static BlockApplications = "block_applications"; // Check applications blocking with Aubrey. DONE
  static ConfigureBluetooth = "configure_bluetooth";
  static ConfigureForceJoin = "configure_force_join";
  static ConfigureYoutube = "configure_youtube";
  static DirectMessageStudents = "direct_message_students";
  static EditStudentProfile = "edit_student_profile";
  static ExportStudentInfo = "export_student_info";
  static ExportWebfilterReports = "export_web_filter_reports";

  static MirrorDevices = "mirror_devices"; // can we still mirror device on teacher dashboard, where?

  static ShareStudentScreen = "share_student_screen";
  static ShareTeachersScreen = "share_teachers_screen";
  static StartClassOutsideSchoolHours = "start_class_outside_school_hours"; // Is this something that gets sent to the backend?

  static StudentLocationData = "student_location_data"; // its not being used on the old dashboard?

  static StudentModule = "student_module";
  static SyncGoogleClasses = "sync_google_classes";
  static WebfilterDataModule = "web_filter_data_module";
  static WebfilterReportActions = "web_filter_report_actions";
  static WebfilterModule = "web_filtering_module";
}

export default PermissionConstants;
