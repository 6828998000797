import { createContext, useEffect, useState } from "react";
import AddClassModalContainer from "./AddClass/containers/AddClassModalContainer";
import DuplicateClassModalContainer from "./DuplicateClass/containers/DuplicateClassModalContainer";
import EditClassModalContainer from "./EditClass/containers/EditClassModalContainer";
import ClassEndModalContainer from "./EndClass/containers/ClassEndModalContainer";
import ClassStartModalContainer from "./StartClass/containers/ClassStartModalContainer";
import TransferStudentsModalContainer from "./TransferStudents/containers/TransferStudentsModalContainer";
import { logger } from "@/app/services/logging/Logger";

export const ClassActionContext = createContext(null);

export const ClassActionProvider = ({ children }) => {
  const [startClass_data, setStartClass_data] = useState(null);
  const [endClass_data, setEndClass_data] = useState(null);
  const [duplicateClass_data, setDuplicateClass_data] = useState(null);
  const [transferStudents_data, setTransferStudents_data] = useState(null);
  const [addClass_data, setAddClass_data] = useState(null);
  const [editClass_data, setEditClass_data] = useState(null);

  // This is not needed in main app. Issue only exists in Storybook because when changing stories, the wrapping
  useEffect(() => {
    return () => {};
  }, []);

  const classAction_startClass = ({
    classId,
    screenShareMode,
    duration,
    onSuccess,
    onFailure,
    onCancel,
    className,
  }) => {
    if (!classId) {
      logger.warn("ClassActionProvider: No classId provided, cannot perform Start Class operation");
      return;
    }

    const onClassStartFailureHandler = (data) => {
      setStartClass_data(null);
      if (typeof onFailure === "function") onFailure(data);
    };

    const onClassStartSuccessHandler = (data) => {
      setStartClass_data(null);
      if (typeof onSuccess === "function") onSuccess(data);
    };

    const onClassStartCancelHandler = () => {
      setStartClass_data(null);
      if (typeof onCancel === "function") onCancel();
    };

    const startClass_dataObj = {
      classId,
      onSuccess: onClassStartSuccessHandler,
      onCancel: onClassStartCancelHandler,
      onFailure: onClassStartFailureHandler,
      screenShareMode: screenShareMode,
      duration: duration,
      className: className,
    };

    setStartClass_data(startClass_dataObj);
  };

  const classAction_endClass = ({
    classId,
    className,
    sessionId,
    onEndClassSuccess,
    onEndClassFailure,
    onExtendClassSuccess,
    onExtendClassFailure,
    onCancel,
  }) => {
    if (!classId) {
      logger.warn("ClassActionProvider: No classId provided, cannot perform End Class operation");
      return;
    }

    if (!sessionId) {
      logger.warn("ClassActionProvider: No sessionId provided, cannot perform End Class operation");
      return;
    }

    const onEndClassSuccessHandler = (data) => {
      setEndClass_data(null);
      if (typeof onEndClassSuccess === "function") onEndClassSuccess(data);
    };

    const onEndClassFailureHandler = (data) => {
      setEndClass_data(null);
      if (typeof onEndClassFailure === "function") onEndClassFailure(data);
    };

    const onExtendClassSuccessHandler = (data) => {
      setEndClass_data(null);
      if (typeof onExtendClassSuccess === "function") onExtendClassSuccess(data);
    };

    const onExtendClassFailureHandler = (data) => {
      setEndClass_data(null);
      if (typeof onExtendClassFailure === "function") onExtendClassFailure(data);
    };

    const onCancelHandler = () => {
      setEndClass_data(null);
      if (typeof onCancel === "function") onCancel();
    };

    const endClass_dataObj = {
      classId,
      className,
      classSessionId: sessionId,
      onEndClassSuccess: onEndClassSuccessHandler,
      onEndClassFailure: onEndClassFailureHandler,
      onExtendClassSuccess: onExtendClassSuccessHandler,
      onExtendClassFailure: onExtendClassFailureHandler,
      onCancel: onCancelHandler,
    };

    setEndClass_data(endClass_dataObj);
  };

  const classAction_hideEndClassModal = () => {
    setEndClass_data(null);
  };

  const classAction_duplicateClass = ({ classId, className, onSuccess, onFailure, onCancel }) => {
    if (!classId) {
      logger.warn("ClassActionProvider: No classId provided, cannot perform Duplicate Class operation");
      return;
    }

    if (!className) {
      logger.warn("ClassActionProvider: No className provided, cannot perform Duplicate Class operation");
      return;
    }

    const onSuccessHandler = (data) => {
      setDuplicateClass_data(null);
      if (typeof onSuccess === "function") onSuccess(data);
    };

    const onFailureHandler = (data) => {
      //setDuplicateClass_data(null);         //Dont close modal on failure
      if (typeof onFailure === "function") onFailure(data);
    };

    const onCancelHandler = () => {
      setDuplicateClass_data(null);
      if (typeof onCancel === "function") onCancel();
    };

    const duplicateClass_dataObj = {
      classId,
      className,
      onSuccess: onSuccessHandler,
      onFailure: onFailureHandler,
      onCancel: onCancelHandler,
    };

    setDuplicateClass_data(duplicateClass_dataObj);
  };

  const classAction_transferStudents = ({ classId, device_uuids, onFailure, onSuccess, onCancel }) => {
    if (!classId) {
      logger.warn("ClassActionProvider: No classId provided, cannot perform Transfer Students operation");
      return;
    }

    if (device_uuids.length < 1) {
      return;
    }

    const onTransferStudentsFailureHandler = (data) => {
      setTransferStudents_data(null);
      if (typeof onFailure === "function") onFailure(data);
    };

    const onTransferStudentsSuccessHandler = (data) => {
      setTransferStudents_data(null);
      if (typeof onSuccess === "function") onSuccess(data);
    };

    const onTransferStudentsCancelHandler = () => {
      setTransferStudents_data(null);
      if (typeof onCancel === "function") onCancel();
    };

    const transferStudents_dataObj = {
      classId,
      device_uuids,
      onSuccess: onTransferStudentsSuccessHandler,
      onCancel: onTransferStudentsCancelHandler,
      onFailure: onTransferStudentsFailureHandler,
    };

    setTransferStudents_data(transferStudents_dataObj);
  };

  const classAction_addClass = ({ isShowing, onSuccess, onFailure, onCancel }) => {
    const onSuccessHandler = (data) => {
      setAddClass_data(null);
      if (typeof onSuccess === "function") onSuccess(data);
    };

    const onFailureHandler = (data) => {
      //setAddClass_data(null);           //Don't close modal on failure
      if (typeof onFailure === "function") onFailure(data);
    };

    const onCancelHandler = () => {
      setAddClass_data(null);
      if (typeof onCancel === "function") onCancel();
    };

    const addClass_dataObj = {
      isShowing,
      onSuccess: onSuccessHandler,
      onFailure: onFailureHandler,
      onCancel: onCancelHandler,
    };

    setAddClass_data(addClass_dataObj);
  };

  const classAction_editClass = ({ classId, onSuccess, onFailure, onCancel }) => {
    if (!classId) {
      logger.warn("ClassActionProvider: No classId provided, cannot perform Edit Class operation");
      return;
    }

    const onSuccessHandler = (data) => {
      setEditClass_data(null);
      if (typeof onSuccess === "function") onSuccess(data);
    };

    const onFailureHandler = (data) => {
      //setEditClass_data(null);          //Dont close modal on failure
      if (typeof onFailure === "function") onFailure(data);
    };

    const onCancelHandler = () => {
      setEditClass_data(null);
      if (typeof onCancel === "function") onCancel();
    };

    const editClass_dataObj = {
      classId,
      onSuccess: onSuccessHandler,
      onFailure: onFailureHandler,
      onCancel: onCancelHandler,
    };

    setEditClass_data(editClass_dataObj);
  };

  return (
    <ClassActionContext.Provider
      value={{
        classAction_startClass,
        classAction_endClass,
        classAction_hideEndClassModal,
        classAction_duplicateClass,
        classAction_transferStudents,
        classAction_addClass,
        classAction_editClass,
      }}
    >
      {children}

      {startClass_data && <ClassStartModalContainer {...startClass_data} />}

      {endClass_data && <ClassEndModalContainer {...endClass_data} />}

      {duplicateClass_data && <DuplicateClassModalContainer {...duplicateClass_data} />}

      {transferStudents_data && <TransferStudentsModalContainer {...transferStudents_data} />}

      {addClass_data && <AddClassModalContainer {...addClass_data} />}

      {editClass_data && <EditClassModalContainer {...editClass_data} />}
    </ClassActionContext.Provider>
  );
};
