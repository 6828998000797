import "./style.css";

export const MGCardFooter = ({ children }) => {
  return <div className="card__card-footer">{children}</div>;
};

MGCardFooter.propTypes = {};

MGCardFooter.defaultProps = {};

export default MGCardFooter;
