import { backLinkVar, backTitleVar, pageTitleVar } from "@/cache";
import { MGFlexBox, MGFlexBoxSpacer } from "@/components/layout";
import { LoadingPlaceholder } from "@/components/progress";
import { useEffect } from "react";
import MGContent from "../MGContent/MGContent";
import MGSurface from "../MGSurface/MGSurface";

export const FeaturesLoaderComponent = () => {
  useEffect(() => {
    backTitleVar("");
    pageTitleVar("");
    backLinkVar("");
  }, []);

  return (
    <MGContent>
      <div style={{ overflow: "hidden", height: "100%" }}>
        <MGSurface>
          <MGFlexBox>
            <LoadingPlaceholder
              extraStyles={{ height: "var(--mg-size-inputHeight)", width: "300px", borderRadius: "3px" }}
            />
            <MGFlexBoxSpacer />
            <LoadingPlaceholder
              extraStyles={{
                height: "calc(var(--mg-size-buttonHeight)",
                width: "calc(var(--mg-size-buttonHeight)",
                borderRadius: "calc(var(--mg-size-buttonHeight)",
              }}
            />
            &nbsp;&nbsp;&nbsp;
            <LoadingPlaceholder
              extraStyles={{ height: "calc(var(--mg-size-buttonHeight)", width: "150px", borderRadius: "10px" }}
            />
          </MGFlexBox>
          <br />
          <br />
          <br />
          <br />
          <MGFlexBox>
            <LoadingPlaceholder
              extraStyles={{ height: "var(--mg-font-heading1-fontSize)", width: "20%", borderRadius: "3px" }}
            />
            <MGFlexBoxSpacer />
            <LoadingPlaceholder
              extraStyles={{ height: "var(--mg-font-heading1-fontSize)", width: "20%", borderRadius: "3px" }}
            />
            <MGFlexBoxSpacer />
            <LoadingPlaceholder
              extraStyles={{ height: "var(--mg-font-heading1-fontSize)", width: "20%", borderRadius: "3px" }}
            />
            <MGFlexBoxSpacer />
            <LoadingPlaceholder
              extraStyles={{ height: "var(--mg-font-heading1-fontSize)", width: "20%", borderRadius: "3px" }}
            />
          </MGFlexBox>
          <br />
          <br />
          <LoadingPlaceholder extraStyles={{ height: "50px", width: "100%", borderRadius: "5px" }} /> &nbsp;
          <br />
          <LoadingPlaceholder extraStyles={{ height: "50px", width: "100%", borderRadius: "5px" }} />
          &nbsp;
          <br />
          <LoadingPlaceholder extraStyles={{ height: "50px", width: "100%", borderRadius: "5px" }} />
          &nbsp;
          <br />
          <LoadingPlaceholder extraStyles={{ height: "50px", width: "100%", borderRadius: "5px" }} />
          &nbsp;
          <br />
          <LoadingPlaceholder extraStyles={{ height: "50px", width: "100%", borderRadius: "5px" }} />
          &nbsp;
          <br />
          <LoadingPlaceholder extraStyles={{ height: "50px", width: "100%", borderRadius: "5px" }} />
          &nbsp;
          <br />
          <LoadingPlaceholder extraStyles={{ height: "50px", width: "100%", borderRadius: "5px" }} />
          &nbsp;
          <br />
          <LoadingPlaceholder extraStyles={{ height: "50px", width: "100%", borderRadius: "5px" }} />
          &nbsp;
          <br />
          <LoadingPlaceholder extraStyles={{ height: "50px", width: "100%", borderRadius: "5px" }} />
          &nbsp;
          <br />
          <LoadingPlaceholder extraStyles={{ height: "50px", width: "100%", borderRadius: "5px" }} />
          &nbsp;
          <br />
          <LoadingPlaceholder extraStyles={{ height: "50px", width: "100%", borderRadius: "5px" }} />
          &nbsp;
          <br />
          <LoadingPlaceholder extraStyles={{ height: "50px", width: "100%", borderRadius: "5px" }} />
          &nbsp;
          <br />
        </MGSurface>
      </div>
    </MGContent>
  );
};

export default FeaturesLoaderComponent;
