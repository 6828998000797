import "./style.css";

export const MGHeading3 = ({ children }) => {
  return <h3>{children}</h3>;
};

MGHeading3.propTypes = {};

MGHeading3.defaultProps = {};

export default MGHeading3;
