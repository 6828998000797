import { useEffect } from "react";
// import { useNavigate } from "react-router";
import { useApolloClient } from "@apollo/client";
import { isLoggedInVar } from "@/cache";
import Config from "@/config";

export const LogoutPage = () => {
  // const navigate = useNavigate();

  const client = useApolloClient();

  useEffect(() => {
    // clear cache here https://www.apollographql.com/docs/react/networking/authentication
    client.resetStore();

    localStorage.removeItem("token");
    localStorage.removeItem("currentUser");

    isLoggedInVar(false);
    window.location.href = `${Config.ADMIN_DASHBOARD_HOST}`;
    // window.location.href = `https://${Config.ADMIN_DASHBOARD_HOST}`;

    //navigate("/login");
  });

  return <></>;
};

export default LogoutPage;
