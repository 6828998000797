import { Form } from "formik";
import { MGLabel } from "@/components/typography";
import * as yup from "yup";

import { MGButton } from "@/components/buttons";
import { useTranslation } from "react-i18next";
import { MGPasswordField, MGTextboxField } from "@/components/inputs";
import PropTypes from "prop-types";
import { MGFlexBox } from "@/components/layout";

export const loginValidationSchema = (translate) => {
  return yup.object({
    username: yup.string().min(3).required(translate("Required")),
    password: yup.string().min(3).required(translate("Required")),
  });
};

export const LoginForm = ({ isSubmitting }) => {
  const { t } = useTranslation();
  return (
    <div style={{ minWidth: "70%" }}>
      <MGFlexBox>
        <Form noValidate autoComplete={"off"} width={50}>
          <div className="login-input">
            <MGLabel htmlFor="username">{t("Username/Email address")}</MGLabel>
            <MGTextboxField name="username" type="text" clearable={false} />
          </div>
          <br />

          <div className="login-input">
            <MGLabel htmlFor="password">{t("Password")}</MGLabel>
            <br />
            <MGPasswordField name="password" type={"password"} clearable={false} />
          </div>
          <br />
          <div className="login-center">
            <MGButton variant="primary" disabled={isSubmitting} isLoading={isSubmitting} type={"submit"}>
              {t("Sign in")}
            </MGButton>
          </div>
        </Form>
      </MGFlexBox>
    </div>
  );
};

LoginForm.propTypes = {
  isSubmitting: PropTypes.any,
};
