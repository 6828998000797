import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { Formik } from "formik";
import { LoginForm, loginValidationSchema } from "@/app/features/auth/components/LoginForm";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { MGImage } from "@/components/image";
import MGSurface from "@/app/app-components/MGSurface/MGSurface";
// import "./style.css";
import { MGHeading2, MGHeading3, MGHelperText } from "@/components/typography";
import { MGButton } from "@/components/buttons";
import { MGIcon } from "@/components/icons";
import { authService } from "@/main";

export const ManualLogin = () => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  let location = useLocation();
  let from = location.state?.from?.pathname || "/";

  const [errorDisplayMessage, setErrorDisplayMessage] = useState(null);

  const initialValues = {
    username: "",
    password: "",
  };

  const processSuccessfulLogin = () => {
    navigate(from, { replace: true });
  };

  //NB!!!!! Leave this here as login error types are still valid but not implemented on new login endpoint
  // const handleErrors = (errors) => {
  //   let msg = null;
  //   errors.map((err) => {
  //     logger.error(err);
  //     switch (err.__typename) {
  //       case "InvalidCredentialsProblem":
  //         msg = t("Invalid credentials provided");
  //         break;
  //       case "GoogleAuthenticatorCodeRequiredProblem":
  //         msg = t("Google Authentication Required"); //Not sure what needs to happen when this error is thrown
  //         break;
  //       case "GoogleAuthenticatorCodeInvalidProblem":
  //         msg = t("Invalid Google Credentials provided");
  //         break;
  //       case "NotQuiteSureProblem":
  //         logger.error(err);
  //         msg = t("An unexpected error occurred, please try again later or contact support");
  //         break;
  //       default:
  //         msg = t("An unexpected error occurred, please try again");
  //         break;
  //     }
  //   });
  //   return msg;
  //   //logger.log("This code should never be reached, there is an error with an unknown type in the mix. Error: ", errors)
  // };

  const handleSubmit = async (values, { setSubmitting }) => {
    const { username, password } = values;

    // var loginInput = {
    //   username: username,
    //   password: password,
    // };

    try {
      setErrorDisplayMessage(null);
      localStorage.removeItem("token"); //Delete token key BEFORE attempting login

      const loginResponse = await authService.login(username, password);

      if (loginResponse === true) {
        processSuccessfulLogin();
      } else {
        throw loginResponse;
      }
    } catch (err) {
      if (err && typeof err === "string") setErrorDisplayMessage(err);
    }
    setSubmitting(false);
  };

  return (
    <div className="login-background">
      <div className="login-wrapper">
        <div className="login-left-col">
          <div className="login-layout">
            <div className="logo">
              <MGImage src="/images/mg_logo_w_text.svg" height={30} fit="contain" />
            </div>
            <MGSurface>
              {errorDisplayMessage && <div>{errorDisplayMessage}</div>}
              <div className="login-form">
                <div className="login-center">
                  <MGHeading3>{t("Login")}</MGHeading3>
                </div>
                <br />
                <br />
                <Formik
                  initialValues={initialValues}
                  onSubmit={handleSubmit}
                  validationSchema={loginValidationSchema(t)}
                >
                  {(props) => {
                    return <LoginForm {...props} />;
                  }}
                </Formik>
                <div className="login-center">
                  <br />
                  <p>
                    <MGHelperText>{t("or")}</MGHelperText>
                  </p>
                  <br />
                  <MGImage src="/images/google_signin_light_normal.png" />
                  <br />
                  <br />
                  <br />
                  <p>
                    <MGHelperText>{t("Don't have an account yet?")}</MGHelperText>
                    <br />
                    <MGButton variant="tertiary">{t("Create account")}</MGButton>
                  </p>
                </div>
              </div>
            </MGSurface>
          </div>
        </div>
        <div className="login-side-panel">
          <MGHeading2>{t("Try our Complete Classroom Solution")}</MGHeading2>
          <MGHeading3>{t("Free 14-day trial!")}</MGHeading3>
          <ul className="onboarding-sidebar-list">
            <MGHeading3>{t("Features Include")}</MGHeading3>
            <br />
            <li>
              <MGIcon icon="tag" /> {t("Mobile device management")}
            </li>
            <li>
              <MGIcon icon="tag" /> {t("Classroom managament")}
            </li>
            <li>
              <MGIcon icon="tag" /> {t("Web filtering")}
            </li>
            <li>
              <MGIcon icon="tag" /> {t("Live screen views")}
            </li>
            <li>
              <MGIcon icon="tag" /> {t("Youtube filtering")}
            </li>
            <li>
              <MGIcon icon="tag" /> {t("Keyword filtering")}
            </li>
            <li>
              <MGIcon icon="tag" /> {t("Google Workplace enrolment")}
            </li>
            <li>
              <MGIcon icon="tag" /> {t("Time & location based Profiles")}
            </li>
            <li>
              <MGIcon icon="tag" /> {t("Location tracking")}
            </li>
            <li>
              <MGIcon icon="tag" /> {t("Remote app updating")}
            </li>
            <li>
              <MGIcon icon="tag" /> {t("Much more")}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
