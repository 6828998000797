import PropTypes from "prop-types";
import "./style.css";
import { MGIcon } from "@/components/icons";
import { MGButton } from "@/components/buttons";

export const MGSnackbar = ({ id, text, actionText, actionHandler, dismissable }) => {
  const actionClickHandler = () => {
    if (typeof actionHandler === "function") {
      actionHandler(id);
    }
  };

  return (
    <div className="--mg-snackbar" data-id={id}>
      <div className="--mg-snackbar-text">{text}</div>
      {/* maybe we can do away with dismissable and test for actionHandler*/}
      {dismissable && (
        <div className="--mg-snackbar-action">
          {actionText ? (
            <MGButton variant={"snack"} onClick={actionClickHandler}>
              {actionText}
            </MGButton>
          ) : (
            <MGIcon icon={"close"} size={14} onClick={actionClickHandler} />
          )}
        </div>
      )}
    </div>
  );
};

MGSnackbar.propTypes = {
  /**
   * (Optional) For use by parents such as `MGSnackbarProvider`, used to distinguish Snackbar messages
   */
  id: PropTypes.string,

  /**
   * Enables custom user *actionText* and *actionHandler*
   */
  dismissable: PropTypes.bool,

  /**
   * Content display on open Snackbar
   */
  text: PropTypes.string.isRequired,

  /**
   * Text that replaces close icon if *dismissable* is true
   */
  actionText: PropTypes.string,

  /**
   * Action handler that is invoked when action clicked and if *dismissable* is true
   */
  actionHandler: PropTypes.func,
};

MGSnackbar.defaultProps = {
  dismissable: false,
};

export default MGSnackbar;
