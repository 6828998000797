import i18next from "i18next";
import { useEffect, useState } from "react";
import MGConfirmationModal from "./app/app-components/confirmation-modal/MGConfirmationModal";
import { MGModal } from "./components/modals";
import { useRegisterSW } from "virtual:pwa-register/react";
import config from "./config";
import { logger } from "@/app/services/logging/Logger";

export const UpdateServiceWorkerModal = () => {
  const NAMESPACE = "[UpdateServiceWorkerModal]";

  const [updateNeeded, setUpdateNeeded] = useState(false);
  const [updateButtonClicked, setUpdateButtonClicked] = useState(false);
  const [updateAppConfigModalProps, setUpdateAppConfigModalProps] = useState(null);
  const [appConfigUpdateInProgress, setAppConfigUpdateInProgress] = useState(false);

  const defaultIntervalMins = 11;

  const appHasUpdatedInTheLastFiveMinutes = () => {
    let hasUpdatedInTheLastFiveMinutes = false;
    const lastUpdated = localStorage.getItem("dashboard_last_updated_on");

    const fiveMinutesAgo = new Date(Date.now() - 5 * 60000);
    if (lastUpdated) {
      if (new Date(lastUpdated) < fiveMinutesAgo) {
        hasUpdatedInTheLastFiveMinutes = true;
      }
    } else {
      return false;
    }

    logger.log(
      NAMESPACE,
      "Has updated in last 5 minutes?",
      hasUpdatedInTheLastFiveMinutes,
      "Last Update: ",
      lastUpdated,
      "Five mins ago:",
      fiveMinutesAgo
    );
  };

  function isNumeric(str) {
    if (typeof str != "string") return false; // we only process strings!
    return (
      !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
      !isNaN(parseFloat(str))
    ); // ...and ensure strings of whitespace fail
  }

  const checkInterval =
    config.CHECK_APP_CONFIG_HASH_INTERVAL_MINS &&
    config.CHECK_APP_CONFIG_HASH_INTERVAL_MINS !== "" &&
    isNumeric(config.CHECK_APP_CONFIG_HASH_INTERVAL_MINS)
      ? parseFloat(config.CHECK_APP_CONFIG_HASH_INTERVAL_MINS)
      : defaultIntervalMins;

  const updateSW = useRegisterSW({
    onRegistered(r) {
      r &&
        setInterval(async () => {
          // eslint-disable-next-line no-unused-vars
          let updateSWResponse = await r.update().then(async (registration) => {
            const recentlyUpdated = appHasUpdatedInTheLastFiveMinutes();
            if (!recentlyUpdated) {
              let needsUpdate = await checkAppConfigHash(); //First check if the AppConfig hash has changed since last check
              if (needsUpdate) {
                //If there is, it should preceed & override the App Config update
                if (!(await registration?.waiting) && !(await registration?.installing)) {
                  // .waiting & .installing lets us know if a new SW is either installing or ready to take over old SW
                  //If there is no new SW to install, proceed with an App Config update prompt
                  setUpdateAppConfigModalProps({
                    onSuccess: async () => {
                      await UpdateForNewAppConfigSettings();
                    },
                  });
                } else {
                  logger.debug(
                    "[UpdateServiceWorkerModal] New App config detected, but new service worker is also installing, waiting until intsallation is completed."
                  );
                }
              }
            }
          }); //We NEED to check if there is a new SW ready FIRST
        }, 1000 * 60 * checkInterval);
    },
    onNeedRefresh() {
      setUpdateNeeded(true);
    },
  });

  const checkAppConfigHash = async () => {

    //dev builds cannot access nginx endpoints of the application (`/env-config-status`)
    //we only need to call this endpoint to check if an Update is needed for the app
    //dev builds NEVER need an update, unless you are trying to test the Update modal
    const isDevEnv = !process.env.NODE_ENV || process.env.NODE_ENV === "development";
    if(isDevEnv){
      return false;
    }

    const appConfigHash = await getAppConfigHash();
    if (appConfigHash) {
      return checkIfAppConfigIsOutOfDate(appConfigHash);
    }
    return false;
  };

  const checkIfAppConfigIsOutOfDate = (appConfigHash) => {
    const lastHash = localStorage.getItem("last_app_config_hash");

    if (!lastHash || lastHash === "undefined" || lastHash === "null") {
      logger.debug("New App config hash received");
      localStorage.setItem("dashboard_last_updated_on", `${new Date()}`);
      localStorage.setItem("last_app_config_hash", appConfigHash?.hash);
    } else {
      if (lastHash !== appConfigHash?.hash) {
        logger.debug("App config hash outdated, update required");
        return true;
      }
    }
    logger.debug("App config hash up to date");
    return false;
  };

  const getAppConfigHash = async () => {
    try {
      let appConfigHashResponse;
      // if (!isDevEnv) {
      const response = await fetch(`/env-config-status`, {
        method: "GET",
        headers: { "Content-type": "application/json; charset=UTF-8" },
        credentials: "include",
        mode: "cors",
      });
      appConfigHashResponse = await response.json();
      // }
      // else {
      //   logger.debug("[UpdateServiceWorker] Getting stubbed appConfig");
      //   appConfigHashResponse = { date: "1701776484000", hash: "e1f97b7507fa38ccd48ac43ff594ae69" };
      // }

      if (appConfigHashResponse && appConfigHashResponse != "" && appConfigHashResponse != {}) {
        return appConfigHashResponse;
      } else {
        return null;
      }
    } catch (err) {
      logger.error("[UpdateServiceWorker] Error: ", err);
      return null;
    }
  };

  const UpdateForNewAppVersion = () => {
    //When there is a code change (new app version), we only need to:
    //  - Clear the last app config hash in localStorage to ensure the next hash check does not trigger another update prompt
    //  - Call VitePWA's updateSW() function to update the Service Worker
    //  - Clear/manage state showing the update prompt

    localStorage.removeItem("last_app_config_hash");
    setUpdateButtonClicked(true);
    setUpdateNeeded(false);

    if (updateSW && typeof updateSW.updateServiceWorker === "function") updateSW.updateServiceWorker();

    setTimeout(() => {
      window.location.reload(true); //Hard reload
    }, 5000);
  };

  const UpdateForNewAppConfigSettings = async () => {
    //When there is a app config change (new env vars), we need to:
    //  - Find the SW's precache storage
    //  - Keep track of all URL keys that are cached as a resource
    //  - Delete SW's precache
    //  - Manually request & add the URL keys to the same cache location as before
    //  - Reload the page
    setAppConfigUpdateInProgress(true);
    try {
      let requestsList = []; // This will keep track of URL keys
      await caches.keys().then(function (cacheNames) {
        return Promise.all(
          cacheNames
            .filter(function (cacheName) {
              if (cacheName.includes("workbox")) return true; // The SW's cache name is dynamic, but utilizes "workbox", so always has it in its name
            })
            .map(async function (cacheName) {
              await caches.open(cacheName).then(async (cacheItems) => {
                await cacheItems.keys().then((requests) => {
                  requests.map((r) => {
                    requestsList.push(r.url); // Once we have the SW's precache, we iterate through the URL keys to update cache after we clear it
                  });
                });
              });
              await caches.delete(cacheName); // Delete SW's cache

              await caches.open(cacheName).then(async (cache) => {
                // await cache.addAll(requestsList); // Reopen the deleted cache & add all URL's again (fresh resources)
                for (let i = 0; i < requestsList.length; i++) {
                  await cache.add(requestsList[i]);
                }
              });

              return null;
            })
        );
      });

      localStorage.removeItem("last_app_config_hash"); //Clear app config hash to prevent another update triggering
      setUpdateButtonClicked(true);
      setUpdateAppConfigModalProps(null);
      window.location.reload(true); //Hard reload
    } catch (err) {
      logger.debug(err);
    } finally {
      setAppConfigUpdateInProgress(false);
    }
  };

  useEffect(() => {
    if (!config.CHECK_APP_CONFIG_HASH_INTERVAL_MINS) {
      logger.debug(
        `[UpdateServiceWorker] CHECK_APP_CONFIG_HASH_INTERVAL_MINS config not provided, checking hash every ${defaultIntervalMins} minutes`
      );
    } else {
      logger.debug(
        `[UpdateServiceWorker] Checking config hash every ${config.CHECK_APP_CONFIG_HASH_INTERVAL_MINS} minutes`
      );
    }

    const appConfHash = localStorage.getItem("last_app_config_hash");

    if (!appConfHash || appConfHash === "undefined" || appConfHash === "null") {
      logger.debug("Need app config hash immediately");
      checkAppConfigHash();
    }
  });

  return (
    <>
      {/* Modal below is shown when there is a new VERSION of the TD deployed & ready */}
      <MGModal
        isShowing={
          // !hasUpdatedInTheLastFiveMinutes && updateNeeded && !updateButtonClicked && !appConfigUpdateInProgress
          updateNeeded && !updateButtonClicked && !appConfigUpdateInProgress
        }
      >
        <MGConfirmationModal
          title={i18next.t("Update available")}
          message={i18next.t("A newer version of our webapp is available, would you like to update?")}
          positive={i18next.t("Update")}
          negative={i18next.t("Cancel")}
          onClose={() => {
            setUpdateButtonClicked(true);
            setUpdateNeeded(false);
          }}
          onSuccess={UpdateForNewAppVersion}
        />
      </MGModal>

      {/* Modal below is shown when there have been changes to the app's configuration (.env.production) WITHOUT any change to the app's code / version */}
      <MGModal
        isShowing={
          // !hasUpdatedInTheLastFiveMinutes && updateAppConfigModalProps && !updateNeeded && !updateButtonClicked
          updateAppConfigModalProps && !updateNeeded && !updateButtonClicked
        }
      >
        <MGConfirmationModal
          title={i18next.t("Update for webapp available")}
          message={i18next.t("A newer version of our webapp is available, would you like to update?")}
          positive={i18next.t("Update")}
          negative={i18next.t("Cancel")}
          onClose={() => {
            setUpdateButtonClicked(true);
            setUpdateNeeded(false);
          }}
          {...updateAppConfigModalProps}
          positiveIsLoading={appConfigUpdateInProgress}
        />
      </MGModal>
    </>
  );
};

export default UpdateServiceWorkerModal;
