import "./style.css";
import { MGHelperText } from "@/components/typography";
import PropTypes from "prop-types";

export const MGBadge = ({ value, props }) => {
  return (
    <div className="--mg-badge" {...props}>
      <MGHelperText>{!value || value < 0 ? 0 : value >= 100 ? "99+" : value}</MGHelperText>
    </div>
  );
};

MGBadge.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

MGBadge.defaultProps = {
  value: 0,
};

export default MGBadge;
