import PropTypes from "prop-types";
import "./style.css";
import classNames from "classnames";
import { MGIcon } from "@/components/icons";

export const MGInputListItem = ({ value, removable, children, disabled, onRemoveClick }) => {
  const onRemoveClickHandler = () => {
    //logger.log(value);
    if (typeof onRemoveClick === "function") {
      onRemoveClick(value);
    }
  };

  return (
    <div className="--mg-input-list-item-container">
      <div
        className={`${classNames({
          "--mg-content-container": true,
          disabled: disabled,
        })}`}
      >
        {children}
      </div>
      <div className="--mg-flex-box-spacer"></div>
      {removable && <MGIcon disabled={disabled} icon="trash" iconSize={16} onClick={onRemoveClickHandler}></MGIcon>}
    </div>
  );
};

MGInputListItem.propTypes = {
  removable: PropTypes.bool,
  disabled: PropTypes.bool,
};

MGInputListItem.defaultProps = {
  removable: true,
  disabled: false,
};

export default MGInputListItem;
