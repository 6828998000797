import PropTypes from "prop-types";
import "./style.css";

export const LoadingPlaceholder = ({ extraStyles, rest }) => {
  const placeholderStyle = {
    position: "relative",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "linear-gradient(to right, #eeeeee 0%, #dddddd 50%, #eeeeee 100%)",
    animation: "placeholderSwipeAnim 1s cubic-bezier(0.4, 0.0, 0.2, 1) infinite",
  };

  const containerStyle = {
    width: "100%",
    height: "100%",
    position: "relative",
    overflow: "hidden",
    background: "#eee",
    display: "inline-block",
    verticalAlign: "top",
    ...extraStyles,
  };

  return (
    <div style={containerStyle} {...rest}>
      <div style={placeholderStyle} />
    </div>
  );
};

LoadingPlaceholder.propTypes = {
  extraStyles: PropTypes.object,
  rest: PropTypes.any,
};

LoadingPlaceholder.defaultProps = {};

export default LoadingPlaceholder;
