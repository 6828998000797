/* eslint-disable */

/** Signature of a logging function */
export interface LogFn {
  (message?: any, ...optionalParams: any[]): void;
}

export const LOG_LEVEL: LogLevel = (!process.env.NODE_ENV || process.env.NODE_ENV) === 'development' ? 'log' : 'warn';
export const DEBUG_STATUS: debugStatus = localStorage.getItem("debug") === 'true' ? 'true' : 'false';


/** Basic logger interface */
export interface Logger {
  log: LogFn;
  warn: LogFn;
  error: LogFn;
  debug: LogFn;
}

/** Log levels */
export type LogLevel = 'log' | 'warn' | 'error';

/** Debug status  */
export type debugStatus = 'false' | 'true';


const NO_OP: LogFn = (message?: any, ...optionalParams: any[]) => {};

/** Logger which outputs to the browser console */
export class ConsoleLogger implements Logger {
    readonly log: LogFn;
    readonly warn: LogFn;
    readonly error: LogFn;
    readonly debug: LogFn

    constructor(options?: { level? : LogLevel, debug?: debugStatus }) {
        const { level } = options || {};
        const { debug } = options || {};

        if (debug === 'true') {
            this.error = console.error.bind(console);

            if (level === 'error') {
                this.debug = NO_OP;
                this.warn = NO_OP;
                this.log = NO_OP;

                return;
            }
            
            this.warn = console.warn.bind(console);
            this.debug = console.debug.bind(console);

            if (level === 'warn') {
                this.log = NO_OP;

                return;
            }

            this.log = console.log.bind(console);
        } else {
            this.error = console.error.bind(console);
            this.debug = NO_OP;
            this.warn = NO_OP;
            this.log = NO_OP;
        }
        
    }

}

export const logger = new ConsoleLogger({ level: LOG_LEVEL, debug: DEBUG_STATUS });