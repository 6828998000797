import { logger } from "../logging/Logger";
import { NatsService } from "./nats-service";

const TAG = "[natsSubscriptionHandler]";

export function natsSubscriptionHandler(channel, handler, onClosureThen) {
  const subId = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });

  logger.debug(TAG, `Subscription ${subId} created for channel ${channel}`);
  let sub = NatsService.getInstance().subscribe(channel);

  let subClosure = async () => {
    let close = false;
    if (!window.disposeSubscription) {
      window.disposeSubscription = {};
    }

    window.disposeSubscription[subId] = {};
    window.disposeSubscription[subId].channel = channel;

    window.disposeSubscription[subId].cleanup = function () {
      logger.debug(TAG, `Subscription & subscription closure ${subId} will be terminated on next message`);
      close = true;
    };

    for await (var message of sub) {
      const messageData = NatsService.getInstance().sanitizeMessage(message);

      if (close) {
        logger.debug(TAG, `Terminate message for subscription ${subId} received`);
        return;
      }

      if (messageData && handler && typeof handler === "function") {
        handler(messageData, message.subject);
      }
    }
  };

  subClosure().then(() => {
    sub?.unsubscribe();
    sub = undefined;
    subClosure = null;
    logger.debug(TAG, `Subscription closure for ${subId} terminated`);
    if (typeof onClosureThen === "function") onClosureThen();
  });

  const disposeSubscription = () => {
    disposeSpecificSubscription(subId);
  };

  return disposeSubscription;
}

export function disposeAllNatsSubscriptions() {
  logger.log(window.disposeSubscription);

  Object.keys(window.disposeSubscription).map((subId) => {
    logger.warn(TAG, `Subscription & subscription closure ${subId} will be terminated on next message`);
    if (typeof window.disposeSubscription[subId] === "function") {
      window.disposeSubscription[subId]();
    }
  });
}

//The Function below is commented out as it is unsafe to assume that there should never be multiple subscriptions to the same channel
//Keeping it here in case the logic needs to be repurposed to terminate straggling subscriptions
// eslint-disable-next-line no-unused-vars
async function findAndKillStragglingSubs(channel) {
  Object.keys(window.disposeSubscription).map((subId) => {
    if (window.disposeSubscription[subId]?.channel === channel) {
      logger.error(TAG, "Found duplicate subs for channel ", channel, "Ensure you are closing subs correctly");
      window.disposeSpecificSubscription(subId);
    }
  });
}

async function disposeSpecificSubscription(specificSubId) {
  if (
    window.disposeSubscription[specificSubId] &&
    typeof window.disposeSubscription[specificSubId].cleanup === "function"
  ) {
    var { [`${specificSubId}`]: specificSub, ...rest } = window.disposeSubscription;
    specificSub.cleanup();
    window.disposeSubscription = rest;
    //findAndKillStragglingSubs(specificSub.channel)
    specificSub = null;
  }
}
