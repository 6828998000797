import "./style.css";
import ReactDOM from "react-dom";
import { useHover } from "./useHover";
import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

export const MGTooltip = ({ children, position, text, disabled, ...rest }) => {
  const [actualPosition, setActualPosition] = useState(position);
  const [hoverRef, isHovered] = useHover();
  const tooltipRef = useRef();
  const [showTooltip, setShowTooltip] = useState(false);

  //Fixed variable for pixel distance between the tooltip and its trigger
  const paddingForTooltip = 5;

  const findCenterOfHoverRef = () => {
    let xy = {
      x: 0,
      y: 0,
    };

    xy.x = hoverRef.current.getBoundingClientRect().left + hoverRef.current.getBoundingClientRect().width / 2;
    xy.y = hoverRef.current.getBoundingClientRect().top + hoverRef.current.getBoundingClientRect().height / 2;

    return xy;
  };

  const positionToTheRight = () => {
    centerVertically();

    const leftPos =
      hoverRef.current.getBoundingClientRect().left +
      hoverRef.current.getBoundingClientRect().width +
      paddingForTooltip;

    var browserWindowWidth = window.innerWidth;

    if (leftPos + tooltipRef.current.getBoundingClientRect().width > browserWindowWidth) positionToTheLeft();
    else {
      tooltipRef.current.style.left = leftPos + "px";
      setActualPosition("right");
    }
  };

  const positionToTheLeft = () => {
    centerVertically();

    const leftPos =
      hoverRef.current.getBoundingClientRect().left -
      tooltipRef.current.getBoundingClientRect().width -
      paddingForTooltip;

    if (leftPos < 0) {
      positionToTheRight();
    } else {
      tooltipRef.current.style.left = leftPos + "px";
      setActualPosition("left");
    }
  };

  const positionAbove = () => {
    centerHorizontally();

    const topPos =
      hoverRef.current.getBoundingClientRect().top -
      tooltipRef.current.getBoundingClientRect().height -
      paddingForTooltip;

    if (topPos < 0) {
      positionBelow();
    } else {
      tooltipRef.current.style.top = topPos + "px";
      setActualPosition("top");
    }
  };

  const positionBelow = () => {
    centerHorizontally();

    //var w = window.innerWidth;
    var browserWindowHeight = window.innerHeight;

    const topPos =
      hoverRef.current.getBoundingClientRect().top +
      hoverRef.current.getBoundingClientRect().height +
      paddingForTooltip;

    if (topPos + tooltipRef.current.getBoundingClientRect().height > browserWindowHeight) {
      positionAbove();
    } else {
      tooltipRef.current.style.top = topPos + "px";
      setActualPosition("bottom");
    }
  };

  const centerHorizontally = () => {
    //Get center xy coords of the tooltip trigger
    const centerPoint = findCenterOfHoverRef();

    //Calculate the horizontal difference between the center of the tooltip & the center of the tooltip trigger
    const tooltipCenterOffset =
      centerPoint.x -
      hoverRef.current.getBoundingClientRect().left -
      tooltipRef.current.getBoundingClientRect().width / 2;

    //The offset + left position of the tooltip trigger is the x coordinate to allow the tooltip's center to be the same as the trigger's center
    tooltipRef.current.style.left = hoverRef.current.getBoundingClientRect().left + tooltipCenterOffset + "px";
  };

  const centerVertically = () => {
    //Get center xy coords of the tooltip trigger
    const centerPoint = findCenterOfHoverRef();

    //Calculate the horizontal difference between the center of the tooltip & the center of the tooltip trigger
    const tooltipCenterOffset =
      centerPoint.y -
      hoverRef.current.getBoundingClientRect().top -
      tooltipRef.current.getBoundingClientRect().height / 2;

    //The offset + top position of the tooltip trigger is the x coordinate to allow the tooltip's center to be the same as the trigger's center
    tooltipRef.current.style.top = hoverRef.current.getBoundingClientRect().top + tooltipCenterOffset + "px";
  };

  useEffect(() => {
    setShowTooltip(isHovered && !disabled);
    if (isHovered) {
      if (tooltipRef?.current && hoverRef?.current) {
        switch (position) {
          case "top":
            positionAbove();
            break;
          case "bottom":
            positionBelow();
            break;
          case "left":
            positionToTheLeft();
            break;
          case "right":
            positionToTheRight();
            break;
          default:
            positionAbove();
            break;
        }
      }
    }
  }, [isHovered, text]);

  return (
    <>
      {isHovered &&
        text &&
        ReactDOM.createPortal(
          <span
            ref={tooltipRef}
            className={`--mg-tooltip-text --mg-tooltip-text-${actualPosition}`}
            style={{ visibility: showTooltip ? "visible" : "hidden", overflowWrap: "anywhere" }}
          >
            {text}
          </span>,
          document.body
        )}
      <div ref={hoverRef} className={`--mg-tooltip --mg-tooltip-` + position} {...rest}>
        {children}
      </div>
    </>
  );
};

MGTooltip.propTypes = {
  disabled: PropTypes.bool,
};

MGTooltip.defaultProps = {
  disabled: false,
};

export default MGTooltip;
