import classNames from "classnames";
import { forwardRef } from "react";

import "./style.css";

// eslint-disable-next-line react/display-name
export const MGInputWrapper = forwardRef(({ children, onClick, tabindex, ...rest }, ref) => {
  const { error, disabled, success, touched } = rest;

  return (
    <div
      ref={ref}
      onClick={onClick}
      tabIndex={tabindex ?? -1}
      className={`input-wrapper ${classNames({
        disabled: disabled,
        success: success,
        error: error && touched,
      })}`}
    >
      {children}
    </div>
  );
});

MGInputWrapper.propTypes = {};

MGInputWrapper.defaultProps = {};

export default MGInputWrapper;
