import { UPCOMING_CLASSES_QUERY } from "@/app/gql/classes/classes_queries";
import { getAMPMTime } from "@/app/hooks/useDateFormattingUtils";
import useMGQuery from "@/app/utils/gql/useMGQuery";

export const useUpcomingClasses = () => {
  const { loading, error, data, refetch } = useMGQuery(UPCOMING_CLASSES_QUERY, {
    variables: {
      page: 1,
      limit: 5,
      filter: {
        in_progress: false,
        upcoming: true,
      },
    },
  });

  return {
    data: data?.classes?.edges?.map(mapUpcomingClassData),
    loading,
    error,
    refetch,
  };
};

const mapUpcomingClassData = ({ node }) => {
  var now = null;
  if (node?.schedules[0]?.start_time) {
    now = new Date();
    var start = new Date(node.schedules[0]?.start_time);
    now.setHours(start.getHours());
    now.setMinutes(start.getMinutes());
    now.setSeconds(start.getSeconds());
  }

  return {
    id: node.id,
    duration: node.duration,
    screen_share_enabled: node.screen_share_enabled,
    className: node.name,
    schedule: node.schedules
      ? `${getAMPMTime(node.schedules[0]?.start_time)} - ${getAMPMTime(node.schedules[0]?.end_time)}`
      : null,
    color: node?.meta_data?.color,
    start_time: now,
  };
};

export default useUpcomingClasses;
