import { gql } from "@apollo/client";

export const DUPLICATE_CLASS_MUTATION = gql`
  mutation duplicateClass($input: DuplicateClassInput!) {
    duplicateClass(input: $input) {
      class {
        id
        #No other fields needed just yet from response
      }
      errors {
        __typename
        ... on NotQuiteSureProblem {
          message
        }
        ... on ConflictProblem {
          message
        }
        ... on Problem {
          message
        }
      }
    }
  }
`;
