import { ErrorMessage } from "formik";
import "./style.css";

import { MGDatePicker } from "./MGDatePicker";
import PropTypes from "prop-types";
import { MGHelperText } from "@/components/typography";
import { MGField } from "@/components/inputs";

export const MGDatePickerField = ({ name, placeholder, minDate, maxDate, onChange, inline }) => {
  return (
    <>
      <MGField name={name} inline={inline}>
        {({ meta, field, form }) => {
          const onChangeHandler = (evt) => {
            form.setFieldValue(field.name, evt);
            if (typeof onChange === "function") onChange(evt);
          };

          return (
            <>
              <MGDatePicker
                value={field.value}
                onChange={onChangeHandler}
                placeholder={placeholder}
                formikField={field}
                formikMeta={meta}
                minDate={minDate}
                maxDate={maxDate}
              ></MGDatePicker>
            </>
          );
        }}
      </MGField>
      <ErrorMessage component={MGHelperText} name={name} />
    </>
  );
};

MGDatePickerField.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  inline: PropTypes.bool,
};

MGDatePickerField.defaultProps = {
  placeholder: "dd/mm/yyyy",
};

export default MGDatePickerField;
