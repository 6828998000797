import { MGHeading1 } from "@/components/typography";
import "./style.css";

export const EmptyState = ({ message, children }) => {
  return (
    <div className="empty-state-container">
      {message && (
        <>
          <MGHeading1>{message}</MGHeading1> <br />
        </>
      )}
      {children}
    </div>
  );
};
