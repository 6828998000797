import "./style.css";

export const MGSpacing = ({ children, size }) => {
  return <div className={` --mg-spacing ${"--mg-spacing-" + size}`}>{children}</div>;
};

MGSpacing.propTypes = {};

MGSpacing.defaultProps = {};

export default MGSpacing;
