import { getDurations } from "@/app/utils/classes/durations";

import { updateActiveClass } from "@/app/store/activeClassStore";
import { useMemo } from "react";
import { GET_CLASS_MODES_CLASS_CONTENT_AND_CURRENT_USER } from "@/app/gql/classes/start_class_queries";
import { MUTATION_START_CLASS } from "@/app/gql/classes/start_class_mutation";
import useMGQuery from "@/app/utils/gql/useMGQuery";
import useMGMutation from "@/app/utils/gql/useMGMutation";
import { logger } from "@/app/services/logging/Logger";

export const useClassStart = ({ classId, onSuccess, onFailure }) => {
  const successHandler = (data) => {
    typeof onSuccess === "function" && onSuccess(data);
  };

  const failureHandler = () => {
    typeof onFailure === "function" && onFailure();
  };

  let classDurations = useMemo(getDurations, [classId]);

  const { data, loading: loadingData } = useMGQuery(GET_CLASS_MODES_CLASS_CONTENT_AND_CURRENT_USER, {
    variables: { classId: classId },
  });

  const modes = data?.classModesByClass?.edges?.map(({ node }) => {
    return { value: node.id, content: node.name };
  });

  const classContent = data?.classContents?.edges.map(({ node }) => {
    return {
      id: node.id,
      name: node.name,
      object_content: node.object_content,
      content_type: node.object_type,
      meta_url: node.meta_url,
    };
  });

  const currentUser = data?.currentUser;

  const [classStart] = useMGMutation(MUTATION_START_CLASS);

  const submitHandler = async (values, formik) => {
    try {
      formik.setSubmitting(true);
      var minutes = values.custom_duration;
      let obj = Object.assign({}, values, {
        classId: classId,
        teacherId: currentUser.id,
        duration: minutes ? minutes : values.duration,
      });
      delete obj.custom_duration;
      let { data, problems } = await classStart({
        variables: {
          input: obj,
        },
      });
      if (problems) {
        if (!data.startClass?.startClassData?.active_session?.id) {
          throw problems[0]?.message ?? problems;
        }
      }

      const payload = {
        classSessionId: data.startClass.startClassData.classSessionId,
        classId: classId,
        classEnded: data.startClass.startClassData.active_session.has_ended,
        classForceJoin: data.startClass.startClassData.class.force_join === "1",
        classStartTeacher: {
          userId: currentUser.id,
          classId: classId,
          classSessionId: data.startClass.startClassData.classSessionId,
        },
      };
      //Global state for start class (gets updated on end class as well)
      updateActiveClass(payload);

      formik.setSubmitting(false);
      formik.setStatus("SUCCESS");
      successHandler(data);
    } catch (err) {
      logger.error("err", err);
      formik.setSubmitting(false);
      formik.setStatus("ERROR");
      failureHandler();
    }
  };

  return {
    loadingData,
    classDurations,
    modes,
    classContent,
    submitHandler,
  };
};
