import PropTypes from "prop-types";
import "./style.css";
import { MGIcon } from "@/components/icons";
import { NavLink } from "react-router-dom";

export const MGSidebarItem = ({ icon, title, route, children }) => {
  return (
    <li className="--mg-sidebar-item">
      <NavLink to={route ? route : ""}>
        {icon && <MGIcon icon={icon} />}
        <span className="--mg-sidebar-item-title">{title}</span>
      </NavLink>
      {children}
    </li>
  );
};

MGSidebarItem.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  // link?: RouterLink;
  route: PropTypes.string,
};

MGSidebarItem.defaultProps = {};

export default MGSidebarItem;
