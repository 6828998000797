import "./style.css";
import MGInputWrapper from "../BuildingBlocks/MGInputWrapper";
import MGInput from "../BuildingBlocks/MGInput";
import { MGIconButton } from "@/components/buttons";
import MGInputButtons from "../BuildingBlocks/MGInputButtons";
import PropTypes from "prop-types";

export const MGTextbox = ({ value, clearable, placeholder, onChange, formikField, formikMeta }) => {
  const onChangeHandler = (evt) => {
    let newValue = evt === "" ? "" : evt.target.value;
    if (typeof onChange == "function") {
      onChange(newValue);
    }
  };

  const onClear = () => {
    onChangeHandler("");
  };

  return (
    <div className="--mg-textbox">
      <MGInputWrapper {...{ ...formikMeta }}>
        <MGInput
          {...formikField}
          type="text"
          value={value}
          onChange={onChangeHandler}
          placeholder={placeholder}
        ></MGInput>
        <MGInputButtons>
          {value && clearable && (
            <MGIconButton tabindex="-1" icon="close" variant="primary" onClick={onClear}></MGIconButton>
          )}
        </MGInputButtons>
      </MGInputWrapper>
    </div>
  );
};

MGTextbox.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  clearable: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  formikField: PropTypes.any,
  formikMeta: PropTypes.any,
};

MGTextbox.defaultProps = {
  clearable: true,
};

export default MGTextbox;
