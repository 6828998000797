import PropTypes from "prop-types";
import "./style.css";

export const MGFlexBox = ({ children, align, justify, wrap }) => {
  return (
    <div
      className="--mg-flex-box --mg-flex-box-row"
      style={{ alignItems: align, justifyContent: justify, flexWrap: wrap }}
    >
      {children}
    </div>
  );
};

MGFlexBox.propTypes = {
  align: PropTypes.string,
  justify: PropTypes.string,
};

MGFlexBox.defaultProps = {
  align: "center",
  justify: "center",
  wrap: "wrap",
};

export default MGFlexBox;
