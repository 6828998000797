// import { logger } from "@/app/services/logging/Logger";
import { currentUserVar } from "@/cache";

// const TAG = "[UserUtils]";

class UserUtils {
  static getCurrentSchoolUser = () => {
    let user = currentUserVar();
    let currentSchoolUser = null;
    if (user) {
      user.school_users?.forEach((school_user) => {
        if (school_user.is_current) {
          currentSchoolUser = school_user;
        }
      });
    }
    return currentSchoolUser;
  };

  static getCurrentSchoolUsers = () => {
    let user = currentUserVar();
    let currentSchoolUsers = null;
    if (user?.school_users) {
      currentSchoolUsers = user.school_users;
    }
    return currentSchoolUsers;
  };

  static getCurrentSchoolRoles = () => {
    let user = currentUserVar();
    let currentSchoolRoles = null;
    if (user?.roles) {
      currentSchoolRoles = user.roles;
    }
    return currentSchoolRoles;
  };

  static getCurrentUserId = () => {
    let user = currentUserVar();
    let currentUserId = null;
    if (user) {
      currentUserId = user?.id;
    }

    return currentUserId;
  };

  static isTeacherRoleActive = (currentUser) => {
    return currentUser?.role?.id === 3 || currentUser?.role?.id === "3";
  };

  static isAdminRoleActive = (currentUser) => {
    return currentUser?.role?.id === 1 || currentUser?.role?.id === "1";
  };

  static isParentRoleActive = (currentUser) => {
    return currentUser?.role?.id === 4 || currentUser?.role?.id === "4";
  };

  static isInRole = (user, role) => {
    if (user?.role?.name?.toLowerCase() === role?.toLowerCase()) {
      return true;
    }
    return false;
  };
}

export default UserUtils;
