import "./style.css";
import { MGHelperText } from "@/components/typography";

export const MGSwitch = ({ children, helperText, value, disabled, onChange }) => {
  function onChangeHandler(event) {
    if (typeof onChange == "function") {
      onChange(event.target.checked);
    }
  }

  return (
    <label className={`--mg-switch ${disabled ? "disabled" : ""} `}>
      {children}
      {helperText && (
        <>
          <br />
          <MGHelperText>{helperText}</MGHelperText>
        </>
      )}
      <input type="checkbox" checked={value ?? false} onChange={onChangeHandler} disabled={disabled} />
      <span className={`--mg-switch-round ${disabled ? "disabled" : ""}`}></span>
    </label>
  );
};

MGSwitch.propTypes = {};

MGSwitch.defaultProps = {};

export default MGSwitch;
