import { Suspense, useContext, useEffect, useMemo, useState } from "react";

import { Outlet } from "react-router";
import { MGSidebar, MGSidebarItem } from "./components";
import {
  currentUserVar,
  isLoggedInVar,
  backLinkVar,
  pageTitleVar,
  backTitleVar,
  automaticRoleChangeVar,
  manualRoleChangeVar,
} from "@/cache";
import { useApolloClient, useReactiveVar } from "@apollo/client";
import { MGImage } from "@/components/image";
import { authService } from "@/main";

import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import MGDropdown from "./components/inputs/MGDropdown/MGDropdown";
import { MGDropdownItem } from "@/components/inputs";
import { changeLanguage } from "i18next";
import { supportedLanguages } from "@/i18n";
import MGContainer from "./app/app-components/MGContainer/MGContainer";
import { PageErrorFallback } from "@/app/app-components/error-boundaries/PageErrorFallback";
import { ErrorBoundary } from "react-error-boundary";
import { HackyTranslationDropdownItem } from "@/app/app-components/hacky-translation-dropdown-item/HackyTranslationDropdownContent";
// import ActiveClassWidget from "./app/app-components/ActiveClassWidget/ActiveClassWidget";

import mgLogo from "./app/style/assets/logo_icon.png";
import edmeshLogo from "./app/style/assets/edMesh-logo.jpg";

import { MGTopBar } from "./components/topbar";
import RolesPickerWidget from "./app/app-components/RolesPickerWidget/RolesPickerWidget";
import Config from "@/config";
// import TokenVerify from "./app/services/auth/TokenVerify";

import SiteLoaderComponent from "./app/app-components/SiteLoaderComponent/SiteLoaderComponent";
import FeaturesLoaderComponent from "./app/app-components/FeaturesLoaderComponent/FeaturesLoaderComponent";

import { SidebarFooter } from "./app/app-components/SidebarFooter/SidebarFooter";
import { MGModal } from "./components/modals";
import MGAlertModal from "./app/app-components/alert-modal/MGAlertModal";
import classNames from "classnames";
import { NatsService } from "./app/services/nats/nats-service";
import { ThemeContext } from "./app/style/ThemeProvider";
import UserUtils from "./app/utils/users/user_utils";

// loading component for suspense fallback

function App() {
  // const loggedIn = useReactiveVar(isLoggedInVar);
  const currentUser = useReactiveVar(currentUserVar);
  const currentSchoolUser = UserUtils.getCurrentSchoolUser();
  const backLink = useReactiveVar(backLinkVar);
  const pageTitle = useReactiveVar(pageTitleVar);
  const backTitle = useReactiveVar(backTitleVar);
  const automaticRoleChange = useReactiveVar(automaticRoleChangeVar);
  const manualRoleChange = useReactiveVar(manualRoleChangeVar);
  const [showOfflineStatus, setShowOfflineStatus] = useState(window.navigator.onLine);

  if (window.navigator.onLine) {
    authService.getCurrentUser(automaticRoleChange);
  }

  const { t, i18n } = useTranslation();

  const client = useApolloClient();

  const langOptions = useMemo(
    () =>
      supportedLanguages.map((lang) => ({
        value: lang.id,
        // symbol: lang.symbol,
        title: lang.title,
        content: `${lang.symbol} ${lang.title}`,
        icon: lang.icon,
      })),
    supportedLanguages
  );

  // quick fix for MGDropdown changes
  const refactorMe = () => {
    const val = langOptions.find((it) => it.value === i18n.language);
    return val && val.content;
  };

  const [languageLoading, setLanguageLoading] = useState(false);

  const dropdownDisplayValue = refactorMe();

  // function getCurrentUser() {
  //   return JSON.parse(localStorage.getItem("currentUser")) ?? null;
  // }

  const logout = () => {
    client.resetStore();
    localStorage.removeItem("token");
    localStorage.removeItem("currentUser");
    isLoggedInVar(false);
    window.location.href = Config.ADMIN_DASHBOARD_HOST;
    // navigate("/login");
  };

  const { theme, setTheme } = useContext(ThemeContext);

  const reloadPage = () => {
    window.location.reload();
  };

  useEffect(() => {
    const zendeskScript = document.createElement("script");
    zendeskScript.src = "https://static.zdassets.com/ekr/snippet.js?key=3eb02566-2902-4a68-ad10-f149bfc1abb5";
    zendeskScript.id = "ze-snippet";
    document.body.appendChild(zendeskScript);
    handleOnlineStatus();
    window.addEventListener("offline", handleOnlineStatus);
    window.addEventListener("online", handleOnlineStatus);

    if (currentSchoolUser?.school?.id == "1947") {
      setTheme("edmesh");
    } else {
      setTheme("MG");
    }

    return () => {
      document.body.removeChild(zendeskScript);
      window.removeEventListener("online", handleOnlineStatus);
      window.removeEventListener("offline", handleOnlineStatus);
    };
  });

  function handleOnlineStatus() {
    let shouldShow = !window.navigator.onLine;
    setShowOfflineStatus(shouldShow);
    if (!shouldShow) {
      if (NatsService.getInstance().natsClient === undefined && NatsService.getInstance().initialized) {
        NatsService.getInstance().checkConnection();
      }
    }
  }

  return (
    <Suspense fallback={<SiteLoaderComponent />}>
      <Helmet>
        {/*  perhaps use a map to lookup appropriate lang */}
        <html lang={i18n.language} />
      </Helmet>

      <div
        className={`--mg-offline --mg-effect-shadow-level1 ${classNames({
          "--mg-offline-hidden": !showOfflineStatus,
        })}`}
      >
        {t("You are currently offline. Please check your internet connection.")}
      </div>

      <div className={`--mg-app${classNames({ " --mg-app-offline": showOfflineStatus })}`}>
        <MGSidebar
          collapsed={false}
          headerContent={
            <div className="--mg-application-logo">
              {theme === "MG" && <MGImage src={mgLogo} width={30} height={30} />}
              {theme === "edmesh" && <MGImage src={edmeshLogo} width={150} height={40} />}

              {/* <MGImage src={logo} width={30} height={30} /> */}
            </div>
          }
          footerContent={currentUser && <SidebarFooter currentUser={currentUser} />}
        >
          <ul>
            <MGSidebarItem route="/" title={t("Home")} icon="home" />
            <hr />
            <MGSidebarItem route="plan" title={t("Plan")} icon="settings" />
            <MGSidebarItem route="teach" title={t("Teach")} icon="teacher" />
            <MGSidebarItem route="review" title={t("Review")} icon="report" />
            <MGSidebarItem route="modes" title={t("All Modes")} icon="class_mode" />
            <hr />
            <MGSidebarItem route="students" title={t("Students")} icon="students" />
            <MGSidebarItem route="devices" title={t("Devices")} icon="devices" />
            <MGSidebarItem route="reports" title={t("Reports")} icon="report" />
            <hr />
            <MGSidebarItem route={"logout"} title={t("Leave Beta")} icon="show" />
            {/* {loggedIn ? (
                <MGSidebarItem route="logout" title={t("Logout")} icon="logout" />
              ) : (
                <MGSidebarItem route="login" title={t("Login")} icon="login" />
              )} */}
            {/*<MGSidebarItem route="razia" title="Razia" icon="report" />
              <MGSidebarItem route="nadeem" title="Nadeem" icon="report" />
            <MGSidebarItem route="gerard" title="Gerard" icon="report" /> */}
          </ul>
        </MGSidebar>

        <MGContainer>
          <ErrorBoundary
            FallbackComponent={PageErrorFallback}
            onReset={() => {
              // reset the state of your app so the error doesn't happen again
            }}
          >
            <MGTopBar title={pageTitle} url={backLink} backTitle={backTitle}>
              {currentUser && <RolesPickerWidget onLogout={logout} />}
              <MGDropdown
                value={i18n.language}
                loading={languageLoading}
                onChange={async (it) => {
                  setLanguageLoading(true);
                  await changeLanguage(it);
                  setLanguageLoading(false);
                  window.location.reload();
                }}
              >
                {langOptions.map((opt) => {
                  return (
                    <MGDropdownItem key={opt.value} value={opt.value} displayValue={dropdownDisplayValue}>
                      <HackyTranslationDropdownItem icon={opt.icon}>{opt.title}</HackyTranslationDropdownItem> &nbsp;
                    </MGDropdownItem>
                  );
                })}
              </MGDropdown>
              {/* <MGSpacing size={"sm"} />
                <MGIconButton icon={"notification"} badge={2}></MGIconButton> */}
            </MGTopBar>
            <Suspense fallback={<FeaturesLoaderComponent />}>
              {/* <TokenVerify logOut={logout}> */}
              <Outlet />
              <MGModal isShowing={automaticRoleChange && !manualRoleChange}>
                <MGAlertModal
                  message={t("Your role has changed, please reload")}
                  title={t("Role Changed")}
                  buttonText={t("Reload")}
                  onClose={() => reloadPage()}
                />
              </MGModal>
              {/* </TokenVerify> */}
            </Suspense>
          </ErrorBoundary>
        </MGContainer>
        {/* <MGContainer>
            <ErrorBoundary
              FallbackComponent={PageErrorFallback}
              onReset={() => {
                // reset the state of your app so the error doesn't happen again
              }}
            >
              <Outlet />
            </ErrorBoundary>
             <ActiveClassWidget /> 
          </MGContainer> */}
        <div
          id={"teacher-screenshare-DOM"}
          style={{
            position: "fixed",
            zIndex: "-1",
            top: "0",
            left: "0",
            width: "100vw",
            height: "100vh",
            backgroundColor: "transparent",
          }}
        ></div>
      </div>
    </Suspense>
  );
}

export default App;
