import "./style.css";

export const MGCard = ({ children }) => {
  return <div className="card --mg-effect-shadow-level1">{children}</div>;
};

MGCard.propTypes = {};

MGCard.defaultProps = {};

export default MGCard;
