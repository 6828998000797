import { gql } from "@apollo/client";

export const PROFILE_WEBFILTER_SETTINGS = gql`
  query profileWebfilterSettings($profileUUID: ID!) {
    profileWebfilterSettings(profileUUID: $profileUUID) {
      __typename
      ... on ProfileWebfilterSettings {
        enabled
        log_only
        redirect_uri
        block_all
        home_uri
        safe_search
        youtube_enabled
        whitelist_only
      }
      ... on NotQuiteSureProblem {
        message
      }
      ... on Problem {
        message
      }
    }
  }
`;

export const WEBFILTER_CATEGORY_TEMPLATES = gql`
  query webfilterCategoryTemplates {
    webfilterCategoryTemplates {
      __typename
      ... on WebfilterCategoryTemplateConnection {
        edges {
          node {
            id
            name
            description
            created_at
            updated_at
            configurations {
              webfilter_category {
                id
                name
                description
                created_at
                updated_at
              }
              configuration
            }
          }
        }
      }
      ... on NotQuiteSureProblem {
        message
      }
      ... on Problem {
        message
      }
    }
  }
`;

export const PROFILE_WEBFILTER_CATEGORIES = gql`
  query profileWebfilterCategories($profileUUID: ID!) {
    profileWebfilterCategories(profileUUID: $profileUUID, type: NETSWEEPER) {
      __typename
      ... on ProfileWebfilterCategoryConnection {
        edges {
          node {
            id
            parent_id
            type
            webfilter_category {
              id
              name
              description
              created_at
              updated_at
            }
            configuration
            created_at
            updated_at
          }
        }
      }
      ... on NotQuiteSureProblem {
        message
      }
      ... on Problem {
        message
      }
    }
  }
`;

export const PROFILE_YOUTUBE_WEBFILTER_CATEGORIES = gql`
  query profileYoutubeWebfilterCategories($profileUUID: ID!) {
    profileYoutubeWebfilterCategories(profileUUID: $profileUUID) {
      __typename
      ... on ProfileYoutubeWebfilterCategoryConnection {
        edges {
          node {
            id
            parent_id
            type
            webfilter_category {
              id
              name
              youtube_id
            }
            configuration
            created_at
            updated_at
          }
        }
      }
      ... on NotQuiteSureProblem {
        message
      }
      ... on Problem {
        message
      }
    }
  }
`;

export const WEBFILTER_CATEGORIES = gql`
  query webfilterCategories {
    webfilterCategories(type: NETSWEEPER) {
      __typename
      ... on WebfilterCategoryConnection {
        edges {
          node {
            id
            name
            description
            created_at
            updated_at
          }
        }
      }
      ... on NotQuiteSureProblem {
        message
      }
      ... on Problem {
        message
      }
    }
  }
`;
export const YOUTUBE_WEBFILTER_CATEGORIES = gql`
  query youtubeWebfilterCategories {
    youtubeWebfilterCategories {
      __typename
      ... on YoutubeWebfilterCategoryConnection {
        edges {
          node {
            id
            name
            youtube_id
          }
        }
      }
      ... on NotQuiteSureProblem {
        message
      }
      ... on Problem {
        message
      }
    }
  }
`;
export const PROFILE_WEBFILTER_URLS = gql`
  query profileWebfilterURLs($profileUUID: ID!) {
    profileWebfilterURLs(profileUUID: $profileUUID) {
      __typename
      ... on ProfileWebfilterURLConnection {
        edges {
          node {
            id
            fqdn
            name
            description
            type
            configuration
            created_at
            updated_at
          }
        }
      }
      ... on NotQuiteSureProblem {
        message
      }
      ... on Problem {
        message
      }
    }
  }
`;
export const TEST_WEBFILTER_URL = gql`
  query webfilterTestByURL($profileUUID: ID!, $url: String!, $classId: String, $deviceSignatures: [String!]) {
    webfilterTestByURL(profileUUID: $profileUUID, url: $url, classId: $classId, deviceSignatures: $deviceSignatures) {
      __typename
      ... on WebfilterURITestResponse {
        is_active
        is_youtube_active
        is_keyword_filtering
        blocked_words
        uris {
          id
          fqdn
          name
          description
          type
          configuration
          created_at
          updated_at
        }
        categories {
          profile_config {
            id
            parent_id
            type
            webfilter_category {
              id
              name
              description
              created_at
              updated_at
            }
            configuration
            created_at
            updated_at
          }
          mg_category {
            id
            name
            description
            created_at
            updated_at
          }
        }
        test_categories {
          profile_config {
            id
            parent_id
            type
            webfilter_category {
              id
              name
              description
              created_at
              updated_at
            }
            configuration
            created_at
            updated_at
          }
          mg_category {
            id
            name
            description
            created_at
            updated_at
          }
        }
        final_result
        whitelist_only
        page_title
        filtered_uri
        youtube_category
      }
      ... on NotQuiteSureProblem {
        message
      }
      ... on Problem {
        message
      }
    }
  }
`;

//mutations
// export const ADD_PROFILE_WEBFILTER_CATEGORY = gql`
//   mutation addProfileWebfilterCategory($input: AddWebfilterCategoryConfigurationInput!) {
//     addProfileWebfilterCategory(input: $input) {
//       result
//       errors {
//         ... on NotQuiteSureProblem {
//           message
//         }
//         ... on Problem {
//           message
//         }
//       }
//     }
//   }
// `;

// export const REMOVE_PROFILE_WEBFILTER_CATEGORY = gql`
//   mutation removeProfileWebfilterCategory($input: RemoveWebfilterCategoryConfigurationInput!) {
//     removeProfileWebfilterCategory(input: $input) {
//       result
//         errors {
//         ... on NotQuiteSureProblem {
//           message
//         }
//         ... on Problem {
//           message
//         }
//       }
//     }
//   }
// `;

// export const ADD_PROFILE_YOUTUBE_WEBFILTER_CATEGORY = gql`
//   mutation addProfileYoutubeWebfilterCategory($input: AddWebfilterCategoryConfigurationInput!) {
//     addProfileYoutubeWebfilterCategory(input: $input) {
//       result
//       errors {
//         ... on NotQuiteSureProblem {
//           message
//         }
//         ... on Problem {
//           message
//         }
//       }
//     }
//   }
// `;

// export const REMOVE_PROFILE_YOUTUBE_WEBFILTER_CATEGORY = gql`
//   mutation removeProfileYoutubeWebfilterCategory($input: RemoveWebfilterCategoryConfigurationInput!) {
//     removeProfileYoutubeWebfilterCategory(input: $input) {
//       result
//       errors {
//         ... on NotQuiteSureProblem {
//           message
//         }
//         ... on Problem {
//           message
//         }
//       }
//     }
//   }
// `;

// export const UPDATE_PROFILE_WEBFILTER_SETTINGS = gql`
//   mutation updateProfileWebfilterSettings($input: UpdateProfileInput!) {
//     updateProfileWebfilterSettings(input: $input) {
//       profileWebfilterSettings {
//         profile_uuid
//         enabled
//         log_only
//         block_all
//         home_uri
//         safe_search
//         youtube_enabled
//       }
//       errors {
//         ... on NotQuiteSureProblem {
//           message
//         }
//         ... on Problem {
//           message
//         }
//       }
//     }
//   }
// `;

// export const ADD_PROFILE_WEBFILTER_URL = gql`
//   mutation addProfileWebfilterURL($input: AddProfileWebfilterURLInput!) {
//     addProfileWebfilterURL(input: $input) {
//       result
//       errors {
//         ... on NotQuiteSureProblem {
//           message
//         }
//         ... on Problem {
//           message
//         }
//       }
//     }
//   }
// `;

// export const REMOVE_PROFILE_WEBFILTER_URL = gql`
//   mutation removeProfileWebfilterURL($input: RemoveProfileWebfilterURLInput!) {
//     removeProfileWebfilterURL(input: $input) {
//       result
//       errors {
//         ... on NotQuiteSureProblem {
//           message
//         }
//         ... on Problem {
//           message
//         }
//       }
//     }
//   }
// `;
