import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import Config from "@/config";

// from https://storybook.js.org/addons/storybook-react-i18next

export const supportedLanguages = [
  //Icons can be found in public/images/flag
  { id: "en", title: "English", icon: "flag_england.svg" },
  { id: "es", title: "Español", icon: "flag_spain.svg" },
  { id: "fr", title: "Français", icon: "flag_france.svg" },
  { id: "ja", title: "日本", icon: "flag_japan.svg" },
  { id: "ko", title: "한국인", icon: "flag_south_korea.svg" },
  { id: "zh_CN", title: "中国人", icon: "flag_china_cn.svg" },
  { id: "zh_TW", title: "中國人", icon: "flag_china_tw.svg" },
  { id: "vi", title: "Tiếng Việt", icon: "flag_vietnam.svg" },
];

const supportedLngs = supportedLanguages.map((language) => language.id);
// end from https://storybook.js.org/addons/storybook-react-i18next

// defaults
let reactSuspense = false;
let debug = Config.DEBUG_I18N === "true";
let resources; // undefined means we'll load from public/ folder . defined means it would be bundled / compiled in

// quick and dirty setup for testing
if (import.meta.env.MODE === "test") {
  reactSuspense = import.meta.env.MODE !== "test";
  debug = import.meta.env.MODE !== "test";
  resources = ["translation"].reduce((acc, n) => {
    ["en"].forEach((lng) => {
      if (!acc[lng]) acc[lng] = {};
      acc[lng] = {
        ...acc[lng],
        [n]: require(`../public/locales/${lng}/${n}.json`), // this is a synchronous load
        // [n]: import(`../public/locales/${lng}/${n}.json`), // async load
      };
    });
    return acc;
  }, {});
}

i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  // .init({
  //   // we use language detector, so don't define lng option
  //   fallbackLng: "en",
  //   debug: true,
  //
  //   interpolation: {
  //     escapeValue: false, // not needed for react as it escapes by default
  //   },
  // })

  .init({
    fallbackLng: "en",
    debug: debug,
    returnEmptyString: false,
    // defaultNS: "common",
    // ns,
    interpolation: { escapeValue: false },
    react: { useSuspense: reactSuspense },
    supportedLngs,
    resources,
  });

export default i18n;
