import { ErrorMessage } from "formik";
import "./style.css";

import MGDropdown from "./MGDropdown";
import PropTypes from "prop-types";
import { MGHelperText } from "@/components/typography";
import MGField from "../BuildingBlocks/MGField";

export const MGDropdownField = ({
  name,
  placeholder,
  options,
  disabled,
  onChange,
  children,
  multiselect,
  loading,
  pluralText,
  actionButtonIcon,
  onActionButtonClick,
  inline,
}) => {
  return (
    <>
      <MGField name={name} inline={inline}>
        {({ meta, field, form }) => {
          const onChangeHandler = (evt) => {
            form.setFieldTouched(field.name, true);
            form.setFieldValue(field.name, evt);
            if (typeof onChange === "function") onChange(evt);
          };

          return (
            <>
              <MGDropdown
                placeholder={placeholder}
                options={options}
                value={field.value}
                disabled={disabled}
                onChange={onChangeHandler}
                loading={loading}
                formikField={field}
                formikMeta={meta}
                multiselect={multiselect}
                pluralText={pluralText}
                actionButtonIcon={actionButtonIcon}
                onActionButtonClick={onActionButtonClick}
              >
                {children}
              </MGDropdown>
            </>
          );
        }}
      </MGField>
      <ErrorMessage component={MGHelperText} name={name} />
    </>
  );
};

MGDropdownField.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  children: PropTypes.any,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      content: PropTypes.any,
    })
  ),
  multiselect: PropTypes.bool,
  pluralText: PropTypes.string,
  inline: PropTypes.bool,
};

MGDropdownField.defaultProps = {};

export default MGDropdownField;
