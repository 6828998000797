import { MGButton } from "@/components/buttons";
import { MGCheckboxField } from "@/components/controls";
import { MGTextboxField } from "@/components/inputs";
import { MGModal, MGModalBody, MGModalFooter, MGModalHeader } from "@/components/modals";
import { MGHeading3, MGLabel } from "@/components/typography";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import "./style.css";

export const DuplicateClassModal = (props) => {
  const {
    duplicateClassModalIsShowing,
    initialValues,
    //onDuplicateClass,
    loadingDuplicateClass,
    onDuplicateClassSubmit,
    onDuplicateClassCancel,
  } = props;

  const { t } = useTranslation();

  const validationSchema = yup.object({
    name: yup.string().required(t("Required")),
  });

  return (
    <MGModal isShowing={duplicateClassModalIsShowing} closable={true} onClose={onDuplicateClassCancel}>
      <div className="--mg-duplicate-class-modal">
        <Formik initialValues={initialValues} onSubmit={onDuplicateClassSubmit} validationSchema={validationSchema}>
          {(props) => {
            const { isValid, submitForm } = props;

            return (
              <>
                <MGModalHeader>
                  <br />
                  <MGHeading3>{t("Duplicate Class")}</MGHeading3>
                  <br />
                </MGModalHeader>
                <MGModalBody>
                  <Form noValidate autoComplete={"off"}>
                    <MGLabel htmlFor={"name"}>{t("Class name")}</MGLabel>
                    <MGTextboxField name={"name"} placeholder={t("New class name")} />

                    <MGCheckboxField name="students">{t("Keep students")}</MGCheckboxField>

                    <MGCheckboxField name="content">{t("Keep content")}</MGCheckboxField>

                    <MGCheckboxField name="modes">{t("Keep linked modes")}</MGCheckboxField>
                  </Form>
                </MGModalBody>
                <MGModalFooter>
                  <center>
                    <MGButton variant={"tertiary"} onClick={onDuplicateClassCancel}>
                      {t("Cancel")}
                    </MGButton>
                    <MGButton
                      variant={"primary"}
                      type={"submit"}
                      isLoading={loadingDuplicateClass}
                      onClick={submitForm}
                      disabled={!isValid}
                    >
                      {t("Duplicate")}
                    </MGButton>
                  </center>
                </MGModalFooter>
              </>
            );
          }}
        </Formik>
      </div>
    </MGModal>
  );
};

export default DuplicateClassModal;

// classToAction: classToAction,
//     initialValues: {
//         classId: classToAction.classId,
//         name: classToAction.className,
//         content: true,
//         students: true,
//         modes: true
//     },
//     duplicateClassModalIsShowing: duplicateClassModalIsShowing,
//     onDuplicateClass: onDuplicateClass,
//     onDuplicateClassCancel: onDuplicateClassCancel,
