import "./style.css";

import MGActionTextbox from "./MGActionTextbox";

import { ErrorMessage } from "formik";
import { MGHelperText } from "@/components/typography";
import PropTypes from "prop-types";
import MGField from "../BuildingBlocks/MGField";

export const MGActionTextboxField = ({
  name,
  icon,
  actionIcon,
  onAction,
  isLoading,
  disabled,
  onChange,
  clearable,
  placeholder,
  inline,
}) => {
  return (
    <>
      <MGField name={name} inline={inline}>
        {({ meta, field, form }) => {
          const onChangeHandler = (evt) => {
            form.setFieldValue(field.name, evt);
            if (typeof onChange === "function") onChange(evt);
          };

          return (
            <>
              <MGActionTextbox
                placeholder={placeholder}
                icon={icon}
                isLoading={isLoading}
                actionIcon={actionIcon}
                disabled={disabled}
                onAction={onAction}
                clearable={clearable}
                value={field.value}
                onChange={(evt) => {
                  onChangeHandler(evt);
                }}
                formikField={field}
                formikMeta={meta}
              ></MGActionTextbox>
            </>
          );
        }}
      </MGField>
      <ErrorMessage component={MGHelperText} name={name} />
    </>
  );
};

MGActionTextboxField.propTypes = {
  name: PropTypes.string,
  icon: PropTypes.string,
  actionIcon: PropTypes.string,
  onAction: PropTypes.func,
  onChange: PropTypes.func,
  clearable: PropTypes.bool,
  placeholder: PropTypes.string,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  inline: PropTypes.bool,
};

MGActionTextboxField.defaultProps = {
  clearable: true,
};

export default MGActionTextboxField;
