import "./style.css";
import { MGTextbox } from "./MGTextbox";
import { ErrorMessage } from "formik";

import PropTypes from "prop-types";
import { MGHelperText } from "@/components/typography";
import MGField from "../BuildingBlocks/MGField";

export const MGTextboxField = ({ name, clearable, placeholder, onChange, inline }) => {
  return (
    <MGField name={name} inline={inline}>
      {({ meta, field, form }) => {
        const onChangeHandler = (evt) => {
          form.setFieldValue(field.name, evt);
          if (typeof onChange === "function") onChange(evt);
        };

        return (
          <>
            <MGTextbox
              clearable={clearable}
              placeholder={placeholder}
              value={field.value}
              onChange={onChangeHandler}
              formikField={field}
              formikMeta={meta}
            ></MGTextbox>
            <ErrorMessage component={MGHelperText} name={name} />
          </>
        );
      }}
    </MGField>
  );
};

MGTextboxField.propTypes = {
  name: PropTypes.string,
  clearable: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  inline: PropTypes.bool,
};

MGTextboxField.defaultProps = {
  clearable: true,
};

export default MGTextboxField;
