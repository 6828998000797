import MGDaysPicker from "./MGDaysPicker";
import { ErrorMessage } from "formik";
import { MGHelperText } from "@/components/typography";
import PropTypes from "prop-types";
import { MGField } from "@/components/inputs";

export const MGDaysPickerField = ({ name, disabled, inline }) => {
  return (
    <MGField name={name} inline={inline}>
      {({ field, form }) => {
        const onChangeHandler = (evt) => {
          form.setFieldTouched(field.name, true); //This can be removed later
          form.setFieldValue(field.name, evt);
        };
        return (
          <>
            <span className="--mg-days-picker-field">
              <MGDaysPicker
                days={field.value}
                disabled={disabled}
                formikField={field}
                onChange={onChangeHandler}
              ></MGDaysPicker>
              <ErrorMessage component={MGHelperText} name={name} />
            </span>
          </>
        );
      }}
    </MGField>
  );
};

MGDaysPickerField.propTypes = {
  name: PropTypes.string,
  disabled: PropTypes.bool,
  inline: PropTypes.bool,
};

MGDaysPickerField.defaultProps = {
  disabled: false,
};

export default MGDaysPickerField;
