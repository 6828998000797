import { useContext } from "react";
import { ClassActionContext } from "./ClassActionsProvider";

export const useTransferStudents = () => {
  const { classAction_transferStudents } = useContext(ClassActionContext);

  const transferStudents = ({ classId, device_uuids, onSuccess, onFailure, onCancel }) => {
    classAction_transferStudents({
      classId,
      device_uuids,
      onSuccess,
      onFailure,
      onCancel,
    });
  };

  return [transferStudents];
};
