import { useState } from "react";
import PropTypes from "prop-types";
import "./style.css";

import MGInputWrapper from "../BuildingBlocks/MGInputWrapper";
import MGInput from "../BuildingBlocks/MGInput";
import { MGIconButton } from "@/components/buttons";
import MGInputButtons from "../BuildingBlocks/MGInputButtons";

export const MGPassword = ({ value, clearable, disabled, placeholder, onChange, formikField, formikMeta }) => {
  let [isVisible, setIsVisible] = useState(false);

  function onChangeHandler(evt) {
    let newValue = evt === "" ? "" : evt.target.value;
    if (typeof onChange == "function") {
      onChange(newValue);
    }
  }

  const onClear = () => {
    onChangeHandler("");
  };

  const toggleVisible = () => {
    setIsVisible(!isVisible);
  };

  return (
    <>
      <MGInputWrapper {...{ ...formikMeta }} disabled={disabled}>
        <MGInput
          {...formikField}
          type={isVisible ? "text" : "password"}
          value={value}
          onChange={onChangeHandler}
          placeholder={placeholder}
          disabled={disabled}
        ></MGInput>
        <MGInputButtons>
          {value && clearable && (
            <MGIconButton variant="primary" tabindex="-1" icon="close" onClick={onClear}></MGIconButton>
          )}

          <MGIconButton
            disabled={disabled}
            variant="primary"
            tabindex={"-1"}
            icon={isVisible ? "hide" : "show"}
            onClick={toggleVisible}
          ></MGIconButton>
        </MGInputButtons>
      </MGInputWrapper>
    </>
  );
};

MGPassword.propTypes = {
  value: PropTypes.string,
  clearable: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  formikField: PropTypes.any,
  formikMeta: PropTypes.any,
};

MGPassword.defaultProps = {
  clearable: true,
};

export default MGPassword;
