import "./style.css";

export const MGFlexBoxSpacer = () => {
  return <div className="--mg-flex-box-spacer"></div>;
};

MGFlexBoxSpacer.propTypes = {};

MGFlexBoxSpacer.defaultProps = {};

export default MGFlexBoxSpacer;
